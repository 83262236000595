import * as XLSX from 'xlsx';
import { AllSoldColumns, ExportExcelItem, PalletProduct, ProductItem, ProductQualityItem, ScanningTimeObject, SoldExcelDataItem, StockAnalysisList, ProductQualityExcelItem,
  StockExcelDataItem, SupplierExportExcelItem, SupplyInventoryExcelItem, SupplyInventoryItem, SupplyPricingList, UserScanningTimeExcelItem } from '../../types';
import { getPackage } from '../../services/PackageService';
import { getProductsBySupplyId } from '../../services/ProductService';
import { getFilteredPalletProducts } from '../../services/PalletService';
import { set } from 'lodash';
import { setTimeout as delay } from 'timers/promises';


export const generateExcelFile = (data:SoldExcelDataItem[] | StockAnalysisList[] | StockExcelDataItem[] | ExportExcelItem[] | SupplierExportExcelItem[] | ProductQualityExcelItem[]
| UserScanningTimeExcelItem[] | SupplyInventoryExcelItem[], filename: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, filename + '.xlsx');
};

export const downloadExcelFile = async (
  pkgID: number,
  isAdmin: boolean,
  discountValue?: number,
  packagePrice?: number,
) => {
  try {
    let data: any[] = [];
    const response = await getPackage(pkgID);
    const hasDiscount = discountValue !== undefined && discountValue > 0;
    const discountHistory = response.data.metadata.discount_history;
    const lastDiscountAmount = discountHistory[discountHistory.length - 1].discount_in_amount;
    const hasNegativeDiscount = lastDiscountAmount !== undefined && lastDiscountAmount != 0;
    const applyPricing = (price: number) => {
      if (hasDiscount) {
        const finalPrice = +(price * (1 - discountValue!)).toFixed(2);
        return {
          Selling_Price: finalPrice,
        };
      } else if(packagePrice && hasNegativeDiscount) {
          const surcharge = (price / packagePrice) * Math.abs(lastDiscountAmount);
          const newPrice = parseFloat((price + surcharge).toFixed(2));
          return {
            Selling_Price: newPrice,
          };
      }else {
        return {
          Selling_Price: price,
        };
      }
    };

    const customerNr = response?.data?.customer_details?.jtl_id;

    // === Standalone Products ===
    if (response.data.standalone_products) {
      response.data.standalone_products.forEach((product: any) => {
        const price = Number(product.selling_price);
        const base: any = {
          Brands: product.brand,
          Article_Nr: product.article,
          Description: `${product.sub_category} ${product.model}`,
          Quantity: 1,
          Usage: product?.usage?.name,
          Lager_Nr: product.id,
          Scale: product.final_points,
          Customer_Nr: customerNr,
          Linked_Lager_Number: '',
          Selling_Online_Price: Number(product.selling_online_price),
          ...applyPricing(price)
        };

        if (isAdmin) {
          base.Supply = product.supply;
          base.Package_id = pkgID;
        }

        data.push(base);
      });
    }

    // === Set Articles ===
    if (response.data.set_articles) {
      response.data.set_articles.forEach((product: any) => {
        const price = Number(product.selling_price);
        const base: any = {
          Brands: product.brand,
          Article_Nr: product.article,
          Description: `${product.sub_category} ${product.model}`,
          Quantity: 1,
          Usage: product?.usage?.name,
          Lager_Nr: product.id,
          Scale: product.final_points,
          Customer_Nr: customerNr,
          Linked_Lager_Number: product.combined_products[0],
          Selling_Online_Price: Number(product.selling_online_price),
          ...applyPricing(price)
        };

        if (isAdmin) {
          base.Supply = product.supply;
          base.Package_id = pkgID;
        }

        data.push(base);
      });
    }

    // === Pallet Products ===
    if (response.data.pallets_products) {
      for (const pallet of response.data.pallets_products) {
        const palletResponse = await getFilteredPalletProducts(pallet.id);
        let count = 1;

        palletResponse.data.forEach((item: PalletProduct) => {
          const price = Number(item.total_selling_price);
          const base: any = {
            Brands: item.brand,
            Article_Nr: item.article,
            Description: `${item.sub_category} ${item.model}`,
            Quantity: item.quantity,
            Usage: pallet?.usage?.name,
            Lager_Nr: `${pallet.id}_${count}`,
            Pallet_Nr: pallet.description,
            Scale: '',
            Customer_Nr: '',
            Selling_Online_Price: item.total_current_online_price,
            ...applyPricing(price)
          };

          if (isAdmin) {
            base.Supply = pallet.supply;
            base.Package_id = pkgID;
          }

          data.push(base);
          count++;
        });
      }
    }

    // ✅ Create Excel with all fields
    generateExcelFile(data, `package_${pkgID}_${response?.data?.created_at}`);
  } catch (e) {
    console.error('Error while handling the package data:', e);
  }
};

export const downloadSupplierExcelFile = (supply_id: string) => {
  let data: SupplierExportExcelItem[] = [];
  let date = new Date().toISOString();
  getProductsBySupplyId(supply_id).then((response) => {
    response.data && response.data.map((product: ProductItem) => {
      data.push({
        Marke: product.brand,
        'Artikel Nr.': product.article,
        Beschreibung: `${product.sub_category} ${product.model}`,
        Menge: 1,
        Zustand: product?.usage?.name,
        "Lager Nr.": product.id,
        Grade: product.supplier_grade,
      })
    })
    generateExcelFile(data, 'Liefernummer_' + supply_id + '__' + date);
  }).catch((e) => {
    console.log('error while getting products: ', e);
  })
};

export const UserScanningTimeData = (scanningTimeData: ScanningTimeObject[]) => {
  let data: UserScanningTimeExcelItem[] = [];
  let date = new Date().toISOString();
  scanningTimeData.map((scanningTimeItem: ScanningTimeObject, index: number) => {
    data.push({
      user_name: scanningTimeItem.user_name,
      month: scanningTimeItem.month,
      products_scanned: scanningTimeItem['No_of_Products_Scanned'],
      average_scanning_time_sec: scanningTimeItem['Average_Scanning_Time(sec)'],
      average_scanning_time_min: scanningTimeItem['Average_Scanning_Time(min)'],
      average_scanning_time_hour: scanningTimeItem['Average_Scanning_Time(hours)'],
    });
  })
  generateExcelFile(data, 'Scanning-Time-' + date);
};

export const ProductQualityData = (qualityData: ProductQualityItem[]) => {
  let data: ProductQualityExcelItem[] = [];
  let date = new Date().toISOString();
  qualityData.map((qualityDataItem: ProductQualityItem, index: number) => {
    data.push({
      "Supply": qualityDataItem.supply,
      "Supply Date": qualityDataItem.supply_date,
      "Supply Type": qualityDataItem.supply_type,
      "Total A Grade":  qualityDataItem.total_A_grade,
      "Total B Grade": qualityDataItem.total_B_grade,
      "Total C Grade": qualityDataItem.total_C_grade,
      "Total products": qualityDataItem.total_products,
    });
  })
  generateExcelFile(data, 'Product-Quality-Analysis-' + date);
};

export const StockAnalysisData = (stockData: SupplyPricingList[]) => {
  let data: StockExcelDataItem[] = [];
  let date = new Date().toISOString();
  stockData.map((stockDataItem: SupplyPricingList, index: number) => {
    data.push({
      "JTL EK Netto": stockDataItem.jtl_ek_netto,
      "Lager Wert": stockDataItem.lagerwert,
      "Portal EK Netto": stockDataItem.portal_ek_netto,
      "Status":  stockDataItem.status,
      "Supplier": stockDataItem.supplier,
      "Supply Type": stockDataItem.supply_type,
      "VK Netto": stockDataItem.vk_netto,
      "VK Soll": stockDataItem.vk_soll,
    });
  })
  generateExcelFile(data, 'Supply-pricing-' + date);
};

export const StockAnalysisDataForExcel = (stockData: StockAnalysisList[]) => {
  let date = new Date().toISOString();
  generateExcelFile(stockData, 'Stock-Analysis-' + date);
};

export const AllSoldData = (stockData: AllSoldColumns[]) => {
  let data: SoldExcelDataItem[] = [];
  let date = new Date().toISOString();
  stockData.map((soldItem: AllSoldColumns, index: number) => {
    data.push({
      "Article": soldItem.article_number,
      "Brand": soldItem.brand_name,
      "Company": soldItem.company,
      "Date": soldItem.created_at,
      "Description": soldItem.description,
      "Discount Date": soldItem.discount_created_at,
      "Discount Invoice": soldItem.discount_invoice,
      "Final Grade": soldItem.final_grade,
      "Full Name": soldItem.fullname,
      "Invoice Date": soldItem.invoice_created_at,
      "Invoice Number": soldItem.invoice_number,
      "Invoice Paid": soldItem.invoice_paid,
      "JTL Buying Price": soldItem.jtl_buying_price,
      "JTL Online Price": soldItem.jtl_online_price,
      "JTL Selling Price": soldItem.jtl_selling_price,
      "Lager Nr.": soldItem.lager_number,
      "Order Nr.": soldItem.order_number,
      "Portal Buying Price": soldItem.portal_buying_price,
      "Portal Online Price": soldItem.portal_online_price,
      "Portal Selling Price": soldItem.portal_selling_price,
      "Price Percent": soldItem.price_percent,
      "Product Group": soldItem.product_group,
      "Profit": soldItem.profit,
      "Sold By": soldItem.sold_by,
      "Supply": soldItem.supply_id,
      "Supply Type": soldItem.supply_type,
      "Turnover": soldItem.turnover,
      "Turnover Ratio": soldItem.turnover_ratio,      
    });
  })
  generateExcelFile(data, 'All-Sold-' + date);
};

export const SupplyInventoryData = (Inventory: SupplyInventoryItem[]) => {
  let data: SupplyInventoryExcelItem[] = [];
  Inventory.map((inventoryItem: SupplyInventoryItem, index: number) => {
    data.push({
      Artikel: inventoryItem.article,
      Plombe: inventoryItem.supply,
      Lager: inventoryItem.product,
      Usage: inventoryItem.usage,
      Grade: inventoryItem.final_grade,
      Glassbruch: inventoryItem.broken_glass,
      Preis_Prozen: inventoryItem.price_percent,
      Skala: inventoryItem.final_point,
      VKP_pries: inventoryItem.offer_price,
      Einkauf_pries: inventoryItem.buying_price,
    });
  })
  generateExcelFile(data, `Plombe-` + data[0]?.Plombe);
};


