import { cilUser, cilInbox, cilFactory, cilCash, cilNewspaper, cilMoney } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react"
import { useEffect, useState } from "react";

interface KPIComponentProps {
    products: any
};

const KPIComponent = (props: KPIComponentProps) => {
   const {products} = props;
    const [kpiData, setKpiData] = useState<any>(null);
    
    const calculateKPIs = (productsData: any) => {
        // selling and buying prices
        // console.log('called: ');
        const totalJTLSellingPrice =  parseFloat(
          productsData.reduce((sum: any, item: any) => sum + item.jtl_selling_price, 0).toFixed(2)
        );
        const totalJTLOnlinePrice =  parseFloat(
          productsData.reduce((sum: any, item: any) => sum + item.jtl_online_price, 0).toFixed(2)
        );
        
        const totalJTLBuyingPrice =  parseFloat(
          productsData.reduce((sum: any, item: any) => sum + item.jtl_buying_price, 0).toFixed(2)
        );
        const totalPortalSellingPrice =  parseFloat(
          productsData.reduce((sum: any, item: any) => sum + item.portal_selling_price, 0).toFixed(2)
        );
        const totalPortalOnlinePrice =  parseFloat(
          productsData.reduce((sum: any, item: any) => sum + item.portal_online_price, 0).toFixed(2)
        );
        const totalPortalBuyingPrice =  parseFloat(
          productsData.reduce((sum: any, item: any) => sum + item.portal_buying_price, 0).toFixed(2)
        );
        
        // Count of invoices paid
        const totalInvoicesPaid = new Set (productsData.filter((item: any) => item.invoice_paid === "Y").map((item: any) => item.invoice_number)).size;
        const totalInvoicesNotPaid = new Set(productsData.filter((item: any) => item.invoice_paid === "N").map((item: any) =>  item.invoice_number)).size;
        const totalInvoices =  new Set(
          productsData
            .map((item: any) => item.invoice_number?.trim().toLowerCase()) // Handles whitespace & case sensitivity
            .filter((invoice: any) => invoice) // Removes null/undefined
        ).size;
  
      
        // Count of distinct orders and profits
        const uniqueOrders = new Set(
          productsData
            .map((item: any) => item.order_number?.trim().toLowerCase()) // Handles whitespace & case sensitivity
            .filter((order: any) => order) // Removes null/undefined
        ).size;
        const totalProfits = parseFloat(
          productsData.reduce((sum: any, item: any) => sum + Number(item.profit || 0), 0).toFixed(2)
        );
        const uniqueSupplies = new Set(productsData.map((item: any) => item.supply_id)).size;
        const uniqueSupplyTypes = new Set(productsData.map((item: any) => item.supply_type)).size;
  
        // Count of distinct lager numbers
        const uniqueLagerNumbers = (productsData.map((item: any) => item.lager_number)).length;
        // if(selectedTab == 0) {
            setKpiData({
            total_invoice_paid: totalInvoicesPaid,
            total_invoice_unpaid: totalInvoicesNotPaid,
            total_invoices: totalInvoices,
    
            total_jtl_selling_price: totalJTLSellingPrice.toFixed(2),
            total_jtl_online_price: totalJTLOnlinePrice.toFixed(2),
            total_jtl_buying_price: totalJTLBuyingPrice.toFixed(2),
    
            total_portal_selling_price: totalPortalSellingPrice.toFixed(2),
            total_portal_online_price: totalPortalOnlinePrice.toFixed(2),
            total_portal_buying_price: totalPortalBuyingPrice.toFixed(2),
    
            total_orders: uniqueOrders,
            total_products: uniqueLagerNumbers,
            total_profit: totalProfits.toFixed(2),
            total_supplies: uniqueSupplies,
            total_supplytypes: uniqueSupplyTypes,
          });
        // }
        // else if(selectedTab == 1) {
        //   setPalletsKpiData({
        //     total_invoice_paid: totalInvoicesPaid,
        //     total_invoice_unpaid: totalInvoicesNotPaid,
        //     total_invoices: totalInvoices,
    
        //     total_jtl_selling_price: totalJTLSellingPrice.toFixed(2),
        //     total_jtl_online_price: totalJTLOnlinePrice.toFixed(2),
        //     total_jtl_buying_price: totalJTLBuyingPrice.toFixed(2),
    
        //     total_portal_selling_price: totalPortalSellingPrice.toFixed(2),
        //     total_portal_online_price: totalPortalOnlinePrice.toFixed(2),
        //     total_portal_buying_price: totalPortalBuyingPrice.toFixed(2),
    
        //     total_orders: uniqueOrders,
        //     total_products: uniqueLagerNumbers,
        //     total_profit: totalProfits.toFixed(2),
        //     total_supplies: uniqueSupplies,
        //     total_supplytypes: uniqueSupplyTypes,
        //   })
        // }
      }
      
    const kpiInvoicesCards = [
        { icon: cilNewspaper, color: '#23B2D9', label: 'Invoice paid', key: 'total_invoice_paid' },
        { icon: cilNewspaper, color: '#23B2D9', label: 'Invoice not paid', key: 'total_invoice_unpaid' },
        { icon: cilNewspaper, color: '#23B2D9', label: 'Total Invoice', key: 'total_invoices' },
    ];

    const kpiPricesCards = [       
        { icon: cilCash, color: '#23B2D9', label: 'JTL Selling price', key: 'total_jtl_selling_price' },
        { icon: cilCash, color: '#23B2D9', label: 'Portal Selling Price', key: 'total_portal_selling_price' },  
        { icon: cilCash, color: '#23B2D9', label: 'JTL Buying price', key: 'total_jtl_buying_price' },
      
        { icon: cilFactory, color: '#23B2D9', label: 'Portal Buying Price', key: 'total_portal_buying_price' },
        { icon: cilFactory, color: '#23B2D9', label: 'JTL Online price', key: 'total_jtl_online_price' },
        { icon: cilFactory, color: '#23B2D9', label: 'Portal Online Price', key: 'total_portal_online_price' },
    ];

    const kpiRemainingCards = [
        { icon: cilInbox, color: '#23B2D9', label: 'Total Orders', key: 'total_orders' },        
        { icon: cilCash, color: '#23B2D9', label: 'Total Products', key: 'total_products' },
        { icon: cilCash, color: '#23B2D9', label: 'Total Profit €', key: 'total_profit' },       
        { icon: cilFactory, color: '#23B2D9', label: 'Total Supplies', key: 'total_supplies' },
        { icon: cilCash, color: '#23B2D9', label: 'Total Supply Types', key: 'total_supplytypes' },
    ];

    useEffect(() => {
        if(products?.length > 0) {
            calculateKPIs(products);
        }
    },[products]);

    return (
        <div>
            {
                kpiData ?
                <>
                    {`${products?.length} of ${kpiData['total_products']}`}
                    <CRow>
                        <CCol xs={12} md={4}>
                            <CCard className='pointer kpi-card'>
                                <CCardHeader>Prices</CCardHeader>
                                <CCardBody className="padding-8">
                                <CRow>
                                    {kpiPricesCards && kpiPricesCards?.map((card, index) => (
                                        <CCol xs={6} className="margin-top-12 horizontal-padding-0">                                        
                                            <div style={{ display: 'flex', alignItems: 'center', borderWidth: 2, borderColor: 'black'}}>
                                                <CIcon icon={card.icon} className='margin-right-12' size="xl" style={{ color: card.color }} />
                                                <h6><b>{card.label}: </b>{kpiData[card?.key]} €</h6>
                                            </div>                                                
                                        </CCol>
                                    ))}
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol xs={12} md={4}>
                            <CCard className='pointer kpi-card'>
                                <CCardHeader>Orders & Profits</CCardHeader>
                                <CCardBody className="padding-8">
                                <CRow>
                                    {kpiRemainingCards && kpiRemainingCards?.map((card, index) => (
                                        <CCol xs={6} className="margin-top-12 horizontal-padding-0">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CIcon icon={card.icon} className='margin-right-12' size="xl" style={{ color: card.color }} />
                                                    <h6><b>{card.label}: </b>{kpiData[card.key]}</h6>
                                                </div>
                                        </CCol>
                                    ))}
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol xs={12} md={4}>
                            <CCard className='pointer kpi-card'>
                                <CCardHeader>Invoices</CCardHeader>
                                <CCardBody className="padding-8">
                                <CRow>
                                    {kpiInvoicesCards && kpiInvoicesCards?.map((card, index) => (
                                        <CCol xs={12} className="margin-top-12 horizontal-padding-0">                                    
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CIcon icon={card.icon} className='margin-right-12' size="xl" style={{ color: card.color }} />
                                                    <h6><b>{card.label}: </b>{kpiData[card.key]}</h6>
                                                </div>
                                        </CCol>
                                    ))}
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>                
                </>
                : null
            }
            
      </div>
    )
};

export default KPIComponent;
