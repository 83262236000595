import { useEffect } from 'react'
import 'simplebar/dist/simplebar.min.css';
import { CCardBody } from '@coreui/react-pro';
import '../CustomerStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { Package } from '../../types';
import { CBadge, CCard, CCardText, CCardTitle, CCol, CHeader, CRow } from '@coreui/react';
import { getPackages } from '../../services/PackageService';
import { setPackages } from '../../redux/slices/PackageSlice';
// import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { setSelectedTab } from '../../redux/slices/CustomerTotalOrdersSlice';
import '@react-pdf-viewer/core/lib/styles/index.css';


const CustomerPackages = (props: any) => {
  const packges: Package[] = useSelector((state: RootState) => state.packageSlice.packages);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const {t} = useTranslation();

  const getAllPackages = () => {
    getPackages().then((response) => {
      let updatedPackagesArray: Package[] = [];
      for (let responseItem of response?.data?.results) {
        let new_date = new Date(responseItem.created_at);
        updatedPackagesArray.push({
          ...responseItem,
          customer_name: responseItem?.customer_details?.user?.first_name + " " + responseItem?.customer_details?.user?.last_name,
          creator_name: responseItem?.creator?.first_name + " " + responseItem?.creator?.last_name,
          created_at: new_date,
        });
      }
      dispatch(setPackages(updatedPackagesArray));
    }).catch((e) => {
      dispatch(setPackages([]));
      console.log('get packages error: ', e);
    });
  };

  useEffect(() => {
    getAllPackages();
    dispatch(setSelectedTab(1));
  }, []);

  return (
    <div>
      <CHeader><h1><b>{t('adminDashboard.Customers.my_packages')}</b></h1></CHeader>
      <CRow>
        {
          packges && packges.length > 0 ? (
            packges.every((packageItem) => packageItem.status === 'F') ? (
              <h5 className='text-center'>{t('adminDashboard.Customers.no_package_assigned')}</h5>
            ) : (
              packges.map((packageItem) => {
                if (packageItem.status !== 'F') {
                  return (
                    <CCol xs={12} md={3} style={{ marginTop: 24, marginBottom: 24 }} key={packageItem.id}>
                      <CCard
                        className='unselected-card pointer'
                        onClick={() => {
                          navigation(`/customer/package/${packageItem.id}/details/`, { state: { packageItem } });
                        }}
                      >
                        <CCardBody>
                          <CCardTitle>{t('adminDashboard.Packages.package')} {packageItem.id}</CCardTitle>
                          <CCardText>
                            <div><b>{t('adminDashboard.OrdersWords.created_by')} {packageItem.creator_first_name} {packageItem.creator_last_name}</b></div>
                            <div><b>{t('adminDashboard.Packages.validity_hours')} {packageItem.package_validity_hours}</b></div>
                            <div><b>{t('adminDashboard.Products.number_of_products')} {packageItem.quantity}</b></div>
                            <div>
                              {packageItem.status === 'M' ? (
                                <CBadge color='success'>{t('adminDashboard.CommonWords.order_placed')}</CBadge>
                              ) : (
                                <CBadge color='warning'>{t('adminDashboard.CommonWords.pending_approval')}</CBadge>
                              )}
                            </div>
                          </CCardText>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  );
                }
                return null; // Return null for items with status 'F' to avoid rendering them
              })
            )
          ) : (
            <h5 className='text-center'>{t('adminDashboard.Customers.no_package_assigned')}</h5>
          )
        }
      </CRow>
    </div>
  );
};
export default CustomerPackages;

