import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CAvatar, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CModalContent, CListGroup, CListGroupItem, CRow, CCol } from "@coreui/react"
import { ProductItem, PossibleArticlePairs, PairedCartItem, Cart } from "../../../types";
import "../AdminComponentStyles.css";
import CIcon from "@coreui/icons-react";
import { cilWarning } from "@coreui/icons";
import { t } from "i18next";

interface ModalProps {
    isMissingModalOpen: boolean;
    setIsMissingModalOpen: (value: boolean) => void;
    isMissingLager: string[];
    isSoldProduct: string[];
    isSoldPallet: string[];
    isDuplicate: string[];
    isBlocked: string[];
}
const ManualPackageCreationErrorModal = (props: ModalProps) => {
    const { isMissingModalOpen, setIsMissingModalOpen, isMissingLager, isSoldPallet, isSoldProduct, isDuplicate, isBlocked } = props;

    // Define error categories dynamically
    const errorCategories = [
        { key: "isBlocked", title: t('adminDashboard.Packages.blocked_ids'), list: isBlocked },
        { key: "isMissingLager", title: t('adminDashboard.Packages.invalid_lager_number'), list: isMissingLager },
        { key: "isDuplicate", title: t('adminDashboard.CommonWords.duplicate_product_ids'), list: isDuplicate },
        { key: "isSoldProduct", title: t('adminDashboard.Packages.sold_products'), list: isSoldProduct },
        { key: "isSoldPallet", title: t('adminDashboard.Packages.sold_pallets'), list: isSoldPallet }
    ];

    // Filter out empty lists
    const activeErrors = errorCategories.filter(category => category.list.length > 0);

    // Dynamically determine modal size
    const getModalSize = (): "xl" | "lg" | "sm" | undefined => {
        if (activeErrors.length >= 5) return "lg";  // Extra large for 5+
        if (activeErrors.length == 4) return "lg";  // Large for 3-4
        if (activeErrors.length >= 3) return undefined;  // Large for 3-4
        if (activeErrors.length === 2) return undefined; // Medium for 2
        return "sm"; // Small for 1 or fewer
    };

    // Dynamically determine column width based on number of active lists
    const getColSize = () => {
        if (activeErrors.length >= 5) return { md: 2, lg: 2, xl: 2 }; // Narrower for more lists
        if (activeErrors.length === 4) return { md: 3, lg: 3, xl: 3 }; // Balanced width
        if (activeErrors.length === 3) return { md: 4, lg: 4, xl: 4 }; // Medium width
        if (activeErrors.length === 2) return { md: 6, lg: 6, xl: 6 }; // Wider columns
        return { md: 12, lg: 12, xl: 12 }; // Full width if only one list
    };

    const colSize = getColSize();

    return (
        <CModal
            className="zero-margin"
            visible={isMissingModalOpen}
            alignment="center"
            size={getModalSize()}  // Dynamically set size
            onClose={() => setIsMissingModalOpen(false)}
        >
            <CModalHeader className="custom-modal-header">
                <h5 className="d-flex align-items-center">
                    <CIcon
                        icon={cilWarning}
                        className="me-2"
                        style={{ fontSize: "1.8rem", color: "white" }}
                    />
                    {t('adminDashboard.CommonWords.detail_overview')}
                </h5>
            </CModalHeader>

            <CModalBody>
                <CRow>
                    {activeErrors.map((category) => (
                        <CCol key={category.key} xs={12} md={colSize.md} lg={colSize.lg} xl={colSize.xl}>
                            <div>
                                <h6>{category.title}</h6>
                                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                                    <CListGroup>
                                        {category.list.map((product: string) => (
                                            <CListGroupItem key={product} className="d-flex justify-content-between">
                                                <span>{product}</span>
                                            </CListGroupItem>
                                        ))}
                                    </CListGroup>
                                </div>
                            </div>
                        </CCol>
                    ))}
                </CRow>

                {/* If all lists are empty */}
                {activeErrors.length === 0 && (
                    <p className="text-muted text-center">{t('No Data Available')}</p>
                )}
            </CModalBody>
        </CModal>
    );
};

export default ManualPackageCreationErrorModal;