
import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSmartTable, CToaster } from '@coreui/react-pro';
import '../CustomerStyles.css';
import { Order, OrderResponse } from '../../types';
import { CHeader } from '@coreui/react';
import { getOrders } from '../../services/OrderService';
import { ORDER_STATUS } from '../../constants';
// import { t } from "i18next";
import { downloadExcelFile } from '../../admin/components/GenerateExcelFile';
import { Utils } from '../../admin/components/utils';
import { setSelectedTab } from '../../redux/slices/CustomerTotalOrdersSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CustomerOrders = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [activePage, setActivePage] = useState(1)
  const [ordersData, setOrdersData] = useState<OrderResponse>();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [records, setRecords] = useState<number>(0);
  const { downloadZipImages, checkPDFStatus, toast, customerOrderColumns } = Utils();

  const getAllOrders = () => {
    getOrders().then((response) => {
      setOrdersData(response.data);
      setRecords(response.data.length);
    }).catch((e) => {
      console.error('error getting orders: ', e);
    });
  }
  useEffect(() => {
    getAllOrders();
    dispatch(setSelectedTab(2));
  }, []);

  useEffect(() => {
    ordersData && setRecords(ordersData?.results?.length);
  }, [ordersData]);

  const handleOpenNewTab = (PackageId: number, url: string) => {
    // Open the link in a new tab using window.open
    const newTab: any = window.open(url, '_blank');
    const serializedProduct = JSON.stringify(PackageId);
    newTab.localStorage.setItem('product', serializedProduct);
  };

  return (
    <div className='website-settings-main-container'>
      <CToaster push={toast} placement="top-end" />
      <CHeader><h1><b>{t('adminDashboard.Customers.my_orders')}</b></h1></CHeader>
      <CSmartTable
        itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
        tableFilter
        tableFilterLabel={`${t('adminDashboard.CommonWords.search')}:`}
        tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
        columns={customerOrderColumns}
        items={ordersData?.results}
        itemsPerPage={itemsPerPage}
        itemsPerPageSelect
        pagination={true}
        noItemsLabel={`${t('adminDashboard.CommonWords.no_items_found')}`}
        paginationProps={{
          activePage: activePage,
          pages: Math.ceil(records / itemsPerPage) || 1,
        }}
        tableProps={{
          hover: true,
          responsive: true,
        }}
        onActivePageChange={(activePage) => setActivePage(activePage)}
        onColumnFilterChange={(filter) => {
          setActivePage(1)
        }}
        onItemsPerPageChange={(itemsPerPage) => {
          setActivePage(1)
          setItemsPerPage(itemsPerPage)
        }}
        scopedColumns={{
          status: (item: any) => {
            return (
              <td className="py-2">
                {/* {ORDER_STATUS[item.status]} */}
                {t('adminDashboard.CommonWords.confirm')}
              </td>
            )
          },
          actions: (item: any) => {
            return (
              <td className="py-2">
                <CDropdown style={{ marginBottom: 10 }}>
                  <CDropdownToggle className="elv-btn" href="#" style={{ width: 100 }}>{t('adminDashboard.CommonWords.action')}</CDropdownToggle>
                  <CDropdownMenu className='pointer' style={{ position: 'absolute' }}>
                    <CDropdownItem onClick={(e) => {
                      handleOpenNewTab(item.package, '/customer/order/details')
                    }}>{t('adminDashboard.CommonWords.view_details')}</CDropdownItem>
                    <CDropdownItem onClick={() => { downloadExcelFile(item.package, false) }}>{t('adminDashboard.OrdersWords.download_excel')}</CDropdownItem>
                    <CDropdownItem onClick={() => { downloadZipImages(item.package_products) }}>{t('adminDashboard.OrdersWords.download_images')}</CDropdownItem>
                    <CDropdownItem onClick={() => { checkPDFStatus(item.id, 'orderPDF')}}>{t('adminDashboard.OrdersWords.download_pdf')}</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </td>
            )
          }
        }}
      />
    </div>
  );
};
export default CustomerOrders;