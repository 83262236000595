import axios from "axios";
import {BACKEND_API_URL} from "../constants";
import {  } from "../types";

const api = axios.create({
    baseURL: BACKEND_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
  });

  const uploadApi = axios.create({
    baseURL: BACKEND_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
  });


export const getCatalogById = async(id: string) => {
    return await api.get(
        `api/catalog/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const filterCatalogByModel = async(model: string) => {
    return await api.get(
        `api/catalog/model/?model=${model}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editCatalog = async(article_number: string, formData: any) => {
    return await api.patch(
        `api/catalog/${article_number}/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const uploadCatalog = async(formData: any) => {
    return await uploadApi.post(
        `api/catalog/upload-catalog/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getAllAvaliableBrandModelNames = async() => {
    return await api.get(
        `api/catalog/brand-product-models/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};