import { CRow, CCol, CFormLabel, CButton, CFormInput, CToaster, CSpinner, CInputGroup, CInputGroupText, CListGroup, CListGroupItem } from "@coreui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { DropDownItem, ProductBrand, ProductSubCategory, ProductItem, Suggestion } from "../../../types";
import '../AdminComponentStyles.css';
import { useEffect, useRef, useState } from "react";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { ELV_BLUE } from "../../../constants";
import Select from 'react-select';

import { fullTextSearchProducts, api } from "../../../services/ProductService";
import { fullTextSearchPallets } from "../../../services/PalletService";
import { useTranslation } from "react-i18next";
import { GenericErrorToast } from "../create-package-components/ToastsComponent";
import { generateSuggestionList } from "../../main-dashboard-screens/products/GenerateSuggestionList";

interface FilterProps {
    setBrandist: (value: string[]) => void;
    setCurrentSubGroupList: (value: string[]) => void;
    setCurrentGradeMin: (value: number) => void;
    setCurrentGradeMax: (value: number) => void;
    setCurrentStatus: (value: DropDownItem) => void;
    setSubGroupList: (value: number[]) => void;
    
    setTempProducts: (value: ProductItem[]) => void;
    setNoReultFound: (value: boolean) => void;
    setLastScannedText: (value: boolean) => void;
    getLatestProduct: () => void;
    filterProducts: (product: ProductItem) => void;
    renderedProducts: ProductItem[];
    tempProducts: ProductItem[];
    currentStatus: DropDownItem;
    currentBrandList: string[];
    currentSubGroupList: string[];
    currentGradeMin: number;
    currentGradeMax: number;
    noReultFound: boolean;
    setLoading: (value: boolean) => void;
}
const ProductHorizontalFilterComponent = (props: FilterProps) => {
    const {
        setBrandist, setCurrentGradeMin, setCurrentGradeMax, tempProducts, setCurrentSubGroupList, getLatestProduct, setTempProducts,
        currentGradeMin, currentGradeMax, setCurrentStatus, setNoReultFound, setLastScannedText, currentBrandList,
        noReultFound, setLoading, currentSubGroupList, currentStatus, filterProducts
    } = props;

    const {t} = useTranslation();
    const productBrands = useSelector((state: RootState) => state.productSlice.productBrands);
    const productSubCategories = useSelector((state: RootState) => state.productSlice.productSubCategories);
    const productSupplies = useSelector((state: RootState) => state.productSlice.productSupplies);
    const productArticles = useSelector((state: RootState) => state.productSlice.productArticles);
    const allAvaliableBrandProductModel = useSelector((state: RootState) => state.productSlice.allAvaliableBrandProductModel);

    const [optionsLoader, setOptionsLoader] = useState<boolean>(false);
    const [subCategoryFilteredOptions, setSubCategoryFilteredOptions] = useState<DropDownItem[]>([]);
    const seenBrands = new Set();  // To make sure the uniqueness of the brands verbose_name
    const [brandOptions, setBrandOptions] = useState<DropDownItem[]>([]); 
    const [productStatusOptions, setProductStatusOptions] = useState<DropDownItem[]>([{ value: 'all', label: `${t('adminDashboard.CommonWords.all')}`}, { value: 'sold', label: `${t('adminDashboard.CommonWords.sold')}` }, { value: 'not_sold', label: `${t('adminDashboard.CommonWords.not_sold')}` }]);
    const [selectedBrandOptions, setSelectedBrandOptions] = useState<DropDownItem[]>([]);
    const [selectedSubcatOptions, setSelectedSubcatOptions] = useState<DropDownItem[]>([]);

    const [toast, addToast] = useState<any>(null);
    const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);
    const [suggestionList, setSuggestionList] = useState<Suggestion[]>([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const searchRef = useRef<HTMLDivElement>(null);
    const [typedValue, setTypedValue] = useState("");
    const MAX_DISTANCE = 2; // Allow minor spelling mistakes
    
    const setGradePointSlider = (products?: ProductItem[]) => {
        if(products) {
            const finalPointsValues = products.filter((product: any) => {
                return filterProducts(product);
              }).map((item: ProductItem) => item.final_points);
            // Find min and max
            const minFinalPoints = Math.min(...finalPointsValues);
            const maxFinalPoints = Math.max(...finalPointsValues);
            // console.log('Max value: ', maxFinalPoints);
            // console.log('Min value: ', minFinalPoints);
            if(minFinalPoints <= maxFinalPoints) {
              setCurrentGradeMin(minFinalPoints);
              setCurrentGradeMax(maxFinalPoints);
            }
        }
        else {
            const finalPointsValues = tempProducts.filter((product: any) => {
                return filterProducts(product);
              }).map((item: ProductItem) => item.final_points);
            // Find min and max
            const minFinalPoints = Math.min(...finalPointsValues);
            const maxFinalPoints = Math.max(...finalPointsValues);
            // console.log('Max value: ', maxFinalPoints);
            // console.log('Min value: ', minFinalPoints);
            if(minFinalPoints <= maxFinalPoints) {
              setCurrentGradeMin(minFinalPoints);
              setCurrentGradeMax(maxFinalPoints);
            }
        }
        
      }
    useEffect(() => {
        setBrandOptions(
            productBrands.filter((brand: ProductBrand) => {
                // Check if the brand.verbose_name has already been added
                if (tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name) && !seenBrands.has(brand.verbose_name)) {
                    seenBrands.add(brand.verbose_name);  // Add the brand.verbose_name to the set
                    return true;  // Include the brand in the filtered results
                }
                return false;  // Exclude duplicate brands
            }).map((brand: ProductBrand) => ({
                value: brand.verbose_name,
                label: brand.verbose_name,
            }))
        )
        setSubCategoryFilteredOptions(
            productSubCategories.filter((subCat: ProductSubCategory) => {
                return tempProducts?.some((item: ProductItem) => item?.sub_category == subCat.name)
            }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
        );
        setProductStatusOptions(
            [{ value: 'all', label: "All" }, { value: 'sold', label: "Sold" }, { value: 'not_sold', label: "Not sold" }]
        )
       setGradePointSlider(tempProducts)
    },[tempProducts])

    useEffect(() => {
        if(currentSubGroupList?.length > 0 && currentStatus.value == 'all') {
            setBrandOptions(
                productBrands.filter((brand: ProductBrand) => {
                    if(tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name && currentSubGroupList?.includes(item?.sub_category)) && !seenBrands.has(brand.verbose_name)) {
                        seenBrands.add(brand.verbose_name);
                        return true
                    }
                    return false;
                   
                }).map((brand: ProductBrand) => ({
                    value: brand.verbose_name,
                    label: brand.verbose_name,
                }))
            )
        }
        else if(currentSubGroupList?.length > 0 && currentStatus.value == 'sold') {
            setBrandOptions(
                productBrands.filter((brand: ProductBrand) => {
                    if(tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name && currentSubGroupList?.includes(item?.sub_category) && item?.is_sold == true) && !seenBrands.has(brand.verbose_name)) {
                        seenBrands.add(brand.verbose_name);
                        return true;
                    }
                   
                    return false;
                }).map((brand: ProductBrand) => ({
                    value: brand.verbose_name,
                    label: brand.verbose_name,
                }))
            )
        }
        else if(currentSubGroupList?.length > 0 && currentStatus?.value == 'not_sold') {
            setBrandOptions(
                productBrands.filter((brand: ProductBrand) => {
                    if(tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name && currentSubGroupList?.includes(item?.sub_category) && item?.is_sold == false) && !seenBrands.has(brand.verbose_name)) {
                        seenBrands.add(brand.verbose_name);
                        return true;
                    }
                    
                    return false;
                }).map((brand: ProductBrand) => ({
                    value: brand.verbose_name,
                    label: brand.verbose_name,
                }))
            )
        }
        else {
            setBrandOptions(
                productBrands.filter((brand: ProductBrand) => {
                    // Check if the brand.verbose_name has already been added
                    if (tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name) && !seenBrands.has(brand.verbose_name)) {
                        seenBrands.add(brand.verbose_name);  // Add the brand.verbose_name to the set
                        return true;  // Include the brand in the filtered results
                    }
                    return false;  // Exclude duplicate brands
                }).map((brand: ProductBrand) => ({
                    value: brand.verbose_name,
                    label: brand.verbose_name,
                }))
            )
        }
        updateStatusOptions();
        setGradePointSlider();
    }, [currentSubGroupList]);

    useEffect(() => {
        if(currentBrandList?.length > 0 && currentStatus.value == 'all') {
            setGradePointSlider();
            let filteredSubCat = productSubCategories.filter((subCat: ProductSubCategory) => {
                return tempProducts?.some((item: ProductItem) => (item?.sub_category == subCat.name) && (currentBrandList?.includes(item.brand)))
            }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
            setSubCategoryFilteredOptions(filteredSubCat);
        }
        else if(currentBrandList?.length > 0 && currentStatus.value == 'sold') {
            setGradePointSlider();
            let filteredSubCat = productSubCategories.filter((subCat: ProductSubCategory) => {
                return tempProducts?.some((item: ProductItem) => (item?.sub_category == subCat.name) && (currentBrandList?.includes(item.brand)) && item?.is_sold == true)
            }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
            setSubCategoryFilteredOptions(filteredSubCat);
        }
        else if(currentBrandList?.length > 0 && currentStatus.value == 'not_sold') {
            setGradePointSlider();
            let filteredSubCat = productSubCategories.filter((subCat: ProductSubCategory) => {
                return tempProducts?.some((item: ProductItem) => (item?.sub_category == subCat.name) && (currentBrandList?.includes(item.brand)) && item?.is_sold == false)
            }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
            setSubCategoryFilteredOptions(filteredSubCat);
        }
        else {
             setSubCategoryFilteredOptions(
                productSubCategories.filter((subCat: ProductSubCategory) => {
                    return tempProducts?.some((item: ProductItem) => item?.sub_category == subCat.name)
                }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
            );
            setGradePointSlider();
        }
        updateStatusOptions();        
    },[currentBrandList]);

    const updateStatusOptions = () => {
        let updatedStatusLabels = [];
        let soldLabelPushed = false;
        let notSoldLabelPushed = false;

        tempProducts?.map((item) => {
            if(currentBrandList?.includes(item.brand) || currentSubGroupList?.includes(item.sub_category)) {
                if(!soldLabelPushed && item.is_sold) {
                    updatedStatusLabels.push({ value: 'sold', label: "Sold" });
                    soldLabelPushed = true;
                }
                else if(!notSoldLabelPushed && !item.is_sold) {
                    updatedStatusLabels.push({ value: 'not_sold', label: "Not sold" });
                    notSoldLabelPushed = true;
                }
            }
        })
        if(soldLabelPushed && notSoldLabelPushed) {
            updatedStatusLabels.push({ value: 'all', label: "All" })
        }
        else if(!soldLabelPushed && !notSoldLabelPushed) {
            updatedStatusLabels.push({ value: 'all', label: "All" })
            updatedStatusLabels.push({ value: 'not_sold', label: "Not sold" });
            updatedStatusLabels.push({ value: 'sold', label: "Sold" });
        }
        setProductStatusOptions(updatedStatusLabels);
    }
    useEffect(() => {
        if(currentStatus.value == 'sold') {
            let filteredSubCat = productSubCategories.filter((subCat: ProductSubCategory) => {
                return tempProducts?.some((item: ProductItem) => (item?.sub_category == subCat.name) && item?.is_sold == true)
            }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
            // console.log(filteredSubCat);
            setSubCategoryFilteredOptions(filteredSubCat);

            let filteredBrands = productBrands.filter((brand: ProductBrand) => {
                if(tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name && item?.is_sold == true) && !seenBrands.has(brand.verbose_name)) {
                    seenBrands.add(brand.verbose_name);  // Add the brand.verbose_name to the set
                    return true;
                }
                return false;
            }).map((brand: ProductBrand) => ({
                value: brand.verbose_name,
                label: brand.verbose_name,
            }))
            setBrandOptions(filteredBrands);
        }
        else if(currentStatus.value == 'not_sold') {
            let filteredSubCat = productSubCategories.filter((subCat: ProductSubCategory) => {
                if(currentBrandList?.length == 0) {
                    return tempProducts?.some((item: ProductItem) => (item?.sub_category == subCat.name) && item?.is_sold == false)
                }
                else {
                    return tempProducts?.some((item: ProductItem) => (item?.sub_category == subCat.name) && (currentBrandList?.includes(item.brand)) && item?.is_sold == false)
                }
              
            }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))

            setSubCategoryFilteredOptions(filteredSubCat);
            let filteredBrands = productBrands.filter((brand: ProductBrand) => {
                if(currentSubGroupList?.length == 0) {
                    if(tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name && item?.is_sold == false) && !seenBrands.has(brand.verbose_name)) {
                        seenBrands.add(brand.verbose_name); 
                        return true;
                    }
                    return false;
                }
                else {
                    if(tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name && currentSubGroupList?.includes(item.sub_category) && item?.is_sold == false) && !seenBrands.has(brand.verbose_name)) {
                        seenBrands.add(brand.verbose_name); 
                        return true;
                    }
                    return false; 
                }
               
            }).map((brand: ProductBrand) => ({
                value: brand.verbose_name,
                label: brand.verbose_name,
            }))
            setBrandOptions(filteredBrands);
        }
        else {
            let filteredSubCat = productSubCategories.filter((subCat: ProductSubCategory) => {
                return tempProducts?.some((item: ProductItem) => (item?.sub_category == subCat.name))
            }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
            
            setSubCategoryFilteredOptions(filteredSubCat);

            let filteredBrands = productBrands.filter((brand: ProductBrand) => {
                if(tempProducts?.some((item: ProductItem) => item?.brand == brand.verbose_name) && !seenBrands.has(brand.verbose_name)) {
                    seenBrands.add(brand.verbose_name); 
                    return true;
                }
                return false;
            }).map((brand: ProductBrand) => ({
                value: brand.verbose_name,
                label: brand.verbose_name,
            }))
            setBrandOptions(filteredBrands);
        }
        setGradePointSlider();
    }, [currentStatus])

    // Generate suggestion list when brands or categories update
    useEffect(() => {
        setSuggestionList(generateSuggestionList(productSupplies, allAvaliableBrandProductModel, productArticles));
        // Click outside handler
        const handleClickOutside = (event: MouseEvent) => {
            if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
                setShowSuggestions(false);
                setShowSuggestions(false);
            }
        };
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [productSupplies, allAvaliableBrandProductModel]);

    const levenshteinDistance = (a: string, b: string): number => {
        const matrix = Array.from({ length: a.length + 1 }, (_, i) => Array(b.length + 1).fill(i));
        for (let j = 1; j <= b.length; j++) matrix[0][j] = j;
    
        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1)
                );
            }
        }
        return matrix[a.length][b.length];
    };
    
    const getFilteredSuggestions = (input: string) => {
        if (!input) return [];
    
        const searchWords = input.toLowerCase().split(" ");
    
        return suggestionList
            .map(item => {
                const lowerProduct = item.suggestionText.toLowerCase();
                // Count how many words from the input are either found in the suggestion OR have a close match
                const matchCount = searchWords.filter(word =>
                    lowerProduct.includes(word) || 
                    levenshteinDistance(lowerProduct, word) <= MAX_DISTANCE
                ).length;
    
                return { suggestionText: item.suggestionText, matchCount };
            })
            .filter(({ matchCount }) => matchCount > 0) // Keep only relevant matches
            .sort((a, b) => b.matchCount - a.matchCount) // More matching words first
            .map(({ suggestionText }) => suggestionText)
            .slice(0, 10); // Limit to top 10 suggestions
    };

    //This function is to get the predefined suggestions
    const handleChange = (e: any) => {
        const value = e.target.value;
        setInputValue(value);
        setSelectedIndex(-1);
        setTypedValue(value);
        if (value.length === 0) {
            setFilteredSuggestions([]);
            setShowSuggestions(false);
            setNoReultFound(false);
            getLatestProduct();
            setSearchTerm('');
        } else if (value.length > 2) {
            setSearchTerm(value);
            const suggestions = getFilteredSuggestions(value);
            setFilteredSuggestions(suggestions);
            setShowSuggestions(suggestions.length > 0);
        }
    };

    const handleSelect = (suggestion: string) => {
        setInputValue(suggestion);
        setSearchTerm(suggestion);
        setShowSuggestions(false);
        setShowSpinner(true);
        setLoading(true);
        setLastScannedText(false);
        fullTextSearchProductAndPallets(suggestion);
    };
  
    const fullTextSearchProductAndPallets = async (search_term: string) => {
        try {
          setShowSpinner(true);
          setLoading(true);
          setLastScannedText(false);
          setNoReultFound(false);
          // Fetch products with the highest probability of matching the provided search term
          const productsSearchResponse = await fullTextSearchProducts(search_term);
          const responseCount = productsSearchResponse?.data?.count;
          if(responseCount && responseCount > 0) {
            fetchFullTextSearchResults(productsSearchResponse, search_term);
          } else {
            const palletsSearchResponse = await fullTextSearchPallets(search_term);
            fetchFullTextSearchResults(palletsSearchResponse, search_term);
          }
        } catch (e:any) {
            setShowSpinner(false);
            setLoading(false);
            setNoReultFound(true);
            const errorMessage = e?.response?.data?.details || e?.message || 'An unknown error occurred';
            addToast(GenericErrorToast(() => { addToast(null) }, ("Error !! " + errorMessage)));
        }
    };

    // Function to fetch full-text-search results and set to setTempProducts
    const fetchFullTextSearchResults = async (responseObject:any, search_term:string): Promise<any> => {
        try {
            if (typeof responseObject !== 'object' || responseObject === null || responseObject === 'undefined') {
                setShowSpinner(false);
                addToast(GenericErrorToast(() => { addToast(null) }, ("Error !! Expected an object")));
            } else {
                let allResults = [...responseObject?.data?.results];
                let nextPageUrl = responseObject?.data?.next;
                setTempProducts([...responseObject?.data?.results]);
                setLoading(false);
                setShowSpinner(false);
                

                // Fetch additional pages of results using POST requests with search_term parameter and append output to previously fetched results
                if(!nextPageUrl) {
                    setGradePointSlider([...responseObject?.data?.results]);
                    setOptionsLoader(false);
                }
                while (nextPageUrl) {
                    const nextPageResponse = await api.post(nextPageUrl, { search_keyword: search_term });
                    nextPageUrl = nextPageResponse?.data?.next;
                    // Append new results to the existing ones
                    allResults = [...allResults, ...nextPageResponse?.data?.results];
                    // Update tempProducts progressively with the new results
                    setTempProducts(allResults);
                    if(!nextPageUrl) {
                        setGradePointSlider(allResults);
                        setOptionsLoader(false);
                    }
                };
            };            
        } catch (e:any) {
            setShowSpinner(false);
            const errorMessage = e?.response?.data?.details || e?.message || 'An unknown error occurred';
            addToast(GenericErrorToast(() => { addToast(null) }, ("Error !! " + errorMessage)));
        }
    };

    const applyFilter = () => {
        setOptionsLoader(true);
        setShowSpinner(true);
        setLoading(true);
        setLastScannedText(false);
        setShowSuggestions(false);
        fullTextSearchProductAndPallets(searchTerm);
    };
    useEffect(() => {
        if(showSpinner) {
            setCurrentSubGroupList([]);
            setBrandist([]);
            setCurrentStatus({ value: 'all', label: `${t('adminDashboard.CommonWords.all')}`});
            setSelectedBrandOptions([]);
            setSelectedSubcatOptions([]);
        }
    },[showSpinner])

    const handleKeyDown = (e: any, ) => {
        if (showSuggestions) {
            if (e.key === "ArrowDown") {
                setSelectedIndex(prev => {
                    const newIndex = prev < filteredSuggestions.length - 1 ? prev + 1 : prev;
                    setSearchTerm(filteredSuggestions[newIndex]);
                    setInputValue(filteredSuggestions[newIndex]);
                    return newIndex;
                });
            } else if (e.key === "ArrowUp") {
                setSelectedIndex(prev => {
                    const newIndex = prev > 0 ? prev - 1 : prev;
                    setSearchTerm(filteredSuggestions[newIndex]);
                    setInputValue(filteredSuggestions[newIndex]);
                    if(newIndex == -1) {
                        setSelectedIndex(-1);
                        setSearchTerm(typedValue);
                        setInputValue(typedValue);
                    }
                    if(newIndex == 0) {
                        setSelectedIndex(-1);
                    }
                    return newIndex;
                });
            } else if (e.key === "Enter") {
                applyFilter();
                setShowSuggestions(false);
                
                setSelectedIndex(prev => {
                    const newIndex = prev < filteredSuggestions.length ? prev : prev;
                    setInputValue(filteredSuggestions[newIndex]);
                    return newIndex;
                });
            } else if (e.key === "Escape") {
                setShowSuggestions(false);
            }
        } else {
            if (e.key === "Enter" && searchTerm !== "") {
                applyFilter();
            }
        }
    };
    // Handle clearing input
    const handleClear = () => {
        setInputValue('');
        setShowSuggestions(false);
        setFilteredSuggestions([]);
        setNoReultFound(false);
        getLatestProduct();
        setSearchTerm('');
    };

    return (
        <>
        <CToaster push={toast} placement="top-end"/>
        <CRow ref={searchRef} className="col-sm-12 justify-content-start">
            {/* Full text Search */}
            <CCol xs={12} md={8} className="position-relative">
                {/* <Search /> */}
                <CInputGroup className={`${"search-bar-input"} ${isSearchFocused ? "custom-input-shadow-focus" : "custom-input-shadow-blur"}`} 
                    onBlur={()=> {
                        setIsSearchFocused(false);
                       
                    }}
                    onFocus={(e) => {
                        setIsSearchFocused(true);
                        
                    }}>
                    <CInputGroupText onClick={handleClear} className="clear-button" >
                            <img
                                style={{ width: "12px", height: "12px" }} 
                                src={require('../../../assets/images/search-icon.png')}
                            />
                    </CInputGroupText>
                    <CFormInput
                        placeholder={`${t('adminDashboard.CommonWords.full_text_search')}`}
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        className="custom-input"
                    />
                    {/* Cross Button (Unicode ✖) to Clear Input */}
                    {searchTerm && (
                        <CInputGroupText onClick={handleClear} className="clear-button" >
                            <span
                                onClick={() => {handleClear()}}
                            >
                                &#10005;
                            </span>
                        </CInputGroupText>
                    )}
                </CInputGroup>
                {/* Suggestions List */}
                {showSuggestions && (
                <CListGroup className="suggestions-list" >
                    {filteredSuggestions.map((suggestion, index) => {
                        const matchIndex = suggestion.toLowerCase().indexOf(typedValue.toLowerCase());

                        if (matchIndex !== -1) {
                            const prefix = suggestion.substring(0, matchIndex); // Before match
                            const match = suggestion.substring(matchIndex, matchIndex + typedValue.length); // Matched text
                            const suffix = suggestion.substring(matchIndex + typedValue.length); // After match

                            return (
                                <CListGroupItem
                                    key={index}
                                    className={`suggestions-list-item ${index === selectedIndex ? "selected" : ""}`}
                                    onClick={() => handleSelect(suggestion)}
                                   
                                >
                                    {prefix && <span style={{ color: "darkgrey" }}>{prefix}</span>}
                                    <span style={{ color: "black", fontWeight: "bold" }}>{match}</span>
                                    {suffix && <span style={{ color: "darkgrey" }}>{suffix}</span>}
                                </CListGroupItem>
                            );
                        } else {
                            return (
                                <CListGroupItem
                                    key={index}
                                    className={`suggestions-list-item ${index === selectedIndex ? "selected" : ""}`}
                                    onClick={() => handleSelect(suggestion)}
                                >
                                    <span style={{ color: "darkgrey" }}>{suggestion}</span>
                                </CListGroupItem>
                            );
                        }
                    })}
                </CListGroup>
            )}
            </CCol>

            {/* Search Button */}
            <CCol xs={12} md={2} className="d-flex justify-content-center align-items-center mt-3 mt-md-0">
                <CButton
                    variant="outline"
                    shape="square"
                    className="elv-btn d-flex justify-content-center align-items-center"
                    onClick={applyFilter}
                    disabled={searchTerm.length === 0 || showSpinner}
                    style={{ minWidth: '150px', height: '40px' }}
                >
                    {showSpinner ? <CSpinner size="sm" /> : t('adminDashboard.CommonWords.search') }
                </CButton>
            </CCol>
        </CRow>
        
        {
            tempProducts?.length > 1 && noReultFound == false ?
            <>
                <h4 className="margin-top-44"><b>Filters</b></h4>
                <CRow className="margin-top-16">
                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.Products.brands')}</b></CFormLabel>
                        <Select
                            isLoading={optionsLoader}
                            isDisabled={optionsLoader}
                            placeholder={`${t('adminDashboard.CommonWords.select')}`}
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedBrandOptions}
                            options={brandOptions}
                            onChange={(options: any) => {
                                setBrandist(
                                    options.map((option: any) => (option.label))
                                )
                                setSelectedBrandOptions(options);
                            }}
                        />
                    </CCol>

                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.Products.category')}</b></CFormLabel>
                        <Select
                            isLoading={optionsLoader}
                            isDisabled={optionsLoader}
                            placeholder={`${t('adminDashboard.CommonWords.select')}`}
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedSubcatOptions}
                            options={subCategoryFilteredOptions} 
                            onChange={(options: any) => {
                                setCurrentSubGroupList(
                                    options.map((option: any) => (option.label))
                                )
                                setSelectedSubcatOptions(options)
                            }}
                        />
                    </CCol>
                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.CommonWords.status')}</b></CFormLabel>
                        <Select
                            isLoading={optionsLoader}
                            isDisabled={optionsLoader}
                            // defaultValue={productStatusOptions[0]}
                            value={currentStatus}
                            options={productStatusOptions} 
                            onChange={(option: any) => {
                                setCurrentStatus(option);
                            }}
                        />
                    </CCol>
                    <CCol xs={12} md={3} >
                        <CFormLabel><b>{t('adminDashboard.Products.grade_point')}</b></CFormLabel>
                        {
                            optionsLoader ?
                                <div className="text-center">
                                    <CSpinner />
                                </div>
                               
                            :
                                <MultiRangeSlider
                                    minValue={currentGradeMin}
                                    maxValue={currentGradeMax}
                                    min={0}
                                    max={100}
                                    onInput={(e) => {
                                        setCurrentGradeMin(e.minValue);
                                        setCurrentGradeMax(e.maxValue);
                                    }}
                                    onChange={(e: ChangeResult) => {
                                        // This function changes values after dragging is done.
                                        setCurrentGradeMin(e.minValue);
                                        setCurrentGradeMax(e.maxValue);
                                    }}
                                    label={true}
                                    ruler={false}
                                    style={{ border: "none", boxShadow: "none", padding: "15px 10px", marginTop: 25 }}
                                    barLeftColor="white"
                                    barInnerColor={ELV_BLUE}
                                    barRightColor="white"
                                    thumbLeftColor={ELV_BLUE}
                                    thumbRightColor={ELV_BLUE}
                                />
                        }
                    </CCol>
                </CRow>
            </>
            : null
        }
      </>
    );
}
export default ProductHorizontalFilterComponent;