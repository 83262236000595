import { CSmartTable } from "@coreui/react-pro";
import { useState, useEffect } from "react";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ExcelDataObject, Supplier } from "../../../types";
import { deleteManualProductPrices, getManualProductPrices } from "../../../services/ProductService";
import { setManualProductPrices } from "../../../redux/slices/ProductSlice";
import { cilPen, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import DeleteItemModal from "../modals/DeleteItemModal";
import EditPriceModal from "../modals/EditPriceModal";
import { Utils } from "../utils";

interface ManualProductPricesProps {
  showEditedToast: () => void;
  showDeletedToast: () => void;
}

const ManualProductPrices = (props: ManualProductPricesProps) => {
    const {showDeletedToast, showEditedToast} = props;
    const dispatch = useDispatch();
    const {ManualPricesColumns} = Utils()
    const manualProductPrices = useSelector((state: RootState) => state.productSlice.manualProductPrices);
    const [showEditPriceModal, setShowEditPriceModal] = useState<boolean>(false);
    const [selectedExcelObject, setSelectedExcelObject] = useState<ExcelDataObject>();
    const [showDeletePriceModal, setShowDeletePriceModal] = useState<boolean>(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    
   
    useEffect(() => {
      // getAllManualProductPrices();
    }, [])
    const getAllManualProductPrices = () => {       
      getManualProductPrices().then((response) => {
        setShowEditPriceModal(false);
        setShowDeletePriceModal(false);
        dispatch(setManualProductPrices(response.data));
      }).catch((e) => {
        dispatch(setManualProductPrices([]));
      });
    }
    const deletePrice = () => {
      deleteManualProductPrices(selectedExcelObject && selectedExcelObject.id ? selectedExcelObject.id : 0).then((response) => {
        showDeletedToast();

        getAllManualProductPrices();
      }).catch((e) => {

      });      
    }

    return (
    <div>
        {
          showEditPriceModal ?
            <EditPriceModal 
              isModalVisible={showEditPriceModal}
              headerText={"Edit Prices."}
              bodyText={"You can edit the following prices."}
              selectedItem={selectedExcelObject}
              closeModal={() => setShowEditPriceModal(false)}
              confirmButtonText={"Edit"}
              onClickConfirmation={() => {showEditedToast(); getAllManualProductPrices(); }}

            /> : null
        }
        {
          showDeletePriceModal ?
            <DeleteItemModal 
              isModalVisible={showDeletePriceModal}
              headerText={"Are you sure?"}
              bodyText={"This will delete the item permanently."}
              closeModal={() => setShowDeletePriceModal(false)}
              confirmButtonText={"Delete"}
              onClickConfirmation={deletePrice}
              size="sm"
            /> : null
        }
        <CSmartTable
            tableFilter
            tableFilterPlaceholder="Search"
            columns={ManualPricesColumns}
            items={manualProductPrices}           
            itemsPerPage={itemsPerPage}
            itemsPerPageSelect
            pagination={true}
            paginationProps={{
              activePage: activePage,
              pages: Math.ceil(manualProductPrices.length / itemsPerPage) || 1,
            }}

            tableProps={{
              hover: true,
              responsive: true,
            }}

            onActivePageChange={(activePage) => setActivePage(activePage)}
            onColumnFilterChange={(filter) => {
              setActivePage(1)
            }}

            onItemsPerPageChange={(itemsPerPage) => {
              setActivePage(1)
              setItemsPerPage(itemsPerPage)
            }}

            scopedColumns={{
              Lager_Number: (item: ExcelDataObject) => {
                return (
                    <td className="py-2 text-center">
                      {item.Lager_Number}
                    </td>
                )
              },
              Selling_Price: (item: ExcelDataObject) => {
                return (
                    <td className="py-2 text-center">
                      {item.Selling_Price}
                    </td>
                )
              },
              Buying_Price: (item: ExcelDataObject) => {
                return (
                    <td className="py-2 text-center">
                      {item.Buying_Price}
                    </td>
                )
              },
              Buying_Online_Price: (item: ExcelDataObject) => {
                return (
                    <td className="py-2 text-center">
                      {item.Buying_Online_Price}
                    </td>
                )
              },
              show_details: (item: ExcelDataObject) => {
                  return (
                      <td className="py-2 text-center">
                        <CIcon className="pointer" icon={cilPen} size="xl" style={{color: '#23B2D9', marginRight: 24}} onClick={() => {setSelectedExcelObject(item); setShowEditPriceModal(true);}}/>
                        <CIcon className="pointer" icon={cilTrash} size="xl" style={{color: 'red'}} onClick={() => {setSelectedExcelObject(item); setShowDeletePriceModal(true);}}/>
                      </td>
                  )
              },

            }}
        />
    </div>
    )
};
export default ManualProductPrices;
