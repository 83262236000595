import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Cart, Package, Pallet, ProductItem } from '../../types';
export interface PackagesSliceState {
    allPackagesCount: number;
    packages: Package[];
    pendingPackages: Package[];
    packagePageNumber: number;
    products: ProductItem[];
    setArticles: ProductItem[];
    pallets: Pallet[];
    selectedProducts: number[]; // store only the ids of selected product items.
    selectedPallets: any; // store only the ids of selected pallet items.
    selectedSetArticles: number[]; //store only the ids of selected set-articles items.
    cart: Cart; // This stores only the ids of cart Items (paired, unpaired and standalone products).
    selectedCartItems: ProductItem[]; // Stores items objects that are in the cart.
    packageTotalPrice: number;
    discount: number;
    discountType: string;
}
const initialState: PackagesSliceState = {
    allPackagesCount: 0,
    packages: [],
    pendingPackages: [],
    products: [],
    pallets: [],
    setArticles: [],
    selectedProducts: [],
    selectedPallets: [],
    selectedSetArticles: [],
    cart: {
      paired: [],
      unpaired: [],
      standalone: [],
      pallets: [],
    },
    selectedCartItems: [],
    packagePageNumber: 1,
    packageTotalPrice: 0,
    discount: 0,
    discountType: 'percentage' as 'percentage' | 'amount',
  };

const packageSlice = createSlice({
    name: 'packageSlice',
    initialState,
    reducers: {
      addNewPackage(state, action: PayloadAction<Package>) {
        state.packages = [
          action.payload,
          ...state.packages
        ];
      },
      setAllPackagesCount(state, action: PayloadAction<number>) {
        state.allPackagesCount = action.payload;
      },
      setPackages(state, action: PayloadAction<any>) {
        state.packages = action.payload;
      },
      setPendingPackages(state, action: PayloadAction<any>) {
        state.pendingPackages = action.payload;
      },
      setPackageProducts(state, action: PayloadAction<any>) {
        state.products = action.payload;
      },
      setPallets(state, action: PayloadAction<any>) {
        state.pallets = action.payload;
      },
      setSetArticles(state, action: PayloadAction<any>) {
        state.setArticles = action.payload;
      },
      setSelectedProducts(state, action: PayloadAction<any>) {
        state.selectedProducts = action.payload;
      },
      setSelectedPallets(state, action: PayloadAction<any>) {
        state.selectedPallets = action.payload;
      },
      setSelectedSetArticles(state, action: PayloadAction<any>) {
        state.selectedSetArticles = action.payload;
      },
      setCart(state, action: PayloadAction<any>) {
        state.cart = action.payload;
      },
      setPackagePageNumber(state, action: PayloadAction<any>) {
        state.packagePageNumber = action.payload;
      },
      setSelectedCartItems(state, action: PayloadAction<any>) {
        state.selectedCartItems = action.payload;
      },
      setPackageTotalPrice(state, action: PayloadAction<any>) {
        state.packageTotalPrice = action.payload;
      },
      setPackageDiscount(state, action: PayloadAction<{ value: number; type: 'percentage' | 'amount' }>) {
        state.discount = action.payload.value;
        state.discountType = action.payload.type;
      },
    },
  })
  
  export const { setPendingPackages, setPackages, setPackageProducts, setSetArticles, setSelectedProducts, setSelectedSetArticles, setAllPackagesCount,
    setPallets, setSelectedPallets, setCart, setPackagePageNumber, setSelectedCartItems, setPackageTotalPrice, setPackageDiscount, addNewPackage } = packageSlice.actions;
  export default packageSlice.reducer;