import axios from "axios";
import {BACKEND_API_URL} from "../constants";
import { ExcelDataObject, SupplyTypePost, filterRequestObject } from "../types";

export const api = axios.create({
    baseURL: BACKEND_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
  });

  const uploadApi = axios.create({
    baseURL: BACKEND_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data',
    }
  });

const imageAPI = axios.create({
    baseURL: 'https://5vymjwmn02.execute-api.eu-central-1.amazonaws.com/',
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': 'YYltflV8LP492bltrm2XC4jWVKauVEPw4rYhHNyj',
    }
  });
export const getProducts = async(page: number = 1) => {
    return await api.get(
        `api/products/?page=${page}`,

    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editProductDetails = async(id: number, form_data: any) => {
    return await api.patch(
        `api/products/${id}/`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};


export const getProductBrands = async() => { // Get all the product brands. and this is used while searching for a specific product.
    return await api.get(
        'api/brand/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};
export const getProductCategories = async() => { // Get all the product categories. and this is used while searching for a specific product.
    return await api.get(
        'api/category/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getProductSubCategories = async() => { // Get all the product categories. and this is used while searching for a specific product.
    return await api.get(
        'api/sub-category/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getProductArticles = async() => { // Get all the product artiles. and this is used while filtering the products with specific article number.
    return await api.get(
        'api/products/article-numbers/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getAllArticles = async() => { 
    return await api.get(
        'api/catalog/articles/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const uploadProductManually = async(formData: any) => {
    return await uploadApi.post(
        `api/products/bulk_upload_products_description/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getProductSupplies = async() => { // Get all the product supplies. and this is used while filtering the products with specific supply number.
    return await api.get(
        'api/supply/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getTypeProductSupplies = async(type: string) => { // Get all the product supplies. and this is used while filtering the products with specific supply number.
    return await api.get(
        `api/supply/by_type/?type=${type}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getProductSuppliesInformation = async (id: string) => {
    return await api.get(
        `api/supply/supply-info/?supply=${id}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const addProductManualPrices = async(formdata: any) => { // Get all the product supplies. and this is used while filtering the products with specific supply number.
    return await api.post(
        'api/manual-prices/upload/',
        formdata,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getManualProductPrices = async () => {
    return await api.get(
        'api/manual-prices/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const editManualProductPrices = async (id : number, form_data : ExcelDataObject) => {
    return await api.patch(
        `api/manual-prices/${id}/`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const deleteManualProductPrices = async (id: number) => {
    return await api.delete(
        `api/manual-prices/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const addProductSupplies = async(formdata: any) => { // Get all the product supplies. and this is used while filtering the products with specific supply number.
    return await api.post(
        'api/supply/',
        formdata,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editProductSupplies = async(id: string, formdata: any) => { // Get all the product supplies. and this is used while filtering the products with specific supply number.
    return await api.patch(
        `api/supply/${id}/`,
        formdata,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const deleteProductSupply = async(id: string) => { // Get all the product supplies. and this is used while filtering the products with specific supply number.
    return await api.delete(
        `api/supply/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getSupplyTypes =  async() => {
    return await api.get(
        'api/supply-type/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editSupplType = async(id: number, formdata: any) => {
    return await api.patch(
        `api/supply-type/${id}/`,
        formdata,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const deleteSupplyTypes =  async(id: number) => {
    return await api.delete(
        `api/supply-type/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const addSupplyTypes =  async(formData: SupplyTypePost) => {
    return await api.post(
        'api/supply-type/',
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const addSuppliers =  async(formData: any) => {
    return await api.post(
        'api/suppliers/',
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editSuppliers =  async(id: number, formData: any) => {
    return await api.patch(
        `api/suppliers/${id}/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const deleteSuppliers =  async(id: number) => {
    return await api.delete(
        `api/suppliers/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getSuppliers =  async() => {
    return await api.get(
        'api/suppliers/',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getProductsBySupplyId = async(supply: string) => {
    return await api.get(
        `api/products/supply/?supply=${supply}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getProductById = async(id: string | number) => {
    return await api.get(
        `api/products/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const scanWarehouseProduct = async(id: string | number) => {
    return await api.get(
        `api/products/scan-warehouse-product/?product=${id}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const scanWarehousePallet = async(id: string | number) => {
    return await api.get(
        `api/pallets/scan-warehouse-pallet/?pallet=${id}`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getSetArticleById = async(id: string | number) => {
    return await api.get(
        `api/set-articles/${id}/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const editProductData = async(id: number, form_data: any) => {
    return await api.patch(
        `api/products/${id}/`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const applyProductFilters = async(filtersData: filterRequestObject, page: number) => {
    return await api.post(
        `api/products/query/?page=${page}`,
        filtersData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const applyProductFiltersWithoutPaginate = async(filtersData: filterRequestObject) => {
    if (filtersData?.sub_category) {
        return await api.post(
            `api/products/fetch-products-by-category/`,
            {
                is_deleted: filtersData?.is_deleted,
                sub_category: filtersData?.sub_category,
            },
        ).then((response) => {
            return response;
        }).catch(function (error) {
            throw error;
        });
    } else {
        return await api.post(
            `api/products/query/`,
            filtersData,
        )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
    }
};

export const getFilteredSingleProducts = async(filtersData: filterRequestObject) => {
    return await api.post(
        `api/products/query/`,
        filtersData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getProductsByUploadingFile = async (formData: FormData) => {
    try {
        const response = await api.post(`api/products/query-products-by-ids/`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getUsage = async() => {
    return await api.get(
        `api/usage/`,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const downloadImages = async(form_data: any) => {
    return await api.post(
        `api/zip-download/`,
        form_data,
        {
            responseType: 'blob',
        }
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

// export const replaceImage = async(form_data: any) => {
//     return await api.post(
//         `api/products/image_replace/`,
//         form_data,
//     )
//     .then((response) => {
//         return response;
//     })
//     .catch(function (error) {
//         throw error;
//     });
// };


export const replaceImage = async(id: number, form_data: any) => {
    return await imageAPI.post(
        `upload?lager=${id}`,
        form_data,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const getActiveProductsCount = async() => {
    return await api.get(
        `api/products/active-products-count/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const getProductsCategoryQuestions = async() => {
    return await api.get(
        `api/category-question/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const filterSupplyInventory = async(formData: any) => {
    return await api.post(
        `api/supply-inventory/supply/`,
        formData,
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};

export const latestProduct = async() => {
    return await api.get(
        `api/products/latest-product/`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const getCRMData = async(value: string) => {
    return await api.get(
        `api/crm/get-crm-data/?path=${value}`
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const postCRMData = async(value: string, formData: any) => {
    return await api.post(
        `api/crm/post-crm-data/?path=${value}`,
        JSON.stringify(formData),
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const putCRMData = async(value: string, formData: any) => {
    return await api.put(
        `api/crm/put-crm-data/?path=${value}`,
        JSON.stringify(formData),
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
}

export const fullTextSearchProducts= async(search_term: string) => {
    return await api.post(
        `api/products/full-text-search-products/`,
        {
            search_keyword: search_term
        }
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        throw error;
    });
};