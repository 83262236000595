import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CCol, CRow, CSpinner } from "@coreui/react";
import "../AdminComponentStyles.css";
import { t } from "i18next";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: any;
    bodyDetail?: any;
    closeModal: () => void;
    onClickConfirmation: () => void;
    confirmButtonText: string;
    hideCancelButton?: boolean;
    loading?: boolean;
    size?: 'sm' | 'lg' | 'xl';
}
const GenericConfirmationModal = (props: ModalProps) => {
    const {size, isModalVisible, closeModal, headerText, onClickConfirmation, bodyText, confirmButtonText, hideCancelButton, loading, bodyDetail} = props;

    return (
        <CModal size={size} visible={isModalVisible} onClose={closeModal} className="zero-margin">
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{loading ? <CRow><CCol className="text-center"><CSpinner /></CCol></CRow> : null}</div>
                <div>{bodyText}</div>
                {
                    bodyDetail ?
                        <div>{bodyDetail && bodyDetail}</div>
                        : null
                }
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={onClickConfirmation}>{confirmButtonText}</CButton>               
                {!hideCancelButton? <CButton color="primary" onClick={closeModal}>{`${t('adminDashboard.CommonWords.cancel')}`}</CButton> : null }
            </CModalFooter>
        </CModal>
    );
}
export default GenericConfirmationModal;