import { CButton } from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CSmartTable } from "@coreui/react-pro";
import { ScanningTimeObject } from "../../types";
import { UserScanningTimeData } from "../components/GenerateExcelFile";
import { setScanningTime } from "../../redux/slices/AdminSlice";
import { getUserScanningTime } from "../../services/AnalyticsService";
import { AnalyticsUtils } from "../components/utils/SalesAnalyticsUtils";
import { t } from "i18next";

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize-width', handleResize);
    // Clean up
    return () => window.removeEventListener('resize-width', handleResize);
  }, []);
  return width;
}
function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize-height', handleResize);
    // Clean up
    return () => window.removeEventListener('resize-height', handleResize);
  }, []);

  return height;
}

const CustomerScanningTime = () => {
  const [windowWidth, setWindowWidth] = useState(useWindowWidth());    // Get the current window width
  const [windowHeight, setWindowHeight] = useState(useWindowHeight());
  const dispatch = useDispatch();
  const { CustomerScanningColumns } = AnalyticsUtils();
  const scanningTime: ScanningTimeObject[] = useSelector(
    (state: RootState) => state.adminSlice.scanningTime
  );
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getUserScanningTime().then((response) => {
      dispatch(setScanningTime(response?.data?.data));
    });
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="website-settings-main-container" style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "left", marginBottom: "20px" }}>
        <b>{t("adminDashboard.ScanningTime.sidebar_text")}</b>
      </h1>

      {/* Table Container to Enable Scrolling */}
      <div style={{ width: "100%", overflowX: "auto", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: windowWidth - 70,
            height: windowHeight - 240,
            position: "relative",
          }}
        >
          <CSmartTable
            columns={CustomerScanningColumns}
            columnFilter
            columnSorter
            items={scanningTime}
            itemsPerPage={scanningTime?.length}
            loading={loading}
            pagination={false}
            tableHeadProps={{
              color: "info",
              style: {
                position: "sticky",
                top: -7,
                zIndex: 50,
                backgroundColor: "white",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
              },
            }}
            tableProps={{
              hover: true,
              responsive: false, // Set to false to allow internal scrolling
              style: {
                marginTop: 0,
                tableLayout: "fixed",
                width: "100%",
                minWidth: "1280px",
              },
              striped: true,
            }}
            tableBodyProps={{
              className: "align-middle",
            }}
            scopedColumns={{
              user_name: (item: any) => <td className="text-center">{item.user_name}</td>,
              month: (item: any) => <td className="text-center">{item.month}</td>,
              No_of_Products_Scanned: (item: any) => (
                <td className="text-center">{item["No_of_Products_Scanned"]}</td>
              ),
              Average_Scanning_Time_sec: (item: any) => (
                <td className="text-center">{item["Average_Scanning_Time_sec"]}</td>
              ),
              Average_Scanning_Time_min: (item: any) => (
                <td className="text-center">{item["Average_Scanning_Time_min"]}</td>
              ),
            }}
          />
        </div>
      </div>

      {/* Excel Download Button */}
      {/* <div style={{ display: "flex", justifyContent: "left", marginTop: "20px" }}>
        <CButton className="elv-btn" size="sm" onClick={() => UserScanningTimeData(scanningTime)}>
          Download Excel
        </CButton>
      </div> */}
    </div>
  );
};

export default CustomerScanningTime;