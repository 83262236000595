import { cilTruck, cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCol, CNav, CNavItem, CRow, CSpinner, CFormCheck, CTooltip } from "@coreui/react";
import ProgressBar from "@ramonak/react-progress-bar";
import { formattedPrice, CUSTOMER_GROUP_ID } from "../../constants";
import { Package, LoggedinUser } from "../../types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isMobile } from "react-device-detect";

interface PackageDetailNonStickyHeaderProps {
    loading: boolean;
    totalPrice: number;
    productCart: number[];
    numOfTrucks:  number;
    truckCapacity: number;
    disableConfirmButton: boolean;
    packageItem: Package | undefined;
    handleShowModal: () => void;
    calculateTruckCapacity: () => void;
    showRefreshButton: boolean;
    isChecked: boolean;
    checkTermsAcceptanceDate: Date;
    setIsChecked: (value: boolean) => void;
    isDiscounted: boolean;
    discountPercentage: string;
    discountPoint: number;
    afterDiscountPrice: number;
    discountAmount: number;
}

const PackageDetailNonStickyHeader = (props: PackageDetailNonStickyHeaderProps) => {
    const {loading, numOfTrucks, handleShowModal, disableConfirmButton, truckCapacity, isDiscounted, discountPercentage, afterDiscountPrice, discountPoint, discountAmount,
        productCart, packageItem, totalPrice, calculateTruckCapacity, showRefreshButton, isChecked, checkTermsAcceptanceDate, setIsChecked} = props;
    const {t} = useTranslation();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
  
    const handleOpenNewTab = (url: string) => {
        return window.open(url, '_blank', 'noopener noreferrer');

    }

    return (
        <div>
            <CNav style={isMobile ? { justifyContent: 'flex-start', alignItems: 'flex-start' } : { justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <CRow>
                    <CCol className={"max-width"} xs={6}>
                        <CNavItem>
                            {
                                loading ?
                                    <CRow>
                                        <CCol>
                                            <CSpinner />
                                        </CCol>
                                    </CRow>
                                    : null
                            }
                        </CNavItem>
                    </CCol>
                </CRow>
                <CRow className={isMobile ? "max-width" : ""}>
                    <CCol xs={12} className={isMobile ? "max-width" : ""}>
                        <CNavItem>
                            <h4>{numOfTrucks} x </h4>
                            <CIcon icon={cilTruck} className="me-2" size="3xl" />
                            {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)
                            <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#23B2D9" width="100%" customLabel=" " />
                        </CNavItem>
                        {
                            !checkTermsAcceptanceDate && (
                                <CNavItem className="margin-top-12">
                                    <div className="d-flex align-items-center">
                                        <CFormCheck
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) => setIsChecked(e?.target?.checked)}
                                            label={
                                                <>
                                                {t('adminDashboard.CommonWords.terms_conditions_agreement')}{' '}
                                                <span
                                                    onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (authUser?.groups && authUser.groups.includes(CUSTOMER_GROUP_ID)) {
                                                        handleOpenNewTab('/customer/terms-conditions');
                                                    }
                                                    }}
                                                    className="url-decoration-sticky elv-blue"
                                                >
                                                    {t('adminDashboard.CommonWords.terms_conditions_pdf')}
                                                </span>
                                                </>
                                            }
                                        />
                                    </div>
                                </CNavItem>
                            )
                        }
                    </CCol>
                </CRow>
                <CRow>
                    <CCol className={"max-width"} xs={6} style={{marginTop: isMobile ? 12 : 0}}>
                        <CNavItem>
                        {
                            showRefreshButton ?
                            <CButton
                                className='elv-btn'
                                variant="outline"
                                shape="square"
                                style={isMobile ? {marginRight: 12} : { marginLeft: 15 }}
                                onClick={calculateTruckCapacity}
                                disabled={productCart.length == 0}
                            >
                                {t('adminDashboard.CommonWords.refresh')}  
                                <CIcon
                                    icon={cilReload}
                                />
                            </CButton>
                            : null
                        }
                        </CNavItem>
                    </CCol>
                </CRow>
                {
                    packageItem && packageItem.status === 'A' ? (
                            <CRow>
                                <CCol className={"max-width"} xs={6} style={{marginTop: isMobile? 12 : 24}}>
                                    <CNavItem>
                                    {
                                        // Check the condition and show the tooltip if the condition is met
                                        !isChecked && !checkTermsAcceptanceDate && checkTermsAcceptanceDate === null && !showRefreshButton && productCart?.length > 0 ? (
                                        <CTooltip content={t('adminDashboard.CommonWords.terms_conditions_not_acceptance_error_message')} placement='top'>
                                            <div style={{ marginLeft: isMobile ? 0 : '20px', marginRight: '12px' }}>
                                                <CButton
                                                    className='elv-btn'
                                                    variant="outline"
                                                    shape="square"
                                                    disabled={productCart?.length === 0 || disableConfirmButton || loading || (!isChecked && checkTermsAcceptanceDate === null)}
                                                    onClick={() => { handleShowModal()}}
                                                >{t('adminDashboard.Packages.confirm_package')}</CButton>
                                            </div>
                                        </CTooltip>
                                        ) : (
                                            <div style={{ marginLeft: isMobile ? 0 : '20px', marginRight: '12px' }}>
                                                <CButton
                                                    className='elv-btn'
                                                    variant="outline"
                                                    shape="square"
                                                    disabled={productCart?.length === 0 || disableConfirmButton || loading || (!isChecked && checkTermsAcceptanceDate === null)}
                                                    onClick={() => { handleShowModal()}}
                                                >{t('adminDashboard.Packages.confirm_package')}</CButton>
                                            </div>
                                        )
                                    }
                                    </CNavItem>
                                </CCol>
                            </CRow>
                        ) : null
                    }
                <CRow>
                    <CCol className={"max-width"} xs={6} style={{marginTop: isMobile? 20 : 0}}>
                        <CNavItem>
                            <h5 style={{ marginBottom: isMobile ? 0: 8, marginLeft: isMobile ? 0 : '12px', marginRight: '12px' }}><b>{t('adminDashboard.Packages.total_items')}: {productCart?.length}</b></h5>
                        </CNavItem>
                    </CCol>
                </CRow>
                {(isDiscounted && discountPoint!=0) ? (
                <CRow>
                    <CCol className={"max-width"} xs={12} style={{ marginTop: isMobile ? 16 : 0 }}>
                        <CNavItem>
                            {/* Row for Discount Badge & Original Price */}
                            <div className="d-flex align-items-center justify-content-end gap-2 w-100 pe-2">
                                {/* Discount Badge */}
                                <div className="discount-badge-white">
                                    {`-${discountPercentage}`}
                                </div>
                                {/* Original Price (Strikethrough) */}
                                <p className="text-muted text-decoration-line-through m-0 margin-left-12">
                                    {formattedPrice(totalPrice)}
                                </p>
                            </div>
                            {/* Final Price */}
                            <h5 style={{ marginBottom: '8px', marginLeft: isMobile ? 0 : '12px', marginRight: isMobile ? 0 : '12px' }}>
                                <b>{t('adminDashboard.Customers.grand_total_euro_customer_side')}: 
                                    <span className="text-danger"> {formattedPrice(totalPrice * (1.0 - discountPoint))}</span>
                                </b>
                            </h5>
                        </CNavItem>
                    </CCol>
                </CRow>
            ) : (
                <CRow>
                    <CCol className={"max-width"} xs={12} style={{ marginTop: isMobile ? 16 : 0 }}>
                        <CNavItem>
                            <h5 style={{ marginBottom: '8px', marginLeft: isMobile ? 0 : '12px', marginRight: isMobile ? 0 : '12px' }}>
                                <b>{t('adminDashboard.Customers.grand_total_euro_customer_side')}: {formattedPrice(totalPrice - discountAmount)}</b>
                            </h5>
                        </CNavItem>
                    </CCol>
                </CRow>
            )}
            </CNav>
        </div>
    )
};
export default PackageDetailNonStickyHeader;