import React, {ReactNode} from 'react'
import { NavLink  } from 'react-router-dom'
import PropTypes from 'prop-types'
import {Badge } from '../../../types';
import { CBadge } from '@coreui/react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ADMIN, BACK_OFFICE_GROUP_ID, CLAIM_SUPPORT, SALES_ASSISTANT_GROUP_ID, SALES_GROUP_ID, SUPPLIER_GROUP_ID, WAREHOUSE_GROUP_ID } from '../../../constants';
import { setSidebarShow } from '../../../redux/slices/AdminSlice';

export const AppSidebarNav = (props: any) => { 
  const { 
    salesAnalyticsDashboardItems,  salesPersonDashboardItems, backOfficeDashboardItems, 
    adminDashboardItems, supplierDashboardItems, salesAssistantDashboardItems, warehouseManagerDashboardItems
  } = props;
  
  const authUser = useSelector((state: RootState) => state.authSlice.user);
  const IS_ADMIN_OR_SUPERADMIN = authUser && (authUser?.is_superuser || authUser?.groups?.includes(ADMIN));
  const { t, i18n } = useTranslation();
  const navLink = (name: string, icon: ReactNode, badge?: Badge ) => {
    return (
      <>
        {icon && icon}
        {name && t('adminDashboard.' + name + '.sidebar_text')}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item: any, index: number) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (     
      <Component
        {...(rest.to && !rest.items &&{
            component: NavLink,
          })}
        key={index}
        style={{
          color: 'white',
        }}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item: any, index: number) => {
    const dispatch = useDispatch();
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        style={{
          color: 'white',
        }}
        onClick={() => {dispatch(setSidebarShow(true))}}
        {...rest}
      >
        {item.items?.map((item: any, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {
        IS_ADMIN_OR_SUPERADMIN ?// Admins can access all the items of Main dashboard.
          adminDashboardItems &&
          adminDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))

          : authUser && authUser?.groups?.includes(CLAIM_SUPPORT) ?
            backOfficeDashboardItems &&
            backOfficeDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))

          : authUser && authUser?.groups?.includes(BACK_OFFICE_GROUP_ID) ?
            backOfficeDashboardItems &&
            backOfficeDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))

        : authUser && authUser?.groups?.includes(SALES_GROUP_ID) ?
            salesPersonDashboardItems &&
            salesPersonDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))
          
        : authUser && authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID) ?
            salesAssistantDashboardItems &&
            salesAssistantDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))

        : authUser && authUser?.groups?.includes(SUPPLIER_GROUP_ID) ?
            supplierDashboardItems &&
            supplierDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))

        : authUser && authUser?.groups?.includes(WAREHOUSE_GROUP_ID) ?
            warehouseManagerDashboardItems &&
            warehouseManagerDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))

          : null
      }
      {
        IS_ADMIN_OR_SUPERADMIN ? // Only Admins can access the sales analytics dashboard.
          salesAnalyticsDashboardItems &&
          salesAnalyticsDashboardItems.map((item: any, index: number) => (item.items ? navGroup(item, index) : navItem(item, index)))
        : null
      }
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = { 
  salesAnalyticsDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  backOfficeDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  claimsSupportDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  salesPersonDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  salesAssistantDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  supplierDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  warehouseManagerDashboardItems: PropTypes.arrayOf(PropTypes.any).isRequired,
}
