import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormInput, CFormLabel, CInputGroup, CNav, CNavItem, CNavLink, CRow, CSpinner, CToaster } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { LoggedinUser, Supplier } from '../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ELV_BLUE } from '../../../constants';
import { Utils } from '../../components/utils';
import { updateAuthUser } from '../../../services/AuthService';
import { uploadProductManually } from '../../../services/ProductService';
import { uploadPalletManually } from '../../../services/PalletService';
import { uploadCatalog } from '../../../services/CatalogService';
import AddManualPrices from '../../components/supply-management/AddManualPrices';
import ManualProductPrices from '../../components/supply-management/ManualProductPrices';
import { GenericCreatedToast, GenericDeletedToast, GenericEditedToast, GenericErrorToast, GenericSuccessToast} from '../../components/create-package-components/ToastsComponent';
import CIcon from '@coreui/icons-react';
import { cilInfo, cilCloudDownload } from '@coreui/icons';

const WebsiteSettingsScreen = () => {
    const { t, i18n } = useTranslation();
    const {changePassword, getManualPrices} = Utils();
    const [loader, setLoader] = useState<boolean>(false);
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const suppliers: Supplier[] = useSelector((state: RootState) => state.productSlice.suppliers);
    const [language, changeLanguage] = useState<string | undefined>(authUser?.preferred_language);
    const [toast, addToast] = useState<any>(null);
    const [selectedPalletSupply, setSelectedPalletSupply] = useState<'pallet_otto' | 'pallet_unito' | ''>('');
    const [selectedSupplier, setSelectedSupplier] = useState<string>('');
    const [productFile, setProductFile] = useState<any>();
    const [palletFile, setPalletFile] = useState<any>();
    const [catalogFile, setCatalogFile] = useState<any>();
    const [selectedTab, setSelectedTab] = useState<0 | 1 | 2 | 3 | 4>(0);
    const [newPassword, setNewPassword] = useState<string>('');
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<Array<{ title: string; items: string[] }>>([]);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);
    
    const updateLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        const formData = {
            preferred_language: lang,
        }
        if(authUser?.id) {
            updateAuthUser(formData).then((response) => {
                changeLanguage(lang);
            }).catch((e) => {
            })
        }
    }

    // Handel errors
    const handleError = (error : any) => {
        const errors = error.response.data.error;
        const newErrors = [];

        if (errors.missing_article_ids) {
            newErrors.push({
                title: "article_not_found",
                items: errors.missing_article_ids,
            });
        }

        if (errors.missing_supply_ids) {
            newErrors.push({
                title: "supply_not_found",
                items: errors.missing_supply_ids,
            });
        }

        if (errors.duplicate_product_ids) {
            newErrors.push({
                title: "duplicate_product_ids",
                items: errors.duplicate_product_ids,
            });
        }

        setErrorMessage(newErrors);
    };
    
    const handleDownload = () => {
        const link = document.createElement('a');
        if(selectedTab == 3) {
            link.href = require('../../../assets/templates/upload-prices-template.xlsx');
            link.setAttribute('download', 'upload-prices-template.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else if(selectedTab == 2) {
            link.href = require('../../../assets/templates/upload-catalog-template.xlsx');
            link.setAttribute('download', 'upload-catalog-template.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else if(selectedTab == 1) {
            // pallet template here.
            link.href = require('../../../assets/templates/upload-pallet-template.xlsx');
            link.setAttribute('download', 'upload-pallet-template.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else if(selectedTab == 0) {
            // product template here.
            link.href = require('../../../assets/templates/upload-product-template.xlsx');
            link.setAttribute('download', 'upload-product-template.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleCatalogUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoader(true);
        const file = event.target.files?.[0];
        if (file) {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const formData = {
                    file: file,
                    supplier_name: selectedSupplier
                }
                try {
                    const response = await uploadCatalog(formData);
                    setLoader(false);
                    addToast(GenericSuccessToast(()=>{addToast(null)}, 'Successfully Uploaded.'))
                } catch (error: any) {
                    setLoader(false);
                    addToast(GenericErrorToast(()=>{addToast(null)}, error?.response?.data[0]?.error))
                }
            }
            else {
                setLoader(false);
                addToast(GenericErrorToast(()=>{addToast(null)}, 'Error. Only excel file is allowed.'))
            }
        }
        setCatalogFile('');
    };
    
    const handleProductUpload = async(e: any) => {
        setLoader(true);
        const file = e.target.files?.[0];
        if (file) {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const formData = {
                    file: file
                }
                try {
                    const response = await uploadProductManually(formData);
                    setLoader(false);
                    setErrorMessage([]);
                    addToast(GenericSuccessToast(()=>{addToast(null)}, 'File uploaded successfully.'))
                } catch (error : any) {
                    setLoader(false);
                    const checkError = error?.response?.data?.error;
                    if (!checkError?.missing_article_ids && !checkError?.missing_supply_ids && !checkError?.duplicate_product_ids) {
                        addToast(GenericErrorToast(()=>{addToast(null)}, error?.response?.data?.error))
                    } else {
                        handleError(error);
                    }
                }
            }
            else {
                setLoader(false);
                addToast(GenericErrorToast(()=>{addToast(null)}, 'Error. Only excel file is allowed.'))
            }
        }
        setProductFile('');
    }

    const handlePalletUpload = async(e: any) => {
        setLoader(true);
        const file = e.target.files?.[0];
        if (file) {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const formData = {
                    file: file,
                    supply_source: selectedPalletSupply,
                }
                try {
                    const response = await uploadPalletManually(formData);
                    setLoader(false);
                    addToast(GenericSuccessToast(()=>{addToast(null)}, 'Successfully uploaded.'));
                } catch (error: any) {
                    setLoader(false);
                    addToast(GenericErrorToast(()=>{addToast(null)}, error.response.data?.error));
                }
            }

            else {
                setLoader(false);
                addToast(GenericErrorToast(()=>{addToast(null)}, 'Error. Only excel file is allowed.'));
            }
        }
        setPalletFile('');
    }
    useEffect(()=>{
        getManualPrices();
    },[])
    return (
        <div className='website-settings-main-container'>
            <CToaster push={toast} placement="top-end" />
            <h1><b>{t('adminDashboard.WebsiteSettings.sidebar_text')}</b></h1>
            <CNav variant="tabs" style={{alignItems: "flex-end"}} className='margin-top-44'>
                <CNavItem className='pointer'>
                    <CNavLink  onClick={() => setSelectedTab(0)} active={selectedTab === 0 ? true : false}>{t('adminDashboard.WebsiteSettings.upload_product')}</CNavLink>
                </CNavItem>
                <CNavItem className='margin-left-12 pointer'>
                    <CNavLink onClick={() => setSelectedTab(1)} active={selectedTab === 1 ? true : false}>{t('adminDashboard.WebsiteSettings.upload_pallet_products')}</CNavLink>
                </CNavItem>
                <CNavItem className='margin-left-12 pointer'>
                    <CNavLink onClick={() => setSelectedTab(2)} active={selectedTab === 2 ? true : false}>{t('adminDashboard.WebsiteSettings.upload_catalog')}</CNavLink>
                </CNavItem>
                <CNavItem className='margin-left-12 pointer'>
                    <CNavLink onClick={() => setSelectedTab(3)} active={selectedTab === 3 ? true : false}>{t('adminDashboard.SupplyManagement.manually_uploaded_prices')}</CNavLink>
                </CNavItem>
                <CNavItem className='margin-left-12 pointer'>
                    <CNavLink onClick={() => setSelectedTab(4)} active={selectedTab === 4 ? true : false}>{t('adminDashboard.WebsiteSettings.password_language')}</CNavLink>
                </CNavItem>
            </CNav>
            {
                loader ?
                 <CRow>
                    <CCol xs = {6} className='margin-top-16 text-center'>
                        <CSpinner />
                    </CCol>
                 </CRow>
                 : null               
            }
            <CRow>
                {
                    selectedTab == 0 ?
                        <CCol xs={4} className='margin-top-36'>
                            {/* <CTooltip
                                content={`${t('adminDashboard.WebsiteSettings.required_columns')}: 'lager_number', 'article_number', 'final_grade', 'supply',
                                'is_setarticle', 'question_answer_list', 'scaling_list', 'image_number_list',
                                'usage', 'buying_price', 'buying_online_price', 'selling_price','is_deleted'`
                               } placement="top"
                            > */}
                                <CFormLabel><b>{t('adminDashboard.WebsiteSettings.upload_product')}<CIcon className='margin-left-12' style={{color: ELV_BLUE}} icon={cilInfo} /></b></CFormLabel>
                            {/* </CTooltip> */}
                            <CFormInput
                                type={"file"}
                                placeholder='only xlsx file format.'
                                value={productFile}
                                onChange={handleProductUpload}
                                className='margin-bottom-44'
                            />
                            <CButton className='elv-btn margin-top-16' onClick={handleDownload}><CIcon icon={cilCloudDownload} size='sm'/> {t('adminDashboard.CommonWords.download_template')}</CButton>
                            {
                                errorMessage && errorMessage.length > 0 && (
                                    <div className='margin-top-24' 
                                        style={{
                                            border: '1px solid #23b2d9',
                                            padding: '10px',
                                            maxWidth: '90%',
                                            width: '400px',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                        }}>
                                        {errorMessage.map((error, index) => (
                                            <div key={index}>
                                                <h5>{t(`adminDashboard.CommonWords.${error.title}`)}</h5>
                                                <ul style={{ padding: '0 0 0 2%' }}>
                                                    {error.items.map((item, idx) => (
                                                        <div key={idx}  style={{ marginBottom: '8px' }} >{item}</div>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                )
                            }
                        </CCol>
                    : selectedTab == 1 ?
                        <CCol xs={4} className='margin-top-36'>
                            <div>
                                <CFormLabel><b>{t('adminDashboard.Packages.select_pallet_supply')}</b></CFormLabel>
                                <CDropdown className='margin-left-16'>
                                    <CDropdownToggle className='elv-btn' color="info">{selectedPalletSupply}</CDropdownToggle>
                                    <CDropdownMenu>
                                        <CDropdownItem href="#" onClick={() => setSelectedPalletSupply('pallet_otto')}>Pallet Otto</CDropdownItem>
                                        <CDropdownItem href="#" onClick={() => setSelectedPalletSupply('pallet_unito')}>Pallet Unito</CDropdownItem>
                                    </CDropdownMenu>
                                </CDropdown>
                            </div>
                            <div className='margin-top-36'>
                                {/* <CTooltip
                                    content={`${t('adminDashboard.WebsiteSettings.required_columns')}: 'Marke', 'Artikel-Nr.', 'Bezeichnung', 'Modell', 'Menge', 'Einkauf_Netto', 
                                    'Einzel_VKP', 'Einzel_Verkauf', 'Pal-Nr.', 'Lager Nummer', 'LS-Nummer', 'Lieferdatum'`}
                                    placement="top"
                                > */}
                                    <CFormLabel><b>{t('adminDashboard.WebsiteSettings.upload_pallet_products')} <CIcon className='margin-left-12' style={{color: ELV_BLUE}} icon={cilInfo} /></b></CFormLabel>
                                {/* </CTooltip> */}
                                <CFormInput
                                    type={"file"}
                                    placeholder='only xlsx file format.'
                                    value={palletFile}
                                    onChange={handlePalletUpload}
                                    className='margin-bottom-44'
                                    disabled={selectedPalletSupply.length == 0}
                                />
                                <CButton className='elv-btn margin-top-16' onClick={handleDownload}><CIcon icon={cilCloudDownload} size='sm'/> {t('adminDashboard.CommonWords.download_template')}</CButton>
                            </div>
                    </CCol>
                    : selectedTab == 2 ?
                        <CCol xs={4} className='margin-top-36'>
                            <div>
                                <CFormLabel><b>{t('adminDashboard.Packages.please_select_supplier')}</b></CFormLabel>
                                <CDropdown className='margin-left-16'>
                                    <CDropdownToggle className='elv-btn' color="info">{selectedSupplier}</CDropdownToggle>
                                    <CDropdownMenu>
                                        {
                                            suppliers.map((supplier: Supplier) => {
                                                return(
                                                    <CDropdownItem href="#" onClick={() => setSelectedSupplier(supplier.name)}>{supplier.name}</CDropdownItem>
                                                )
                                            })
                                        }
                                    </CDropdownMenu>
                                </CDropdown>
                            </div>

                            <div className='margin-top-36'>
                                {/* <CTooltip
                                    content={`${t('adminDashboard.WebsiteSettings.required_columns')}: 'brand','article_number','sub_category','model','weight_kg','retail_price', 
                                    'online_price','buying_price','taric_code','height_primary','height_secondary','width_primary','width_secondary','depth_primary','depth_secondary'`}
                                    placement="top"
                                > */}
                                    <CFormLabel><b>{t('adminDashboard.WebsiteSettings.upload_catalog')} <CIcon className='margin-left-12' style={{color: ELV_BLUE}} icon={cilInfo} /></b></CFormLabel>
                                {/* </CTooltip> */}
                                <CFormInput
                                    type={"file"}
                                    placeholder='only xlsx file format.'
                                    value={catalogFile}
                                    onChange={handleCatalogUpload}
                                    disabled={selectedSupplier.length == 0}
                                />
                                <CButton className='elv-btn margin-top-16' onClick={handleDownload}><CIcon icon={cilCloudDownload} size='sm'/> {t('adminDashboard.CommonWords.download_template')}</CButton>
                            </div>
                        </CCol>
                    : selectedTab == 3 ?
                        <CCol xs={12}>
                            <CRow>
                                <CCol xs={4}>
                                    <div className='margin-top-24'>
                                        <AddManualPrices handleDownload = {handleDownload} showCreatedToast={() => addToast(GenericCreatedToast(()=>{addToast(null)}))}/>
                                    </div>
                                </CCol>
                                <CCol xs={12}>
                                    <ManualProductPrices showEditedToast={() => addToast(GenericEditedToast(()=>{addToast(null)}))} showDeletedToast={() => addToast(GenericDeletedToast(()=>{addToast(null)}))}/>
                                </CCol>
                            </CRow>
                            
                        </CCol>
                    :
                    <>
                        <CCol xs={12} className='margin-top-36'>
                            <CFormLabel><b>{t('adminDashboard.WebsiteSettings.select_language')}</b></CFormLabel>
                            <CDropdown className='margin-left-16'>
                                <CDropdownToggle className='elv-btn' color="info">{language}</CDropdownToggle>
                                <CDropdownMenu>
                                    <CDropdownItem href="#" onClick={() => updateLanguage('en')}>English</CDropdownItem>
                                    <CDropdownItem href="#" onClick={() => updateLanguage('de')}>Deutsch</CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>
                        </CCol>
                        <CCol xs={4} className='margin-top-24'>
                            <CFormLabel><b>{t('adminDashboard.WebsiteSettings.change_password')}</b></CFormLabel>
                            <CInputGroup className='form-input-group'>
                                <CFormInput
                                    type={showNewPassword ? "text" : "password"}
                                    placeholder={`${t('adminDashboard.WebsiteSettings.new_password')}`}
                                    value={newPassword}
                                    onChange={(e: any) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    style={{borderWidth: 0}}
                                />
                                {
                                    showNewPassword ?
                                        <img className='margin-left-12 show-hide-password-icon' src={require('../../../assets/images/eye-icon.png')} onClick={()=>setShowNewPassword(false)}/>
                                    :
                                        <img className='margin-left-12 show-hide-password-icon' src={require('../../../assets/images/eye-slash-icon.png')} onClick={()=>setShowNewPassword(true)}/>
                                }
                            </CInputGroup>
                            <CInputGroup className='form-input-group margin-top-16'>
                                <CFormInput
                                    type={showConfirmNewPassword ? "text" : "password"}
                                    placeholder={`${t('adminDashboard.WebsiteSettings.confirm_new_password')}`}
                                    value={confirmNewPassword}
                                    onChange={(e: any) => {
                                        setConfirmNewPassword(e.target.value);
                                    }}
                                    style={{borderWidth: 0}}
                                />
                                {
                                    showConfirmNewPassword ?
                                        <img className='margin-left-12 show-hide-password-icon' src={require('../../../assets/images/eye-icon.png')} onClick={()=>setShowConfirmNewPassword(false)}/>
                                    :
                                        <img className='margin-left-12 show-hide-password-icon' src={require('../../../assets/images/eye-slash-icon.png')} onClick={()=>setShowConfirmNewPassword(true)}/>
                                }
                            </CInputGroup>
                            <CButton className='elv-btn margin-top-36' size='sm' onClick={()=> changePassword(newPassword, confirmNewPassword)}>{`${t('adminDashboard.CommonWords.confirm')}`}</CButton>
                        </CCol>
                    </>
                }
            </CRow>
        </div>
    );
};
export default WebsiteSettingsScreen;
