import { Suggestion, Supply } from "../../../types";

export const generateSuggestionList = (
    supplies: Supply[],
    allAvaliableBrandProductModel : string[],
    productArticles: string[],
): Suggestion[] => {
    const suggestions: Suggestion[] = [];

    allAvaliableBrandProductModel
    .filter(model => model !== null) // Remove null & empty/whitespace-only strings
    .forEach((model) => {
        suggestions.push({
            suggestionText: model
        });
    });

    supplies.forEach((supply) => {
        suggestions.push({
            suggestionText: `${supply.id}` // "Supplier + Supply"
        });
    });

    productArticles
    .filter(article => article !== null && article.trim() !== "") // Remove null & empty/whitespace-only strings
    .forEach((article) => {
        suggestions.push({
            suggestionText: article
        });
    });

    return suggestions;
};