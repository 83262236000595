import { CButton, CCol, CFormLabel, CRow } from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CSmartTable } from "@coreui/react-pro";
import { setSupplyPricingList } from "../../redux/slices/AdminSlice";
import { getSupplyPricingAnalysis } from "../../services/AnalyticsService";
import { AnalyticsUtils } from "../components/utils/SalesAnalyticsUtils";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SupplyPricingList } from "../../types";
import { StockAnalysisData } from "../components/GenerateExcelFile";
import { formattedPrice } from "../../constants";

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize-width', handleResize);
    // Clean up
    return () => window.removeEventListener('resize-width', handleResize);
  }, []);
  return width;
}
function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize-height', handleResize);
    // Clean up
    return () => window.removeEventListener('resize-height', handleResize);
  }, []);

  return height;
}

const SupplyAnalysis = () => {
  const [windowWidth, setWindowWidth] = useState(useWindowWidth());    // Get the current window width
  const [windowHeight, setWindowHeight] = useState(useWindowHeight());
  const dispatch = useDispatch();
  const { SupplyAnalysisColumns } = AnalyticsUtils();
  const supplyPricingList: SupplyPricingList[] = useSelector(
    (state: RootState) => state.adminSlice.supplyPricingList
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date("2024-11-01"));

  useEffect(() => {
    dispatch(setSupplyPricingList([]));
  }, []);

  const fetchData = () => {
    setLoading(true);
    const form_data = {
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    };
    getSupplyPricingAnalysis(form_data)
      .then((response) => {
        dispatch(setSupplyPricingList(response.data.supply_type_analysis));
        setLoading(false);
      })
      .catch((e) => {
        console.log("Analytics Error:", e);
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      };
      window.addEventListener('resize', handleResize);
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      }});

  return (
    <div className="website-settings-main-container" style={{ padding: "30px" }}>
      <h1><b>{t('adminDashboard.SupplyAnalysis.sidebar_text')}</b></h1>
        <CRow>
          <CCol xs={12} md={3}>
            <CFormLabel><b>{t('adminDashboard.CommonWords.date')}</b></CFormLabel>
            <div style={{
                position: 'relative',
                zIndex: 1000
              }}>
              <DatePicker
                selected={selectedDate}
                onChange={(date: Date) => setSelectedDate(date)}
                dateFormat="yyyy-MM"
                className="datepicker"
                showMonthYearPicker
              />
            </div>
          </CCol>
          <CCol xs={12} md={3}>
            <CButton
              className='elv-btn margin-top-24 margin-bottom-44'
              variant="outline"
              shape="square"
              disabled={(!selectedDate) || loading}
              onClick={()=>{
              fetchData();
              }}
            >
              {t('adminDashboard.Packages.fetch_products_button_text')}
            </CButton>
          </CCol>
        </CRow>
      {/* Table Container (Scrollable) */}
      <div
        style={{
          width: "100%",
          overflowX: "auto",
          display: "flex",
          justifyContent: "center",
          marginTop: "1px",
        }}
      >
        <div
          style={{
            width: windowWidth - 70,
            height: windowHeight - 210,
            overflow: "auto",
            position: "relative",
          }}
        >
          <CSmartTable
            columns={SupplyAnalysisColumns}
            columnFilter
            columnSorter
            items={supplyPricingList}
            itemsPerPage={supplyPricingList?.length}
            loading={loading}
            pagination={true}
            tableHeadProps={{
              color: "info",
              style: {
                position: "sticky",
                top: -7,
                zIndex: 50,
                backgroundColor: "white",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
              },
            }}
            tableProps={{
              hover: true,
              responsive: false,
              style: {
                marginTop: 0,
                tableLayout: "fixed",
                width: "100%",
                minWidth: "1280px",
              },
              striped: true,
            }}
            tableBodyProps={{
              className: "align-middle",
            }}
            scopedColumns={{
              jtl_ek_netto: (item: any) => (
                <td className="text-center">{formattedPrice(item.jtl_ek_netto)}</td>
              ),
              lagerwert: (item: any) => (
                <td className="text-center">{formattedPrice(item.lagerwert)}</td>
              ),
              portal_ek_netto: (item: any) => (
                <td className="text-center">{formattedPrice(item.portal_ek_netto)}</td>
              ),
              status: (item: any) => <td className="text-center">{item.status}</td>,
              supplier: (item: any) => <td className="text-center">{item.supplier}</td>,
              supply_type: (item: any) => <td className="text-center">{item.supply_type}</td>,
              vk_netto: (item: any) => (
                <td className="text-center">{formattedPrice(item.vk_netto)}</td>
              ),
              vk_soll: (item: any) => (
                <td className="text-center">{formattedPrice(item.vk_soll)}</td>
              ),
            }}
          />
        </div>
      </div>

      {/* Excel Download Button */}
      {/* <div style={{ display: "flex", justifyContent: "left", marginTop: "20px" }}>
        <CButton className="elv-btn" size="sm" onClick={() => StockAnalysisData(supplyPricingList)}>
          {t("adminDashboard.supply_analysis_analytics.download_excel")}
        </CButton>
      </div> */}
    </div>
  );
};

export default SupplyAnalysis;
