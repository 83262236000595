import { CButton, CSpinner } from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CSmartTable } from "@coreui/react-pro";
import { setStockAnalysisList } from "../../redux/slices/AdminSlice";
import { getStockAnalysis } from "../../services/AnalyticsService";
import { AnalyticsUtils } from "../components/utils/SalesAnalyticsUtils";
import { t } from 'i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { StockAnalysisList } from "../../types";
// import { StockAnalysisDataForExcel } from "../components/GenerateExcelFile";
import { formattedPrice } from "../../constants";


function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize-width', handleResize);
      // Clean up
      return () => window.removeEventListener('resize-width', handleResize);
    }, []);
    return width;
  }
  function useWindowHeight() {
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
      const handleResize = () => setHeight(window.innerHeight);
      window.addEventListener('resize-height', handleResize);
      // Clean up
      return () => window.removeEventListener('resize-height', handleResize);
    }, []);
    return height;
  }

const StockAnalysis = () => {
    const [windowWidth, setWindowWidth] = useState(useWindowWidth());    // Get the current window width
    const [windowHeight, setWindowHeight] = useState(useWindowHeight());
    const dispatch = useDispatch();
    const {StockAnalysisColumns} = AnalyticsUtils();
    const stockAnalysisList: StockAnalysisList[] = useSelector((state: RootState) => state.adminSlice.stockAnalysisList);
    // const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchData = () => {
      setLoading(true);
      getStockAnalysis()
          .then((response) => {
              dispatch(setStockAnalysisList(response?.data?.stock_data));
              // const rawData = response.data["stock_data"];
  
              // // Map API response keys to valid table keys
              // const transformedData = rawData.map((item: any) => ({
              //     portal_buying_price: item["Buying_Price"],
              //     portal_online_price: item["Online_Price"],
              //     portal_selling_price: item["Selling_Price"],
              //     article_number: item["article_number"],  // No change needed
              //     datetime_upload: item["datetime_upload"],  // No change needed
              //     final_grade: item["final_grade"],  // No change needed
              //     lager_number: item["lager_number"],  // No change needed
              //     brand: item["lager_number"],  // No change needed
              //     usage: item["usage"],  // No change needed
              //     model: item["model"],  // No change needed
              //     grade_points: item["grade_points"],  // No change needed
              //     price_percent: item["price_percent"] ?? 0,
              //     product_group: item["product_group"],  // No change needed
              //     supply_id: item["supply_id"],  // No change needed
              //     supply_type: item["supply_type"],  // No change needed
              // }));
              // dispatch(setStockAnalysisList(transformedData));
              setLoading(false);
          })
          .catch((e) => {
              console.error('Analytics Error:', e);
              setLoading(false);
          });
    };

    // ✅ Call fetchData when the component mounts
    useEffect(() => {
        fetchData();
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
          };
          window.addEventListener('resize', handleResize);
          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener('resize', handleResize);
          };
    }, []);
   

    return (
      <div className='website-settings-main-container'>
        <h1><b>{t('adminDashboard.StockAnalysis.sidebar_text')}</b></h1>
        <div className="smart-table-div-one">
          <div
            style={{
              width: windowWidth - 70,
              height: windowHeight - 210,
              overflow: "auto", // Enable scrolling inside table
              position: "relative",
            }}
          >
            <CSmartTable
              columns={StockAnalysisColumns}
              columnFilter
              columnSorter
              items={stockAnalysisList} // Show all records (No pagination)
              itemsPerPage={(stockAnalysisList?.length) / 5}
              pagination={true} // Disable pagination
              loading={loading}
              tableHeadProps={{
                color: "info",
                style: {
                  position: "sticky",
                  top: -7,
                  
                  // right: 10,
                  zIndex: 50,
                  backgroundColor: "white",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)", // Optional shadow
                },
              }}
              tableProps={{
                  hover: true,
                  responsive: false, // Set to false to allow scrolling inside
                  style: { 
                      marginTop: 0, 
                      tableLayout: "auto", 
                      width: "100%",
                      minWidth: "1280px", // Prevent table from shrinking too much
                  },
                  striped: true,
              }}
              tableBodyProps={{
                  className: "align-middle",
              }}
              scopedColumns={{
                Buying_Price: (item: any) => (
                <td className="padding-left-24">{formattedPrice(item.Buying_Price)}</td>
                ),
                Online_Price: (item: any) => (
                <td className="padding-left-24">{formattedPrice(item.Online_Price)}</td>
                ),
                Selling_Price: (item: any) => (
                <td className="padding-left-24">{formattedPrice(item.Selling_Price)}</td>
                ),
                article_number: (item: any) => (
                <td className="padding-left-24">{item.article_number}</td>
                ),
                upload_datetime: (item: any) => (
                <td className="padding-left-24">{new Date(item.upload_datetime).toLocaleString()}</td>
                ),
                final_grade: (item: any) => (
                <td className="padding-left-24 text-center">{item.final_grade}</td>
                ),
                lager_number: (item: any) => (
                <td className="padding-left-24">{item.lager_number}</td>
                ),
                price_percent: (item: any) => (
                <td className="padding-left-24">{(item.price_percent * 100).toFixed(2)}%</td>
                ),
                product_group: (item: any) => (
                <td className="padding-left-24">{item.product_group}</td>
                ),
                supply_id: (item: any) => (
                <td className="padding-left-24">{item.supply_id}</td>
                ),
                supply_type: (item: any) => (
                <td className="padding-left-24">{item.supply_type}</td>
                ),
                brand: (item: any) => (
                <td className="padding-left-24">{item.brand}</td>
                ),
                usage: (item: any) => (
                <td className="padding-left-24">{item.usage}</td>
                ),
                model: (item: any) => (
                <td className="padding-left-24">{item.model}</td>
                ),
                grade_points: (item: any) => (
                <td className="padding-left-24">{item.grade_points}</td>
                ),
              }}
            />
          </div>
        </div>
             
        {/* <CButton
          className="elv=btn"
          style={{ marginTop: "20px"}}
          size="sm"
          onClick={()=>{
            StockAnalysisDataForExcel(stockAnalysisList);
          }}
        >
          {t('adminDashboard.supply_analysis_analytics.download_excel')}
        </CButton> */}
      </div>
  )

}
export default StockAnalysis;