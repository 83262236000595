import axios from "axios";
import { BACKEND_API_URL } from "../../constants";

const api = axios.create({
    baseURL: BACKEND_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Download PDF of single product
export const getProductPDFURL = async (productId: number | string) => {
    return await api.get(
        `api/products/${productId}/pdf/`,
    )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            console.log('error from PDF endpoint ', error);
            throw error;
        });
};

// Download PDF of a order
export const getOrderPDFURL = async (orderId: number | string) => {
    return await api.get(
        `api/orders/${orderId}/pdf/`,
    )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
};

// Download PDF of whole supply
export const getSupplyPDFURL = async (supplyId: number | string) => {
    return await api.get(
        `api/supply/${supplyId}/pdf/`,
    )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
};

// Download PDF of a package
export const getPackagePDFURL = async (packageId: number | string, force?: boolean | false) => {
    return await api.get(
        `api/packages/${packageId}/pdf/?force=${force}`,
    )
        .then((response) => {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
};