import { useDispatch } from "react-redux";
import { setSuppliesList, setCompaniesList, setSalesPersonList, setYearsData, setScanningTime } from "../../../redux/slices/AdminSlice";
import { getAllSupplies, getAllCompanies, getAllSalesPerson, getYearlyProfitData, getUserScanningTime } from "../../../services/DashboardService";
import { baseColumnStyle, columnStyle } from "../../../constants";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const AnalyticsUtils = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const QualityAnalysisColumns = [
      {
        key: 'supply',
        label: `${t('adminDashboard.SupplyManagement.supply')}`,
        _style: baseColumnStyle,
      },
      {
        key: 'supply_date',
        label: `${t('adminDashboard.CommonWords.date')}`,
        _style: columnStyle,
      },
      {
        key: 'total_A_grade',
        label: `A Grade`,
        _style: columnStyle,
      },
      {
        key: 'total_B_grade',
        label: `B Grade`,
        _style: columnStyle,
      },
      {
        key: 'total_C_grade',
        label: `C Grade`,
        _style: columnStyle,
      },
      {
        key: 'total_products',
        label: `${t('adminDashboard.Products.products')}`,
        _style: columnStyle,
      }
    ];
    const SupplyAnalysisColumns = [
      {
        key: 'supplier',
        label: t('adminDashboard.supply_analysis_analytics.supplier'),
        _style: columnStyle,
      },
      {
        key: 'supply_type',
        label: t('adminDashboard.supply_analysis_analytics.supply_type'),
        _style: columnStyle,
      },
      {
        key: 'jtl_ek_netto',
        label: t('adminDashboard.supply_analysis_analytics.jtl_ek_netto'),
        _style: baseColumnStyle,
        filter: false,
      },
      {
        key: 'portal_ek_netto',
        label: t('adminDashboard.supply_analysis_analytics.portal_ek_netto'),
        _style: columnStyle,
        filter: false,
      },
      {
        key: 'lagerwert',
        label: t('adminDashboard.supply_analysis_analytics.lagerwert'),
        _style: columnStyle,
        filter: false,
      },
      
      {
        key: 'status',
        label: t('adminDashboard.supply_analysis_analytics.status'),
        _style: columnStyle,
      },
      
      {
        key: 'vk_netto',
        label: 'VK Netto',
        _style: columnStyle,
        filter: false,
      },
      {
        key: 'vk_soll',
        label: 'VK Soll',
        _style: columnStyle,
        filter: false,
      }
    ]
    const StockAnalysisColumns = [
      {
        key: 'lager_number',
        label: t('adminDashboard.stock_analysis_analytics.lager_number'), // Mapped from "lager_number"
        _style: baseColumnStyle,
      },
      {
        key: 'brand',
        label: t('adminDashboard.Products.brand'), // Mapped from "lager_number"
        _style: columnStyle,
      },
      {
        key: 'model',
        label: t('adminDashboard.CommonWords.model'), // Mapped from "lager_number"
        _style: columnStyle,
      },
      {
        key: 'article_number',
        label: t('adminDashboard.stock_analysis_analytics.article_number'), // Mapped from "article_number"
        _style: columnStyle,
      },
      {
        key: 'supply_id',
        label: t('adminDashboard.SupplyManagement.supply'), // Mapped from "supply_id"
        _style: columnStyle,
      },
      {
        key: 'supply_type',
        label: t('adminDashboard.SupplyManagement.supply_type'), // Mapped from "supply_type"
        _style: columnStyle,
      },
      {
        key: 'product_group',
        label: t('adminDashboard.stock_analysis_analytics.product_group'), // Mapped from "product_group"
        _style: columnStyle,
      },
      {
        key: 'usage',
        label: t('adminDashboard.Products.usage'), // Mapped from "product_group"
        _style: columnStyle,
      },
      {
        key: 'grade_points',
        label: t('adminDashboard.Products.grade_point'), // Mapped from "final_grade"
        _style: columnStyle,
      },
      {
        key: 'final_grade',
        label: t('adminDashboard.Products.final_grade'), // Mapped from "final_grade"
        _style: columnStyle,
      },
      {
        key: 'price_percent',
        label: t('adminDashboard.Products.price_percent'), // Mapped from "price_percent"
        _style: columnStyle,
      },
      {
        key: 'Buying_Price',
        label: t('adminDashboard.stock_analysis_analytics.buying_price'), // Mapped from "Portal Buying Price"
        _style: baseColumnStyle,
        filter: false,
      },
      {
        key: 'Online_Price',
        label: t('adminDashboard.stock_analysis_analytics.online_price'), // Mapped from "Portal Online Price"
        _style: columnStyle,
        filter: false,
      },
      {
        key: 'Selling_Price',
        label: t('adminDashboard.stock_analysis_analytics.selling_price'), // Mapped from "Portal Selling Price"
        _style: columnStyle,
        filter: false,
      },
      {
        key: 'datetime_upload',
        label: t('adminDashboard.stock_analysis_analytics.upload_datetime'), // Mapped from "datetime_upload"
        _style: columnStyle,
      },
    ];
    const CustomerScanningColumns= [
      {
        key: 'user_name',
        label: 'Name',
        _style: baseColumnStyle,
  
      },
      {
        key: 'month',
        label: 'Month-Year',
        _style: columnStyle,
        sorter: false,
      },
      {
        key: 'No_of_Products_Scanned',
        label: 'Products Scanned',
        _style: columnStyle,
      },
      {
        key: 'Average_Scanning_Time_sec',
        label: 'Avg scan time(sec)',
        _style: columnStyle,
      },
      {
        key: 'Average_Scanning_Time_min',
        label: 'Avg scan time(min)',
        _style: columnStyle,
      },
  ];
    
    const getAllAnalyticsData = () => {
        getUserScanningTime().then((response: any) => {
          dispatch(setScanningTime(JSON.parse(response.data.data)));
        }).catch((e) => {
          console.log('scanning time error: ', e);          
          dispatch(setScanningTime([]));
        });

        getAllSupplies().then((response) => {
            dispatch(setSuppliesList(response.data.all_supplies));
          }).catch((e) => {
            console.log('Get All Supplies Error: ', e);  
            dispatch(setSuppliesList([]));
          });
      
          getAllCompanies().then((response) => { // get the names of all companies.
            dispatch(setCompaniesList(response.data.data));
          }).catch((e) => {
            dispatch(setSalesPersonList([]));
          });
      
          getAllSalesPerson().then((response) => { // get the names of all sales men.
            dispatch(setSalesPersonList(response.data.data));
          }).catch((e) => {
            dispatch(setSalesPersonList([]));
          });
          
          getYearlyProfitData().then((response) => { // getting years from another route. 
            // Calling this route is only to get years.
            let new_years_data = [];
            for (let dataItem of response.data.data.data) {
              new_years_data.push(dataItem[0]);
            }
            dispatch(setYearsData(new_years_data));
        }).catch((e) => {
          dispatch(setYearsData([]));
        });
    }
    return {getAllAnalyticsData, CustomerScanningColumns, StockAnalysisColumns, SupplyAnalysisColumns, QualityAnalysisColumns}
}