import 'fixed-data-table-2/dist/fixed-data-table.css';
import { useEffect, useRef, useState } from 'react';
import RoundElvLogo from '../../assets/images/round-elvinci-logo.gif';
import { CRow, CCol, CButton, CFormLabel, CHeader, CNav, CNavItem, CNavLink, CToaster } from '@coreui/react';
import { t } from 'i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getAnalyticsData, getProductsKPIData, getPalletsKPIData } from '../../services/AnalyticsService';
import { useDispatch, useSelector } from 'react-redux';
import { setAllSoldProductList,  setAllSoldPalletsList} from '../../redux/slices/AdminSlice';
import '../AdminStyles.css';
// import KPIComponent from '../components/sales-analytics/AllSoldKPIComponent';
import AllSoldProducts from '../components/sales-analytics/AllSoldProducts';
import AllSoldPallets from '../components/sales-analytics/AllSoldPallets';
import { GenericErrorToast } from '../components/create-package-components/ToastsComponent';
import { AllSoldData } from '../components/GenerateExcelFile';

function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize-width', handleResize);
      // Clean up
      return () => window.removeEventListener('resize-width', handleResize);
    }, []);
    return width;
  }
  function useWindowHeight() {
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
      const handleResize = () => setHeight(window.innerHeight);
      window.addEventListener('resize-height', handleResize);
      // Clean up
      return () => window.removeEventListener('resize-height', handleResize);
    }, []);
  
    return height;
}
  
const AllSoldList = () => {
    const [windowWidth, setWindowWidth] = useState(useWindowWidth());    // Get the current window width
    const [windowHeight, setWindowHeight] = useState(useWindowHeight());
    const dispatch = useDispatch();
    const [productOrderedData, setProductOrderedData] = useState<any>();
    const [palletsOrderedData, setPalletsOrderedData] = useState<any>();
    const [productKpiData, setProductKpiData] = useState<any>();
    const [palletsKpiData, setPalletsKpiData] = useState<any>();
    // const tableRef = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [toast, addToast] = useState<any>(null);
    const [kpiLoader, setKpiLoader] = useState<boolean>(false);
    const [selectedTab, setSelecteTab] = useState<0 | 1 >(0);
    const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date(Date.UTC(2024, 4, 1)));
    const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date(Date.UTC(2024, 4, 31)));

    const fetchProductData = () => {
      const form_data = {
        "start_date": selectedDateFrom.toISOString().split('T')[0],
        "end_date": selectedDateTo.toISOString().split('T')[0],
        "is_product": true,
      }
      getAnalyticsData(form_data).then((response) => {
        
        let new_products = [...response?.data?.Ordered_data];
        setProductOrderedData(new_products);
        dispatch(setAllSoldProductList(new_products));
        setLoading(false);

      }).catch((e) => {
        console.log('products analytics error: ', e?.response?.data?.error);
        addToast(GenericErrorToast(() => { addToast(null) }, ("Error !! " + e?.response?.data?.error)));
        setProductOrderedData([]);
        dispatch(setAllSoldProductList([]));
        setLoading(false);
      });
    }
    const fetchPalletsData = () => {
      // console.log(selectedDateFrom);
      // console.log('Date from: ', selectedDateFrom.toISOString().split('T')[0]);
      // console.log('Date to: ', selectedDateTo.toISOString().split('T')[0]);

      const form_data = {
        "start_date": selectedDateFrom.toISOString().split('T')[0],
        "end_date": selectedDateTo.toISOString().split('T')[0],
        "is_product": false,
      }

      getAnalyticsData(form_data).then((response) => {
       // console.log('Pallet Response: ', response);
       // console.log(response?.data);
        let new_products = [ ...response.data.Ordered_data];
        setPalletsOrderedData(new_products);
        dispatch(setAllSoldPalletsList(new_products));
        setLoading(false);
      }).catch((e) => {
        console.log('pallets analytics error: ', e);
        setLoading(false);
      });
    }
   
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      };
      window.addEventListener('resize', handleResize);
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty dependency array means this effect will only run once after the initial render
  
    const getLastDayOfMonth = (date: Date): Date => {
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)); 
    };
   
    return (
      <div className='website-settings-main-container'>
        <CToaster push={toast} placement="top-end" />
        <h1><b>{t('adminDashboard.AllSoldList.sidebar_text')}</b></h1>
        <CRow>
          <CCol xs={12} md={2} className='padding-top-16'>
            <CFormLabel><b>{t('adminDashboard.Packages.date_from')} (Ordered from Portal)</b></CFormLabel>
            <div>
              <DatePicker
                selected={selectedDateFrom}
                onChange={(date: Date) => {
                  setSelectedDateFrom(date);
                  setSelectedDateTo(getLastDayOfMonth(date));
                }}                        
                dateFormat="dd-MM-yyyy"
                className="datepicker date-input"
              />
            </div>
          </CCol>
          <CCol xs={12} md={2} className='padding-top-16'>
            <CFormLabel><b>{t('adminDashboard.Packages.date_to')} (Ordered from Portal)</b></CFormLabel>
            <div>
              <DatePicker
                minDate={selectedDateFrom}
                selected={selectedDateTo}
                onChange={(date: Date) => setSelectedDateTo(date)}
                dateFormat="dd-MM-yyyy"
                className="datepicker date-input"
              />
            </div>
          </CCol>
          <CCol xs={12} md={2} className='padding-top-16'>
            <CButton
              className='elv-btn margin-top-24 margin-bottom-24'
              variant="outline"
              shape="square"
              disabled={(!selectedDateFrom && !selectedDateTo) || loading}
              onClick={()=>{
                setLoading(true);
                if (selectedTab == 0)
                {
                  setProductOrderedData([]);
                  dispatch(setAllSoldProductList([]));
                  setProductKpiData({})
                  fetchProductData();
                }
                else {
                  fetchPalletsData();
                }
              }}
              style={{ marginRight: "10px" }} 
          >{t('adminDashboard.Packages.fetch_products_button_text')}
          </CButton>
          {/* <CButton
              className='elv-btn margin-top-24 margin-bottom-24'
              variant="outline"
              shape="square"
              disabled={productOrderedData?.length == 0 || loading}
              onClick={()=>{
                AllSoldData(productOrderedData);
              }}
            >
              Download Excel
            </CButton> */}
          </CCol>
      </CRow>
      
      <div className='margin-bottom-24'>
        <CHeader className='margin-bottom-12'>
          <CNav variant="tabs" style={{alignItems: "flex-end"}}>
            <CNavItem className="pointer">
                <CNavLink onClick={() => setSelecteTab(0)} active={selectedTab === 0 ? true : false}>
                {t('adminDashboard.Products.products')}
                </CNavLink>
            </CNavItem>
            {/* <CNavItem className='margin-left-12 pointer'>
                <CNavLink onClick={() => setSelecteTab(1)} active={selectedTab === 1 ? true : false}>{t('adminDashboard.Pallets.pallets')} </CNavLink>
            </CNavItem> */}
          </CNav>
        </CHeader>
      </div>
       
      {
        loading ?
          <div className='text-center'><img src={RoundElvLogo} style={{width: '20%'}} /></div>
        :
         productOrderedData && selectedTab == 0 ?
          <AllSoldProducts
            productOrderedData={productOrderedData}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            setProductOrderedData={setProductOrderedData}
            productKpiData={productKpiData}
            kpiLoader={kpiLoader}
          />
      :
        palletsOrderedData && selectedTab == 1 ?
          <AllSoldPallets 
            palletsOrderedData={palletsOrderedData}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            setPalletsOrderedData={setPalletsOrderedData}
            palletsKpiData={palletsKpiData}
          />
      :
      <div className='text-center'>
        <h3>
        "No Data Yet."
        </h3>
      </div>
      }
      </div>
    );
  }

export default AllSoldList;
