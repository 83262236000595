import { CButton, CCol, CFormLabel, CRow } from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CSmartTable } from "@coreui/react-pro";
import { setProductQualityList } from "../../redux/slices/AdminSlice";
import { getProductQualityAnalysis } from "../../services/AnalyticsService";
import { AnalyticsUtils } from "../components/utils/SalesAnalyticsUtils";
import { t } from 'i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {DropDownItem, ProductQualityItem, SupplyType } from "../../types";
import { ProductQualityData } from "../components/GenerateExcelFile";
import Select from 'react-select';
import CIcon from "@coreui/icons-react";
import { cilArrowTop, cilArrowBottom, cilSwapVertical } from "@coreui/icons";

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize-width', handleResize);
    // Clean up
    return () => window.removeEventListener('resize-width', handleResize);
  }, []);
  return width;
}
function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize-height', handleResize);
    // Clean up
    return () => window.removeEventListener('resize-height', handleResize);
  }, []);

  return height;
}

const ProductQualityAnalysis = () => {
    const [windowWidth, setWindowWidth] = useState(useWindowWidth());    // Get the current window width
    const [windowHeight, setWindowHeight] = useState(useWindowHeight());
    const dispatch = useDispatch();
    const {QualityAnalysisColumns} = AnalyticsUtils();
    const ProductQualityList: ProductQualityItem[] = useSelector((state: RootState) => state.adminSlice.productQualityList);
    const supplyTypes: SupplyType[] = useSelector((state: RootState) => state.productSlice.supplyTypes);
    const [supplyTypeOptions, setSupplyOptions] = useState<DropDownItem[]>(supplyTypes.map((type: SupplyType) => ({ value: type?.name, label: `${type?.name}` })));
    const [selectedSupplyType, setSelectedSupplyType] = useState<DropDownItem>({value: '', label: ''});

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date("2024-11-01"));
    const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date("2024-12-01"));

    const fetchData = () => {
      setLoading(true);
      const form_data = {
        "start_date" : selectedDateFrom,
        "end_date": selectedDateTo,
        "supply_type": selectedSupplyType.value,      
      }
      getProductQualityAnalysis(form_data).then((response) => {
          dispatch(setProductQualityList(response.data.supply_type_analysis));
          setLoading(false);
      }).catch((e) => {
          console.log('analytics error: ', e);
          dispatch(setProductQualityList([]));
          setLoading(false);
      });
    }

    useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
          setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        dispatch(setProductQualityList([]));
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
      setSupplyOptions(supplyTypes.map((type: SupplyType) => ({ value: type?.name, label: `${type?.name}` })));
    },[supplyTypes]);

    return (
      <div className='website-settings-main-container'>
            <h1><b>{t('adminDashboard.ProductQuality.sidebar_text')}</b></h1>
            <CRow>
            <CCol xs={12} md={2} className="margin-top-24">
              <CFormLabel><b>{t('adminDashboard.Packages.date_from')}*</b></CFormLabel>
              <div style={{
                position: 'relative',
                zIndex: 1000
              }}>
                <DatePicker
                    selected={selectedDateFrom}
                    onChange={(date: Date) => setSelectedDateFrom(date)}
                    dateFormat="yyyy-MM-dd"
                    className="datepicker margin-left-12 date-input"
                />
              </div>
            </CCol>
            <CCol xs={12} md={2} className="margin-top-24">
              <CFormLabel className="margin-left-24"><b>{t('adminDashboard.Packages.date_to')}*</b></CFormLabel>
              <div style={{
                  position: 'relative',
                  zIndex: 1000
                }}
              >
                <DatePicker
                  minDate={selectedDateFrom}
                  selected={selectedDateTo}
                  onChange={(date: Date) => setSelectedDateTo(date)}
                  dateFormat="yyyy-MM-dd"
                  className="datepicker margin-left-12 date-input"
                />
                </div>
           </CCol>
           </CRow>
            <CRow>
              <CCol className="margin-top-24" xs={12} md={4}>
                <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_type')}*</b></CFormLabel>
                <div style={{
                  position: 'relative',
                  zIndex: 900
                }}>
                  <Select
                    value={selectedSupplyType}
                    noOptionsMessage={({ inputValue: string }) => 'Supply not found.' }
                    options={supplyTypeOptions}
                    onChange={(option: any) => {
                      setSelectedSupplyType(option);
                    }}
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs={12} md={3}>
                <CButton
                  className='elv-btn margin-top-24 margin-bottom-44'
                  variant="outline"
                  shape="square"
                  disabled={(!selectedDateFrom && !selectedDateTo) || loading || selectedSupplyType.value?.length == 0}
                  onClick={()=>{
                      fetchData();
                  }}
                >
                  {t('adminDashboard.Packages.fetch_products_button_text')}
                </CButton>
              </CCol>
            </CRow>
            <div style={{ width: "100%", overflowX: "auto", display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: windowWidth - 70,
                height: windowHeight - 210,
                position: "relative",
              }}
            >
          <CSmartTable
            columns={QualityAnalysisColumns}
            columnFilter
            columnSorter
            sortingIconAscending={
              <CIcon width={20} icon={cilArrowTop} style={{opacity: 1}}/>
            }
            sortingIconDescending={
              <CIcon width={20} icon={cilArrowBottom} style={{opacity: 1}} />
            }
            sortingIcon={
              <CIcon width={22} icon={cilSwapVertical} style={{opacity: 1}} />
            }
            items={ProductQualityList}
            itemsPerPage={ProductQualityList?.length}
            loading={loading}
            pagination={false}
            tableHeadProps={{
              color: 'info',
              style: {
                position: "sticky",
                top: -7,
                zIndex: 50,
                backgroundColor: "white",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
              },
            }}
            tableProps={{
              hover: true,
              responsive: false,
              style:{marginTop: 12},
              striped: true,
            }}
            tableBodyProps={{
              className: 'align-middle'
            }}
            scopedColumns={{
              supply: (item: ProductQualityItem) => {
                return (
                  <td className='text-center'>
                    {item.supply}
                  </td>
                )
              },
              supply_date: (item: ProductQualityItem) => {
                return (
                  <td className='text-center'>
                    {item.supply_date}
                  </td>
                )
              },
              total_A_grade: (item: ProductQualityItem) => {
                return (
                  <td className='text-center'>
                    {item.total_A_grade}
                  </td>
                )
              },
              total_B_grade: (item: ProductQualityItem) => {
                return (
                  <td className='text-center'>
                    {item.total_B_grade}
                  </td>
                )
              },
              total_C_grade: (item: ProductQualityItem) => {
                return (
                  <td className='text-center'>
                    {item.total_C_grade}
                  </td>
                )
              },
              total_products: (item: ProductQualityItem) => {
                return (
                  <td className='text-center'>
                    {item.total_products}
                  </td>
                )
              },
            }}
          />
        </div>
      </div>
      {/* <CButton
        className="elv=btn"
        size="sm"
        onClick={()=>{
            ProductQualityData(ProductQualityList);
        }}
      >
        Download Excel
      </CButton> */}
    </div>
  )
}
export default ProductQualityAnalysis;