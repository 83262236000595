import React, { ReactNode } from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilBasket,
  cilInbox,
  cilSpeedometer,
  cilCash,
  cilDollar,
  cilUser,
  cilUserPlus,
  cilHome,
  cilTruck,
  cilFactory,
  cilSettings,
  cilPeople,
  cilBarChart
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';
import { t } from 'i18next';
const salesPersonDashboardItems = [
  {
    component: CNavTitle,
    name: t('adminDashboard.CommonWords.navigation'),
  },
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Packages',
    to: '/admin/dashboard/packages',
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,  
  },
  
  {
    component: CNavItem,
    name: 'Warehouse',
    to: '/admin/dashboard/warehouse-carts',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'OrderRequests',
    to: '/admin/dashboard/orders/requests',
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
];
const salesAssistantDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/admin/dashboard/customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Packages',
    to: '/admin/dashboard/packages',
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,  
  },

  {
    component: CNavItem,
    name: 'Warehouse',
    to: '/admin/dashboard/warehouse-carts',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'OrderRequests',
    to: '/admin/dashboard/orders/requests',
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'SupplyManagement',
    to: '/admin/dashboard/supplies',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyInventory',
    to: '/admin/dashboard/supply-inventory',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'CatalogManagement',
    to: '/admin/dashboard/catalog',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'PendingCustomerRequests',
  //   to: '/admin/dashboard/pending-customers-requests',
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
];

const supplierDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
 
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',

    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  
];
const warehouseManagerDashboardItems = [
  {
    component: CNavTitle,
    name: 'Navigation',
  },
 
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  
];

const backOfficeDashboardItems = [
  {
    component: CNavTitle,
    name: 'Dashboard',
  },
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Team',
    to: '/admin/dashboard/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/admin/dashboard/customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyManagement',
    to: '/admin/dashboard/supplies',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyInventory',
    to: '/admin/dashboard/supply-inventory',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'CatalogManagement',
    to: '/admin/dashboard/catalog',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'PendingCustomerRequests',
  //   to: '/admin/dashboard/pending-customers-requests',
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
];
const salesAnalyticsDashboardItems = [ // This sales portal only accessible by admins.
  {
    component: CNavTitle,
    name: 'Analytics',
  },
  {
    component: CNavGroup,
    name: 'Analytics',    
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'AllSoldList',
        to: '/',
        icon: <CIcon icon={cilCash} customClassName="nav-icon" />,
        onClick: () => window.open(
          '/admin/dashboard/analytics/all-sold',
          "_blank"
        )
      },
      {
        component: CNavItem,
        name: 'ScanningTime',
        to: '/',
        icon: <CIcon icon={cilCash} customClassName="nav-icon" />,
        onClick: () => window.open(
          '/admin/dashboard/scanning-time',
          "_blank"
        )
      },
      {
        component: CNavItem,
        name: 'SupplyAnalysis',
        to: '/',
        icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
        onClick: () => window.open(
          '/admin/dashboard/supply-analysis',
          "_blank"
        )
      },
      {
        component: CNavItem,
        name: 'ProductQuality',
        to: '/',
        icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
        onClick: () => window.open(
          '/admin/dashboard/quality-analysis',
          "_blank"
        )
      },
      {
        component: CNavItem,
        name: 'StockAnalysis',
        to: '/',
        icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
        onClick: () => window.open(
          '/admin/dashboard/stock-analysis',
          "_blank"
        )
      }
    ],
  },
]
const adminDashboardItems = [
  {
    component: CNavTitle,
    name: t('adminDashboard.CommonWords.navigation'),
  },
 
  {
    component: CNavItem,
    name: 'MainDashboard',
    to: '/admin/dashboard/home',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  }, 
  {
    component: CNavItem,
    name: 'Team',
    to: '/admin/dashboard/users',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Customers',
    to: '/admin/dashboard/customers',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'PendingCustomerRequests',
  //   to: '/admin/dashboard/pending-customers-requests',
  //   icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Packages',
    to: '/admin/dashboard/packages',
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
  
  },
  {
    component: CNavItem,
    name: 'Warehouse',
    to: '/admin/dashboard/warehouse-carts',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  
  {
    component: CNavItem,
    name: 'OrderRequests',
    to: '/admin/dashboard/orders/requests',
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Products',
    to: '/admin/dashboard/products',
    icon: <CIcon icon={cilFactory} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyManagement',
    to: '/admin/dashboard/supplies',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'SupplyInventory',
    to: '/admin/dashboard/supply-inventory',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'CatalogManagement',
    to: '/admin/dashboard/catalog',
    icon: <CIcon icon={cilBasket} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'RightsManagement',
    to: '/admin/dashboard/permission-groups',
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
  }, 
  {
    component: CNavItem,
    name: 'WebsiteSettings',
    to: '/admin/dashboard/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
]

export default {
  salesAnalyticsDashboardItems: salesAnalyticsDashboardItems,
  salesPersonDashboardItems: salesPersonDashboardItems,
  salesAssistantDashboardItems: salesAssistantDashboardItems,
  backOfficeDashboardItems: backOfficeDashboardItems,
  claimsSupportDashboardItems: backOfficeDashboardItems,
  adminDashboardItems: adminDashboardItems,
  supplierDashboardItems: supplierDashboardItems,
  warehouseManagerDashboardItems: warehouseManagerDashboardItems,
};
