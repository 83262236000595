import React, { useEffect, useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,  
  CTableRow,
  CTableDataCell,
  CFormInput,
  CFormCheck,
  CButton,
  CSpinner,
  CToaster,
} from '@coreui/react';
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Cart, Pallet, ProductItem } from '../../../types';
import ProductCartModal from '../modals/ProductCartModal';
import { setSetArticles, setSelectedProducts, setSelectedSetArticles, setCart, setPackageProducts, setPallets, setSelectedPallets, setPackageTotalPrice, setPackageDiscount } from '../../../redux/slices/PackageSlice';
import { setWarehousePackageDiscount } from '../../../redux/slices/WarehousePackageSlice';
import PalletCartModal from '../modals/PalletCartModal';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { Utils } from '../../components/utils';
import { modifyPackage } from '../../../services/PackageService';
import { useNavigate, useLocation } from 'react-router-dom';
import { GenericErrorToast } from './ToastsComponent';

interface SummaryProps {
    updateSelectedProducts: (value: number, callBack?: () => void) => void;
    updateSelectedPallets?: (value: any) => void;
    updateSelectedSetArticles: (value: number) => void;
    itemsSelected?: number;
    is_warehouse_package?: boolean;
    packageID?: number;
    showApplyDiscountButton?: boolean;
    addToCart?: boolean;
    setAddToCart?: (value: boolean) => void;
    buyFullPackage?: boolean;
    setBuyFullPackage?: (value: boolean) => void;
    setFinalPrice: (value: number) => void;
    finalPrice: number;
    discountDisabled?: boolean;
    setDiscountInPercentage?: (value: number) => void;
}
const OrderSummary = (props: SummaryProps) => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const { updateSelectedProducts, updateSelectedSetArticles, itemsSelected, updateSelectedPallets, is_warehouse_package, packageID,
        showApplyDiscountButton, buyFullPackage, addToCart, finalPrice,  setFinalPrice, discountDisabled, setDiscountInPercentage} = props;
    const cart: Cart = is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseCart) : useSelector((state: RootState) => state.packageSlice.cart);
    const pallets: Pallet[] = is_warehouse_package ?  useSelector((state: RootState) => state.warehousePackageSlice.warehousePallets) : useSelector((state: RootState) => state.packageSlice.pallets);
    const packageTotalPrice =  is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehousePackageTotalPrice) : useSelector((state: RootState) => state.packageSlice.packageTotalPrice);
    let discount =  is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseDiscount) : useSelector((state: RootState) => state.packageSlice.discount);
    const discountType:any =  is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseDiscountType) : useSelector((state: RootState) => state.packageSlice.discountType);

    // const [finalPrice, setFinalPrice] = useState<number>(0);
    const [showProductCartModal, setShowProductCartModal] = useState<boolean>(false);
    const [showPairedCartModal, setShowPairedCartModal] = useState<boolean>(false);
    const [showPalletCartModal, setShowPalletCartModal] = useState<boolean>(false);
    const [showUnpairedCartModal, setShowUnpairedCartModal] = useState<boolean>(false);
    const setArticles: ProductItem[] = is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseSetArticles) : useSelector((state: RootState) => state.packageSlice.setArticles);
    const products: ProductItem[] = is_warehouse_package ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseProducts) : useSelector((state: RootState) => state.packageSlice.products);  
    const { getPackagesData, getPendingPackagesData, getAllProductSupplies, getOrdersData } = Utils();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [toast, addToast] = useState<any>(null);

    const handleOpenNewTab = (product: ProductItem, url: string) => {
        // Open the link in a new tab using window.open
        const newTab: any = window.open(url, '_blank');
        const serializedProduct = JSON.stringify(product);
        newTab.localStorage.setItem('product', serializedProduct);
    };

    const handleRadioChange = (type: 'percentage' | 'amount') => {       
        is_warehouse_package ? dispatch(setWarehousePackageDiscount({ value: 0, type })) : dispatch(setPackageDiscount({ value: 0, type }));
        // Update final price accordingly
        if (type === 'percentage') {
            setFinalPrice(packageTotalPrice - (packageTotalPrice * (discount / 100)));
        } else {
            setFinalPrice(packageTotalPrice - discount);
        }     
    };
    
    const handleDiscountChange = (e: any) => {
        let value = e.target.value;

        // Remove all special characters except numbers and a single dot
        // value = value.replace(/[^0-9.]/g, '');
        // value = value.replace(/[^0-9.-]/g, ''); // Allows digits, '.', and '-'
        value = value.replace(/(?<=,.*),/g, '');   // Remove extra ',' if multiple exist

        // Ensure only one decimal point exists
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.replace(/\.(?=.*\.)/g, ''); // Remove extra dots except the first one
        }
        // Restrict to three decimal places
        if (value.includes('.')) {
            const parts = value.split('.');
        
            // Remove commas after the dot
            parts[1] = parts[1].replace(/[^0-9]/g, '');            
        
            // Ensure the user can delete digits naturally
            if (parts.length === 2) {
                if (parts[1] === "") {
                    value = `${parts[0]}.`; // Keep the dot if the user is still typing
                } else if (parts[1].length > 2) {
                    value = `${parts[0]}.${parts[1].substring(0, 2)}`; // Restrict to two decimal places
                }
            };
        };

        if (discountType === 'percentage') {
            value = parseFloat(value) || 0;
            if (setDiscountInPercentage) {
                setDiscountInPercentage(value);
            }
        } else {
            value = value;
            const discountInPercent = parseFloat(((value / packageTotalPrice) * 100).toFixed(2));
            if (setDiscountInPercentage) {
                setDiscountInPercentage(discountInPercent);
            }
        }
        
        // Auto-switch to "amount (€)" if percentage exceeds 50
        if (discountType === 'percentage' && value > 50) {
            is_warehouse_package ? dispatch(setWarehousePackageDiscount({ value, type: "amount" })) : dispatch(setPackageDiscount({ value, type: "amount" }));
            setFinalPrice(packageTotalPrice - parseFloat(value));
        } else {
            is_warehouse_package ? dispatch(setWarehousePackageDiscount({ value, type: discountType })) : dispatch(setPackageDiscount({ value, type: discountType }));
    
            if (discountType === 'percentage') {
                setFinalPrice(packageTotalPrice - (packageTotalPrice * (value / 100)));
            } else {
                setFinalPrice(packageTotalPrice - parseFloat(value));
            }
        }
    };

    const resetToDefaults = () => {
        dispatch(setSelectedSetArticles([]));
        dispatch(setSelectedProducts([]));
        dispatch(setPackageProducts([]));
        dispatch(setSetArticles([]));
        dispatch(setPackageTotalPrice(0));
        dispatch(setPackageDiscount({ value: 0, type: 'percentage' }));
        dispatch(setCart({
            ...cart,
            standalone: [],
            unpaired: [],
            paired: [],
        }));
    };

    const editPackage = (editCallBackFunction?: () => void) => {
        discount = Number(discount);
        setShowSpinner(true);
        if((discount >= 100 && discountType !== 'amount') || (discount < 0 && discountType !== 'amount') || (discount >= packageTotalPrice  && discountType === 'amount') || discount.toString() == 'NaN') {
            addToast(GenericErrorToast(()=>{addToast(null)}, `Error !! ${t('adminDashboard.Packages.check_discount_value')}`));
            setShowSpinner(false);
            dispatch(setPackageDiscount({value: 0.00, type: 'percentage'}));
            return -1;
        };
        let newCalculatedDiscount;
        if (discountType === 'amount' && discount > 0) {
            newCalculatedDiscount = parseFloat((discount / packageTotalPrice).toFixed(3));
        } else if(discountType === 'amount' &&  discount < 0) {
            newCalculatedDiscount = 0.00;
            discount = discount
        } else {
            newCalculatedDiscount = (discount / 100);
            discount = 0.00
        }
        if(packageID) {
            let productIds: any = [];
            productIds = [...cart.standalone, ...cart.unpaired, ...cart.pallets];
            cart.paired.map((item)=>{
                productIds =  [...productIds, ...item.set_articles];
            });
            let formData =  {
                products: productIds,
                grand_total:Number(finalPrice.toFixed(2)),
                discount: newCalculatedDiscount,
                discountInAmount:discount,
                discountType:discountType,
                packageTotalPrice:packageTotalPrice,
                products_selection_percentage: 0.000,
                buy_full_package: buyFullPackage,
                add_to_cart: addToCart,
            }
            modifyPackage(packageID, formData).then((response) => {
                getPackagesData();
                getPendingPackagesData();
                getOrdersData();
                getAllProductSupplies();
                resetToDefaults();
                navigation('/admin/dashboard/home');
            }).catch((e) => {
                setShowSpinner(false);
                addToast(GenericErrorToast(()=>{addToast(null)}, `Error !! ${e}`));
                editCallBackFunction && editCallBackFunction();
                if(e?.response?.data?.discount) {
                    addToast(GenericErrorToast(()=>{addToast(null)}, e?.response?.data?.discount));
                }
            }).finally (() => {
                setShowSpinner(false);
            });
        }
    };

    return (
        <>
            <CToaster push={toast} placement="top-end" />
            <CCard style={{width: '95%', marginLeft: 24}} className='margin-top-24'>
                {
                    <ProductCartModal
                        headerText={"Products"}
                        isModalVisible={showProductCartModal}
                        closeModal={() => setShowProductCartModal(false)}
                        bodyText={""}
                        cartData={cart.standalone}
                        productsData={products}
                        productType="standalone"
                        onClick={(cartItem) =>{
                            handleOpenNewTab(products.filter((product) => {return product.id === cartItem})[0], '/admin/dashboard/product/details')
                        }}
                        removeFromCart={(id) => updateSelectedProducts(id)}
                    />
                }
                {
                    <ProductCartModal
                        headerText={"Paired Articles"}
                        isModalVisible={showPairedCartModal}
                        closeModal={() => setShowPairedCartModal(false)}
                        bodyText={""}
                        cartData={cart.paired}
                        productsData={setArticles}
                        productType="paired"
                        onClick={(cartItem) =>{
                            handleOpenNewTab(setArticles.filter((setArticle) => {return setArticle.id === cartItem})[0], '/admin/dashboard/product/details')
                        }}
                        removeFromCart={(id) => {
                            // updateSelectedSetArticles(id, setArticles.filter((product: ProductItem) => {return product.id === id})[0]?.selling_price)
                            updateSelectedSetArticles(id)
                        }}
                    />
                }

                {
                    <PalletCartModal
                        headerText={"Pallets"}
                        isModalVisible={showPalletCartModal}
                        closeModal={() => setShowPalletCartModal(false)}
                        bodyText={""}
                        cartData={cart.pallets}
                        productsData={pallets}
                        productType="paired"
                        onClick={(cartItem) =>{
                            // handleOpenNewTab(setArticles.filter((setArticle) => {return setArticle.id === cartItem})[0], '/admin/dashboard/product/details')
                        }}
                        removeFromCart={(id) => updateSelectedPallets && updateSelectedPallets(id)}
                    />
                }
                {
                    <ProductCartModal
                        headerText={"Unpaired Articles"}
                        isModalVisible={showUnpairedCartModal}
                        closeModal={() => setShowUnpairedCartModal(false)}
                        bodyText={""}
                        cartData={cart.unpaired}
                        productsData={setArticles}
                        productType="unpaired"
                        onClick={(cartItem) =>{
                            handleOpenNewTab(setArticles.filter((setArticle) => {return setArticle.id === cartItem})[0], '/admin/dashboard/product/details')
                        }}
                        removeFromCart={(id) => {
                            // updateSelectedSetArticles(id, setArticles.filter((product: ProductItem) => {return product.id === id})[0]?.selling_price)
                            updateSelectedSetArticles(id)
                        }}
                    />
                }
                <CCardHeader><h4>{t('adminDashboard.Packages.package_summary')}</h4></CCardHeader>
                <CCardBody>
                    <CTable>                
                        <CTableBody className='summary-item'>
                            <CTableRow>
                                <CTableDataCell onClick={()=>setShowProductCartModal(true)} className='summary-item-deep-font'>{t('adminDashboard.Products.products')}</CTableDataCell>
                                <CTableDataCell>{cart.standalone.length}</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell onClick={()=>setShowUnpairedCartModal(true)} className='summary-item-deep-font'>Unpaired Set-Articles</CTableDataCell>
                                <CTableDataCell>{cart.unpaired.length}</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell onClick={()=>setShowPairedCartModal(true)} className='summary-item-deep-font' >Paired Set-Articles</CTableDataCell>
                                <CTableDataCell>{cart.paired.length}</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell onClick={()=>setShowPalletCartModal(true)} className='summary-item-deep-font' >{t('adminDashboard.Pallets.pallets')}</CTableDataCell>
                                <CTableDataCell>{cart.pallets.length}</CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell>{t('adminDashboard.CommonWords.quantity')}</CTableDataCell>
                                <CTableDataCell>{cart.paired.length + cart.unpaired.length + cart.standalone.length + cart.pallets.length}</CTableDataCell>
                            </CTableRow>
                            {
                                itemsSelected ?
                                    <CTableRow>
                                        <CTableDataCell>{t('adminDashboard.Products.items_selected_percentage')}</CTableDataCell>
                                        <CTableDataCell>{itemsSelected}</CTableDataCell>
                                    </CTableRow>
                                : null
                            }
                            
                            <CTableRow>
                                <CTableDataCell>{t('adminDashboard.OrdersWords.sub_total_euro')}</CTableDataCell>
                                <CTableDataCell>{Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(packageTotalPrice)}</CTableDataCell>
                            </CTableRow>
                            {/* <CTableRow>
                                <CTableDataCell>{t('adminDashboard.CommonWords.discount_percentage')}</CTableDataCell>
                                <CTableDataCell>
                                    <CFormInput
                                        placeholder='Enter Discount'
                                        type='number'
                                        min={0}
                                        max={50}
                                        value={discount}
                                        onChange={(e: any) => {
                                            is_warehouse_package ? dispatch(setWarehousePackageDiscount(e.target.value)) : dispatch(setPackageDiscount(e.target.value));
                                            setFinalPrice(packageTotalPrice - (packageTotalPrice * (e.target.value / 100)))
                                        }}
                                        style={{width: isTablet ?'35%' : isMobile ? '100%' : '25%'}}
                                    />
                                </CTableDataCell>
                            </CTableRow> */}
                            <CTableRow>
                                <CTableDataCell style={{ fontWeight: 'bold', verticalAlign: 'top' }}>
                                    {t('adminDashboard.CommonWords.discount_label')}
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                                        <CFormCheck
                                            type="radio"
                                            label="%"
                                            checked={discountType === 'percentage'}
                                            onChange={() => handleRadioChange('percentage')}
                                            disabled={discountDisabled}
                                        />
                                        <CFormCheck
                                            type="radio"
                                            label="€"
                                            checked={discountType === 'amount'}
                                            onChange={() => handleRadioChange('amount')}
                                            disabled={discountDisabled}
                                        />
                                    </div>
                                </CTableDataCell>

                                {/* Discount Input Field */}
                                <CTableDataCell>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                                        {/* <CFormInput
                                            type={discountType === 'percentage' ? "number" : "text"}
                                            min={0}
                                            step={0.10}
                                            max={discountType === 'percentage' ? 50 : packageTotalPrice}
                                            value={discount === 0 || discount === null ? "" : discount}
                                            onChange={handleDiscountChange}
                                            style={{ width: '130px', textAlign: 'right' }}
                                        /> */}
                                        <CFormInput
                                            type={discountType === 'percentage' ? "number" : "text"}
                                            min={discountType === 'percentage' ? 0 : undefined}
                                            step={discountType === 'percentage' ? 0.10 : "any"}
                                            max={discountType === 'percentage' ? 50 : packageTotalPrice}
                                            value={discount === 0 || discount === null ? "" : discount}
                                            onChange={handleDiscountChange}
                                            style={{ width: '130px', textAlign: 'right' }}
                                            disabled={discountDisabled}
                                            title={discountDisabled ? String(t('adminDashboard.OrdersWords.unable_to_change_discount')) : ""}
                                        />
                                        {
                                            showApplyDiscountButton && (
                                                <CButton
                                                    variant="outline"
                                                    shape="square"
                                                    className="elv-btn d-flex justify-content-center align-items-center"
                                                    onClick={() => editPackage()}
                                                    disabled={showSpinner || discount === 0 || packageTotalPrice <= 0  || finalPrice <= 0 || finalPrice.toString() === 'NaN' || (discountType === 'percentage' && discount < 0) || discountDisabled}
                                                    style={{ minWidth: '90px', height: '40px' }}
                                                    
                                                    >
                                                    {showSpinner ? <CSpinner size="sm" /> : `${t('adminDashboard.CommonWords.apply')}`}
                                                </CButton>
                                            )
                                        }
                                    
                                    </div>
                                
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell>{t('adminDashboard.Packages.grand_total_euro')}</CTableDataCell>
                                <CTableDataCell>{Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(finalPrice)}</CTableDataCell>
                            </CTableRow>
                        </CTableBody>
                    </CTable>
                </CCardBody>
            </CCard>
        </>
        
    );
};

export default OrderSummary;