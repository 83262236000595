import { CFormInput } from "@coreui/react"
import { Table, Column, Plugins, Cell, } from 'fixed-data-table-2';
import { useCallback, useEffect, useMemo, useState } from "react";
import KPIComponent from "./AllSoldKPIComponent";
import { debounce } from "lodash";

interface AllSoldProductsProps {
    palletsOrderedData: any;
    setPalletsOrderedData: (value: any) => void;
    windowWidth: number;
    windowHeight: number;
    palletsKpiData: any;
};

const AllSoldPallets = (props: AllSoldProductsProps) => {
    const palletsColumnTitles: any = useMemo(() => ({
      // your column titles here
      lager_number: "Lager Nr",
      description: 'Description',
      sold_by: 'Sold by',
      supply_id: 'Supply',
      supply_type: "Supply Type",
      company: 'Company',
      created_at: 'Order Date',
      order_number: "Order",
      discount_invoice: "Discount invoice",
      discount_created_at: "Discount date",
      
      invoice_created_at: "Invoice Date",
      invoice_number: "Invoice",
      invoice_paid: "Paid",
      jtl_buying_price: "JTL Buying €",
      jtl_online_price: "JTL Online €",
      jtl_selling_price: "JTL Selling €",
      
      portal_buying_price: "Portal Buying €",
      portal_online_price: "Portal Online €",
      portal_selling_price: "Portal Selling €",
      profit: "Profit €",       
      // turnover: 'Turnover €',
      // turnover_ratio: 'Turnover Ratio',
    }), []);
    const {palletsOrderedData, windowWidth, windowHeight, palletsKpiData} = props;
    const [searchTexts, setSearchTexts] = useState<any>({});
   
    const [columnWidths, setColumnWidth] = useState<any> ({
        article_number: 220,
        brand_name: 100,
        company: 140,
        created_at: 140,
    })

      useEffect(() => {
        let defaultSearchValues: any = {};
        Object.keys(palletsColumnTitles).map((columnKey: any) => {
          defaultSearchValues[columnKey] = '';
        })
        setSearchTexts(defaultSearchValues);
      },[]);
    
    const onColumnResizeEndCallback = useCallback((newColumnWidth: number, columnKey: string) => {
      setColumnWidth((prevWidths: any) => ({
        ...prevWidths,
        [columnKey]: newColumnWidth
      }));
    }, []);

    const getFilteredData = () => {
      return palletsOrderedData.filter((row: any) => {
          return Object.keys(palletsColumnTitles).every(columnKey => {
              // If no search text is entered for this column, include all data
              if (!searchTexts[columnKey]) return true;
              // If data exists and includes the search text, include it in the filtered results
              return row[columnKey]?.toString().toLowerCase().includes(searchTexts[columnKey].toLowerCase());
          });
      });
    };
    
    const handleSearchChange = debounce((columnKey, value) => {
        setSearchTexts((prevTexts: any) => ({
          ...prevTexts,
          [columnKey]: value
        }));
    }, 150); // Adjust debounce time as needed
    const filteredData = getFilteredData();
    return (
        <div>
          <KPIComponent products={filteredData} />
     
          
        <Table
          rowHeight={45}
          headerHeight={90}
          rowsCount={filteredData?.length}
          className='all-sold-table margin-top-24'
          width={(windowWidth * 0.96) + 13}
          maxHeight={windowHeight * 0.8}
          touchScrollEnabled={true}
        >
          {
            Object.keys(palletsColumnTitles).map((columnKey: any) => {
              return (
                <Column
                  columnKey={columnKey}
                  header={
                      <Plugins.ResizeCell
                        className='elv-btn text-center'
                        onColumnResizeEnd={onColumnResizeEndCallback}
                      >
                        {palletsColumnTitles[columnKey]}
                        <CFormInput
                          value={searchTexts[columnKey]}
                          size='sm'
                          placeholder={`Search ${palletsColumnTitles[columnKey]}`}
                          // onChange={(e) => {setSearchTexts({
                          //     ...searchTexts,
                          //     [columnKey]: e?.target?.value
                          //   });
                          // }}
                          onChange={(e) => handleSearchChange(columnKey, e.target.value)}
                        />

                        
                      </Plugins.ResizeCell>
                    }
                  cell={({ rowIndex }) => <Cell>{filteredData[rowIndex][columnKey] ? filteredData[rowIndex][columnKey] : ""}</Cell>}
                  width={columnWidths[columnKey] || 150}
                />
              )
            })
          }
        </Table>
      </div>
    )
};

export default AllSoldPallets;
