import { CToast, CToastHeader, CToastBody } from "@coreui/react";
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons';
import { t } from "i18next";

export const GenericCreatedToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true}>         
            <div className="fw-bold me-auto">Created.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Item has been created successfully</CToastBody>
    </CToast>
);
export const GenericEditedToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton>         
            <div className="fw-bold me-auto">Edited.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Item has been edited successfully</CToastBody>
    </CToast>
);

export const GenericDeletedToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton>
            <div className="fw-bold me-auto">Deleted.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Item has been deleted.</CToastBody>
    </CToast>
);

export const packageCreatedSuccessfullyToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton>         
            <div className="fw-bold me-auto">Package Created</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody></CToastBody>
    </CToast>
);

export const packageCreationError = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton>         
            <div className="fw-bold me-auto">Error</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Some products are already part of another package.</CToastBody>
    </CToast>
);

export const GenericSuccessToast = (onClose:() => void, body_text?: string) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton style={{color: 'green'}}>
            <div className="fw-bold me-auto">Success. </div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>{body_text ? body_text : null}</CToastBody>
    </CToast>
);

export const GenericErrorToast = (onClose:() => void, body_text?: string) => (
    <CToast animation autohide visible delay={4000} onClose={() => {onClose()}}>
        <CToastHeader closeButton style={{color: 'red'}}>         
            <div className="fw-bold me-auto">Error occured! </div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>{body_text ? body_text : null}</CToastBody>
    </CToast>
);

export const pairedFoundToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={() => {onClose()}}>
        <CToastHeader closeButton>         
            <div className="fw-bold me-auto">Set Article</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Paired successfully</CToastBody>
    </CToast>
);
export const noPairFoundToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={() => {onClose()}}>
        <CToastHeader closeButton style={{color: 'red'}}>         
            <div className="fw-bold me-auto">Set Article</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>No more pair found</CToastBody>
    </CToast>
);
export const alreadyPairedToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={() => {onClose()}}>
        <CToastHeader closeButton style={{color: 'red'}}>         
            <div className="fw-bold me-auto">Article cannot be paired</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Already paired with another article.</CToastBody>
    </CToast>
);

export const EditedToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={() => {onClose()}}>
        <CToastHeader closeButton>         
            <div className="fw-bold me-auto">Edited.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Item has been edited successfully</CToastBody>
    </CToast>
);

export const DeletedToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={() => {onClose()}}>
        <CToastHeader closeButton>         
            <div className="fw-bold me-auto">Deleted.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Item has been deleted successfully</CToastBody>
    </CToast>
);
export  const AddedToCartToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={()=>onClose()}>
        <CToastHeader closeButton={true}>
            <div className="fw-bold me-auto">Added.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Product added to the cart.</CToastBody>
    </CToast>
);

export  const ErrorAddingToCartToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={()=>onClose()}>
        <CToastHeader closeButton={true} style={{color: 'red'}}>
            <div className="fw-bold me-auto">Error.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Product already in another's user cart.</CToastBody>
    </CToast>
);

export const ErrorToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true} style={{color: 'red'}}>
            <div className="fw-bold me-auto">Unexprected Error.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Some products are not active at the moment.</CToastBody>
    </CToast>
);

export const RemovedFromCartToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true} style={{color: 'red'}}>
            <div className="fw-bold me-auto">Removed.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Product removed from cart.</CToastBody>
    </CToast>
);

export const UserCreatedSuccessToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true}>
            <div className="fw-bold me-auto">Success.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>User account created.</CToastBody>
    </CToast>
);

export const UserCreatedErrorToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true} style={{color: 'red'}}>
            <div className="fw-bold me-auto">Error.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>User account already exists</CToastBody>
    </CToast>
);

export const UserEditedSuccessToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true}>
            <div className="fw-bold me-auto">Success.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>User information edited.</CToastBody>
    </CToast>
);

export const UserEditedErrorToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true} style={{color: 'red'}}>
            <div className="fw-bold me-auto">Error.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>User edit error.</CToastBody>
    </CToast>
);


export const PriceDuplicateErrorToast = (text: string, onClose:() => void) => (
    <CToast animation autohide visible delay={5000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true} style={{color: 'red'}}>
            <div className="fw-bold me-auto">Error</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>{text}</CToastBody>
    </CToast>
);

export const PDFGeneratedSuccessToast = (text:string, onClose: () => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true}>
            <div className="fw-bold me-auto">Success.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>{text}</CToastBody>
    </CToast>
)

export const PDFGeneratedErrorToast = (text:string, onClose: () => void) => (
    <CToast animation autohide visible delay={3000} onClose={() => {onClose()}}>
        <CToastHeader closeButton={true} style={{color: 'red'}}>
            <div className="fw-bold me-auto">Error.</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>{text}</CToastBody>
    </CToast>
)
export const NoDataFoundToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={4000} onClose={() => {onClose()}}>
        <CToastHeader closeButton style={{color: 'red'}}>         
            <div className="fw-bold me-auto">No data found</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>No data has been found against this input.</CToastBody>
    </CToast>
);
export const TruckErrorToast = (onClose:() => void) => (
    <CToast animation autohide visible delay={8000} onClose={() => {onClose()}}>
        <CToastHeader closeButton style={{color: 'red'}}>         
            <div className="fw-bold me-auto">Truck capacity notification</div>
            <small>few seconds ago</small>
        </CToastHeader>
        <CToastBody>Selected products cannot be palletized due to unavailability in the specified categories.</CToastBody>
    </CToast>
);

export const GenericInfoToast = (onClose: () => void, body_text?: string) => (
    <CToast animation autohide visible delay={3500} onClose={() => onClose()}>
        <CToastHeader closeButton style={{ color: '#23b2d9', display: 'flex', alignItems: 'center' }}>         
            <CIcon icon={cilInfo} className="text-secondary" size="xl" />
            <div className="fw-bold me-auto">{`${t('adminDashboard.CommonWords.information')}`}</div>
            <small>{`${t('adminDashboard.CommonWords.few_seconds_ago')}`}</small>
        </CToastHeader>
        <CToastBody>{body_text ? body_text : 'This offer must be purchased as a complete package!'}</CToastBody>
    </CToast>
);