import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Cart, Pallet, ProductItem } from '../../types';
import { REHYDRATE } from 'redux-persist';
export interface WarehousePackagesSliceState {
    warehouseProducts: ProductItem[];
    warehouseSetArticles: ProductItem[];
    warehousePallets: Pallet[];
    warehouseSelectedProducts: number[]; // store only the ids of selected product items.
    warehouseSelectedPallets: any; // store only the ids of selected pallet items.
    warehouseSelectedSetArticles: number[]; //store only the ids of selected set-articles items.
    warehouseCart: Cart; // This stores only the ids of cart Items (paired, unpaired and standalone products).
    warehousePackageTotalPrice: number;
    warehouseDiscount: number;
    warehouseDiscountType: string;
}
const initialState: WarehousePackagesSliceState = {
    warehouseProducts: [],
    warehousePallets: [],
    warehouseSetArticles: [],
    warehouseSelectedProducts: [],
    warehouseSelectedPallets: [],
    warehouseSelectedSetArticles: [],
    warehouseCart: {
      paired: [],
      unpaired: [],
      standalone: [],
      pallets: [],
    },
    warehousePackageTotalPrice: 0,
    warehouseDiscount: 0,
    warehouseDiscountType: 'percentage' as 'percentage' | 'amount',
};

const WarehousePackageSlice = createSlice({
  name: 'warehousePackageSlice',
  initialState,
  reducers: {
    rehydrateState(state, action: PayloadAction<any>) {
      return { ...state, ...action.payload };
    },
    setWarehousePackageProducts(state, action: PayloadAction<any>) {
      state.warehouseProducts = action.payload;
    },
    setWarehousePallets(state, action: PayloadAction<any>) {
      state.warehousePallets = action.payload;
    },
    setWarehouseSetArticles(state, action: PayloadAction<any>) {
      state.warehouseSetArticles = action.payload;
    },
    setWarehouseSelectedProducts(state, action: PayloadAction<any>) {
      state.warehouseSelectedProducts = action.payload;
    },
    setWarehouseSelectedPallets(state, action: PayloadAction<any>) {
      state.warehouseSelectedPallets = action.payload;
    },
    setWarehouseSelectedSetArticles(state, action: PayloadAction<any>) {
      state.warehouseSelectedSetArticles = action.payload;
    },
    setWarehouseCart(state, action: PayloadAction<any>) {
      state.warehouseCart = action.payload;
    },
    setWarehousePackageTotalPrice(state, action: PayloadAction<any>) {
      state.warehousePackageTotalPrice = action.payload;
    },
    setWarehousePackageDiscount(state, action: PayloadAction<{ value: number; type: 'percentage' | 'amount' }>) {
      state.warehouseDiscount = action.payload.value;
      state.warehouseDiscountType = action.payload.type;
    },
  },
})
  
  export const { setWarehousePackageProducts, setWarehouseSetArticles, setWarehouseSelectedProducts, setWarehouseSelectedSetArticles, setWarehousePallets, setWarehouseSelectedPallets,
     setWarehouseCart, setWarehousePackageTotalPrice, setWarehousePackageDiscount, rehydrateState } = WarehousePackageSlice.actions;
  export default WarehousePackageSlice.reducer;