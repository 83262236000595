import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
    Route, createRoutesFromElements,
} from "react-router-dom";
import { useSelector } from "react-redux";
import LoginScreen from "../authentication/LoginScreen";
// import RegisterScreen from "../authentication/RegisterScreen";
import AdminHomeScreen from "../admin/main-dashboard-screens/home/HomeScreen";
import CustomerHomeScreen from "../customer/screens/HomeScreen";
import CustomerPackageSummary from "../customer/screens/Packages";
import CustomerPackageDetails from "../customer/screens/PackageDetails";
import CustomerDashboard from "../customer/screens/CustomerDashboard";
import CustomerOrderSummary from "../customer/screens/Orders";
import CustomerOrderDetails from "../customer/screens/OrderDetails";
import MainDashboard from "../admin/main-dashboard-screens/home/MainDashboardScreen";
import UsersScreen from "../admin/main-dashboard-screens/users/UsersScreen";
import PackagesScreen from "../admin/main-dashboard-screens/packages/PackagesScreen";
import RightsManagementScreen from '../admin/main-dashboard-screens/rights/RightsManagement';
import AddUserScreen from "../admin/main-dashboard-screens/users/AddCustomerScreen";
import AddStaffUserScreen from "../admin/main-dashboard-screens/users/AddStaffUserScreen";
import CustomersScreen from "../admin/main-dashboard-screens/users/CustomersScreen";
import ProductsScreen from "../admin/main-dashboard-screens/products/ProductsScreen";
import SupplyProducts from "../admin/supplier-dashboard/SupplyProducts";
import ProductDetailScreen from "../admin/main-dashboard-screens/products/ProductDetailScreen";
import CustomerProductDetailScreen from "../customer/screens/ProductDetailScreen";
import WebsiteSettingsScreen from '../admin/main-dashboard-screens/settings/WebsiteSettings';
import CustomerTermsConditonsPDFScreen from "../customer/screens/TermsConditionsPDFScreen";
import CustomerScanningTime from '../admin/sales-dashboard-screens/CustomerScanningTime';
import DashboardStockAnalysis from '../admin/sales-dashboard-screens/StockAnalysis';

import CreatePackageScreen from '../admin/main-dashboard-screens/packages/CreatePackageScreen';
import PendingCustomersScreen from '../admin/main-dashboard-screens/users/PendingCustomersScreen';
import { RootState } from "../redux/store";
import { BACK_OFFICE_GROUP_ID, CUSTOMER_GROUP_ID, SALES_GROUP_ID, SUPPLIER_GROUP_ID, SALES_ASSISTANT_GROUP_ID, WAREHOUSE_GROUP_ID } from "../constants";
import OrderRequestsScreen from "../admin/main-dashboard-screens/orders/OrdersRequests";
import SupplyManagementScreen from "../admin/main-dashboard-screens/supply-management/SupplyManagementScreen";
import SupplierMainDashboard from "../admin/supplier-dashboard";
import EditPackageScreen from "../admin/main-dashboard-screens/packages/EditPackageScreen";
import PendingPackagesScreen from "../admin/main-dashboard-screens/packages/PendingPackagesScreen";
import WarehouseCartScreen from "../admin/main-dashboard-screens/packages/WarehouseCartScreen";

import CreateWarehousePackageScreen from "../admin/main-dashboard-screens/packages/CreateWarehousePackageScreen";
import PalletDetailScreen from "../admin/main-dashboard-screens/products/PalletDetailScreen";
import CatalogManagementScreen from "../admin/main-dashboard-screens/catalog/CatalogManagementScreen";
import SupplyInventoryScreen from "../admin/main-dashboard-screens/supply-management/SupplyInventory";
import CustomerWebsiteSettingsScreen from "../customer/screens/WebsiteSettings";
import ForgotPasswordScreen from "../authentication/ForgotPassword";
import ResetPasswordScreen from "../authentication/ResetPassword";
import AllSoldList from "../admin/sales-dashboard-screens/AllSoldList";
import SupplyAnalysis from "../admin/sales-dashboard-screens/SupplyAnalysis";
import ProductQualityAnalysis from "../admin/sales-dashboard-screens/ProductQualityAnalysis";

const router = (isAuthenticated: boolean, user: any) => createBrowserRouter(
    createRoutesFromElements(
        <Route>
            {/* Public routes */}
            {!isAuthenticated ?
                <Route>
                    <Route path="/" element={<LoginScreen />} />
                    <Route path="login" element={<LoginScreen />} />
                    <Route path="forgot-password" element={<ForgotPasswordScreen />} />
                    <Route path="reset-password" element={<ResetPasswordScreen />} />
                    {/* <Route path="signup" element={<RegisterScreen />} /> */} {/* primary functionality being replaced with redicting to Elvinci.de GmbH English Contact Form */}
                    <Route path="*" element={<LoginScreen />} />
                </Route>
                : (isAuthenticated && ((user?.groups?.includes(CUSTOMER_GROUP_ID)) || (user?.groups.length === 0 && user?.is_superuser === false))) ?
                    <Route>
                        <Route path="/*" element={<Navigate to="/customer/dashboard/summary" replace />} />
                        <Route path="login" element={<Navigate to="/customer/dashboard/summary" replace />} />
                        <Route path="signup" element={<Navigate to="/customer/dashboard/summary" replace />} />
                    </Route>
                    :
                    (isAuthenticated && ((user?.groups?.includes(WAREHOUSE_GROUP_ID)) || (user?.groups.length === 0 && user?.is_superuser === false))) ?
                        <Route>
                            <Route path="/*" element={<Navigate to="/admin/dashboard/products" replace />} />
                            <Route path="login" element={<Navigate to="/admin/dashboard/products" replace />} />
                            <Route path="signup" element={<Navigate to="/admin/dashboard/products" replace />} />

                        </Route>
                        :
                        <Route>
                            <Route path="/*" element={<Navigate to="/admin/dashboard/home" replace />} />
                            <Route path="login" element={<Navigate to="/admin/dashboard/home" replace />} />
                            <Route path="signup" element={<Navigate to="/admin/dashboard/home" replace />} />
                        </Route>
            }

            {/* Authenticated routes */}
            {isAuthenticated &&
                ((user?.groups?.includes(CUSTOMER_GROUP_ID)) || (user?.groups.length === 0 && user?.is_superuser === false)) ? (
                <Route path="customer" element={<CustomerHomeScreen />}>
                    <Route path="dashboard/summary" element={<CustomerDashboard />} />
                    <Route path="orders/summary" element={<CustomerOrderSummary />} />
                    <Route path="packages/summary" element={<CustomerPackageSummary />} />
                    <Route path="package/:id/details" element={<CustomerPackageDetails />} />
                    <Route path="settings" element={<CustomerWebsiteSettingsScreen />} />
                    <Route path="product/details" element={<CustomerProductDetailScreen />} />
                    <Route path="pallet/details" element={<PalletDetailScreen />} />
                    <Route path="order/details" element={<CustomerOrderDetails />} />
                    <Route path="terms-conditions" element={<CustomerTermsConditonsPDFScreen />} />
                    <Route path="*" element={<Navigate to="orders/summary" replace />} />
                </Route>
            )
                : isAuthenticated &&
                    ((user?.groups?.includes(SALES_GROUP_ID)) && user?.is_superuser === false) ? (
                    <Route path="admin/dashboard" element={<AdminHomeScreen />}>
                        <Route path="home" element={<MainDashboard />} />
                        {/* <Route path="customers" element={<CustomersScreen />} /> */}
                        {/* <Route path="add/user" element={<AddUserScreen />} /> */}

                        <Route path="packages" element={<PackagesScreen />} />
                        <Route path="create/package" element={<CreatePackageScreen />} />
                        <Route path="warehouse-carts" element={<WarehouseCartScreen />} />
                        <Route path="create/warehouse-package" element={<CreateWarehousePackageScreen />} /> 
                        <Route path="edit/package" element={<EditPackageScreen />} />
                        <Route path="package/:id/details" element={<CustomerPackageDetails />} />
                        <Route path="product/details" element={<ProductDetailScreen />} />
                        <Route path="pallet/details" element={<PalletDetailScreen />} />
                        <Route path="products" element={<ProductsScreen />} />
                        <Route path="packages" element={<PackagesScreen />} />
                        <Route path="pending/packages" element={<PendingPackagesScreen />} />
                        <Route path="orders/requests" element={<OrderRequestsScreen />} />
                        <Route path="settings" element={<CustomerWebsiteSettingsScreen />} />
                        <Route path="*" element={<Navigate to="home" replace />} />
                    </Route>
                )

                : isAuthenticated &&
                    ((user?.groups?.includes(SALES_ASSISTANT_GROUP_ID)) && user?.is_superuser === false) ? (
                    <Route path="admin/dashboard" element={<AdminHomeScreen />}>
                        <Route path="home" element={<MainDashboard />} />
                        <Route path="customers" element={<CustomersScreen />} />
                        <Route path="add/user" element={<AddUserScreen />} />
                        <Route path="pending-customers-requests" element={<PendingCustomersScreen />} />
                        <Route path="users" element={<UsersScreen />} />
                        <Route path="add/staff-user" element={<AddStaffUserScreen />} />
                        <Route path="packages" element={<PackagesScreen />} />
                        <Route path="create/package" element={<CreatePackageScreen />} />

                        <Route path="warehouse-carts" element={<WarehouseCartScreen />} />
                        <Route path="create/warehouse-package" element={<CreateWarehousePackageScreen />} /> 
                        <Route path="edit/package" element={<EditPackageScreen />} />
                        <Route path="package/:id/details" element={<CustomerPackageDetails />} />
                        <Route path="product/details" element={<ProductDetailScreen />} />
                        <Route path="pallet/details" element={<PalletDetailScreen />} />
                        <Route path="products" element={<ProductsScreen />} />
                        <Route path="pending/packages" element={<PendingPackagesScreen />} />
                        <Route path="orders/requests" element={<OrderRequestsScreen />} />
                        <Route path="supplies" element={<SupplyManagementScreen />} />
                        <Route path="catalog" element={<CatalogManagementScreen />} />
                        <Route path="supply-inventory" element={<SupplyInventoryScreen />} />
                        <Route path="settings" element={<WebsiteSettingsScreen />} />

                        <Route path="*" element={<Navigate to="home" replace />} />
                    </Route>
                )
                : isAuthenticated &&
                    ((user?.groups?.includes(BACK_OFFICE_GROUP_ID)) && user?.is_superuser === false) ? (
                    <Route path="admin/dashboard" element={<AdminHomeScreen />}>
                        <Route path="home" element={<MainDashboard />} />
                        <Route path="users" element={<UsersScreen />} />
                        <Route path="customers" element={<CustomersScreen />} />
                        <Route path="add/user" element={<AddUserScreen />} />
                        <Route path="add/staff-user" element={<AddStaffUserScreen />} />
                        <Route path="product/details" element={<ProductDetailScreen />} />
                        <Route path="pallet/details" element={<PalletDetailScreen />} />
                        <Route path="products" element={<ProductsScreen />} />
                        <Route path="supplies" element={<SupplyManagementScreen />} />
                        <Route path="catalog" element={<CatalogManagementScreen />} />
                        <Route path="supply-inventory" element={<SupplyInventoryScreen />} />
                        <Route path="settings" element={<WebsiteSettingsScreen />} />
                        <Route path="pending-customers-requests" element={<PendingCustomersScreen />} />
                        <Route path="*" element={<Navigate to="home" replace />} />
                    </Route>
                )
                : isAuthenticated &&
                    ((user?.groups?.includes(SUPPLIER_GROUP_ID)) && user?.is_superuser === false) ? (
                    <Route path="admin/dashboard" element={<AdminHomeScreen />}>
                        <Route path="home" element={<SupplierMainDashboard />} />
                        <Route path="settings" element={<CustomerWebsiteSettingsScreen />} />
                        <Route path="products" element={<SupplyProducts />} />
                        <Route path="product/details" element={<ProductDetailScreen />} />
                        <Route path="pallet/details" element={<PalletDetailScreen />} />
                        <Route path="*" element={<Navigate to="home" replace />} />

                    </Route>
                )
                
                : isAuthenticated &&
                    ((user?.groups?.includes(WAREHOUSE_GROUP_ID)) && user?.is_superuser === false) ? (
                    <Route path="admin/dashboard" element={<AdminHomeScreen />}>
                        <Route path="products" element={<ProductsScreen />} />
                        <Route path="settings" element={<CustomerWebsiteSettingsScreen />} />
                        <Route path="product/details" element={<ProductDetailScreen />} />
                        <Route path="pallet/details" element={<PalletDetailScreen />} />
                        <Route path="*" element={<Navigate to="products" replace />} />
                    </Route>
                )

                :
                
                (
                    <Route path="admin/dashboard" element={<AdminHomeScreen />}>
                        {/* Define your admin routes here */}
                        <Route path="home" element={<MainDashboard />} />
                        
                        <Route path="analytics/all-sold" element={<AllSoldList />} />
                        <Route path="stock-analysis" element={<DashboardStockAnalysis />} />
                        <Route path="scanning-time" element={<CustomerScanningTime />} />
                        <Route path="supply-analysis" element={<SupplyAnalysis />} />
                        <Route path="quality-analysis" element={<ProductQualityAnalysis />} />
                        

                        <Route path="users" element={<UsersScreen />} />
                        <Route path="customers" element={<CustomersScreen />} />
                        <Route path="packages" element={<PackagesScreen />} />
                        <Route path="create/package" element={<CreatePackageScreen />} />
                        <Route path="warehouse-carts" element={<WarehouseCartScreen />} />
                        <Route path="create/warehouse-package" element={<CreateWarehousePackageScreen />} />
                        <Route path="edit/package" element={<EditPackageScreen />} />
                        <Route path="settings" element={<WebsiteSettingsScreen />} />
                        <Route path="add/user" element={<AddUserScreen />} />
                        <Route path="add/staff-user" element={<AddStaffUserScreen />} />
                        <Route path="products" element={<ProductsScreen />} />
                        <Route path="pending-customers-requests" element={<PendingCustomersScreen />} />
                        <Route path="packages" element={<PackagesScreen />} />
                        <Route path="pending/packages" element={<PendingPackagesScreen />} />

                        <Route path="product/details" element={<ProductDetailScreen />} />
                        <Route path="orders/requests" element={<OrderRequestsScreen />} />
                        <Route path="permission-groups" element={<RightsManagementScreen />} />
                        <Route path="supplies" element={<SupplyManagementScreen />} />
                        <Route path="catalog" element={<CatalogManagementScreen />} />
                        <Route path="supply-inventory" element={<SupplyInventoryScreen />} />
                        <Route path="package/:id/details" element={<CustomerPackageDetails />} />
                        <Route path="pallet/details" element={<PalletDetailScreen />} />

                        <Route path="*" element={<Navigate to="home" replace />} />
                    </Route>
                )
            }
            <Route path="logout" element={<Navigate to="/login" replace />} />
        </Route>
    )
);
const AppRouter = () => {
    const isAuthenticated = useSelector((state: RootState) => state.authSlice.isAuthenticated);
    const user = useSelector((state: RootState) => state.authSlice.user);
    return (
        <RouterProvider router={router(isAuthenticated, user)} />
    )
}
export default AppRouter;
