import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsAuthenticated, setAccessToken, setLoggedinUser, setClaimAPIToken } from "../redux/slices/AuthSlice";
import { Button, Form } from "react-bootstrap";
import BlackLogo from '../assets/images/black-logo.png'
import './Authstyles.css';
import { Link } from "react-router-dom";
import {loginUser, getAuthUser} from '../services/AuthService';
import { CInputGroup, CSpinner } from "@coreui/react";
import { loginClaimAPI } from "../services/ClaimAPIService";
import { CLAIM_SUPPORT } from "../constants";

function LoginScreen() {   
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const login = (e: any) => {
        e.preventDefault();
        setLoading(true);       
        loginUser(email, password).then((response) => {
            getAuthUser().then((authResponse) => {
                if(authResponse?.data?.groups?.includes(CLAIM_SUPPORT) ) {
                    loginClaimAPI(email, password).then((claimResponse) => {
                        console.log('Claim API success: ', claimResponse?.data?.auth_token);
                        dispatch(setClaimAPIToken(claimResponse?.data?.auth_token))
                        dispatch(setLoggedinUser(authResponse.data));
                        dispatch(setIsAuthenticated(true));
                        dispatch(setAccessToken(response.data.access_token));
                        setLoading(false);
                    }).catch((e) => {
                        console.log('claim API login fail.');
                        dispatch(setClaimAPIToken(''));
                        dispatch(setLoggedinUser(authResponse.data));
                        dispatch(setIsAuthenticated(true));
                        dispatch(setAccessToken(response.data.access_token));
                        setLoading(false);
                    })
                }
                else {
                    dispatch(setLoggedinUser(authResponse.data));
                    dispatch(setIsAuthenticated(true));
                    dispatch(setAccessToken(response.data.access_token));
                    setLoading(false);
                }
            }).catch((e) => {
                console.log('érror: ', e);
            });
        }).catch((e) => {
            dispatch(setIsAuthenticated(false));
            setError(e.response?.data?.non_field_errors);
            console.log("login failure", e.response?.data?.non_field_errors);
           setLoading(false);
        });
    }
  return (
    <div className="main-container">
        <div className="bg-image-blurred"></div>
        <div className="login-form-container col-md-4 col-lg-3">
            <div style={{textAlign: "center"}}>
                <img src={BlackLogo} style={{width: 170}}/>
            </div>
            <div style={{textAlign: "center", marginTop: 14, marginBottom: 14}}>
                <h4>Login</h4>
            </div>
            <Form onSubmit={(e) => login(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <CInputGroup className='form-input-group'>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            style={{borderWidth: 0}}
                            required
                        />
                        {
                            showPassword ? 
                                <img className='margin-left-12 show-hide-password-icon' src={require('../assets/images/eye-icon.png')} onClick={()=>setShowPassword(false)}/>
                            :
                                <img className='margin-left-12 show-hide-password-icon' src={require('../assets/images/eye-slash-icon.png')} onClick={()=>setShowPassword(true)}/>
                        }
                    </CInputGroup>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Link to="/forgot-password">Forgot password?</Link>
                </Form.Group>
                
                <Form.Group className="mb-3 margin-top-24" controlId="formBasicCheckbox">
                    {/* Disable primary functionality for the time being as Sign-Up form will be linked to Elvinci.de GmbH English Contact Form */}
                    {/* <Link to="/signup">Don't have an account?</Link>  */}
                    <a href="https://www.elvinci.de/en/contact/" target="_blank" rel="noopener noreferrer">Don't have an account?</a>
                </Form.Group>                
                <Button className="login-button" type="submit">
                    {loading ? <CSpinner /> : "Login"}
                </Button>
                {
                   error && error.length > 0 ?
                        <p style={{color: 'red', marginTop: 8}}>{error}</p>
                    :    null
                }
            </Form>
        </div>
    </div>
  );
}

export default LoginScreen;
