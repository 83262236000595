
import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSmartTable, CToaster } from '@coreui/react-pro';
import '../CustomerStyles.css';
import { Package, ProductItem } from '../../types';
import { getPackage } from '../../services/PackageService';
import { CHeader } from '@coreui/react';
import { ORDER_STATUS } from '../../constants';
import { Utils } from '../../admin/components/utils';
import { useTranslation } from 'react-i18next';

const CustomerOrderDetails = () => {
    const [activePage, setActivePage] = useState(1);
    const {t} = useTranslation();
    const {customerOrderDetailsColumns} = Utils();
    const [products, setProducts] = useState<ProductItem[]>([]);
    const [packageItem, setPackageItem] = useState<Package>();
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [records, setRecords] = useState<number>(0);
    const { downloadZipImages, checkPDFStatus, toast } = Utils();

    const getPackageById = () => {
        const packageId = localStorage.getItem('product');
        if (packageId !== null) {
            const parsedPackageId = parseInt(packageId, 10); // Parsing string to number
            getPackage(parsedPackageId).then((response) => {
                setPackageItem(response.data);
                setProducts([...response.data.standalone_products, ...response.data.set_articles, ...response.data.pallets_products]);
                console.info('products ', products);
            }).catch((e) => {
                console.error('error while getting package: ', e);
            });
        } else {
            console.error("Package ID is null or not found in localStorage");
        }
    }

    useEffect(() => {
        getPackageById();
    }, []);
    useEffect(() => {
        setRecords(products.length);
    }, [products]);

    return (
        <div className='website-settings-main-container'>
            <CToaster push={toast} placement="top-end" />
            <CHeader><h1><b>{t('adminDashboard.Customers.my_orders_details')}</b></h1></CHeader>
            <CSmartTable
                itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
                tableFilter
                tableFilterLabel={`${t('adminDashboard.CommonWords.search')}:`}
                tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
                noItemsLabel={`${t('adminDashboard.CommonWords.no_items_found')}`}
                columns={customerOrderDetailsColumns}
                columnFilter={true}
                columnSorter={true}
                items={products}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                pagination={true}
                paginationProps={{
                    activePage: activePage,
                    pages: Math.ceil(records / itemsPerPage) || 1,
                }}

                tableProps={{
                    hover: true,
                    responsive: true,
                }}

                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                    setActivePage(1)
                }}

                onItemsPerPageChange={(itemsPerPage) => {
                    setActivePage(1)
                    setItemsPerPage(itemsPerPage)
                }}

                scopedColumns={{
                    description: (item: any) => {
                        return (
                            <td className="py-2">
                                {
                                    item?.pallet_products ?
                                    `${item.description}`
                                    :
                                    `${item.sub_category} ${item.model}`
                                }
                               
                            </td>
                        )
                    },
                    status: (item: any) => {
                        return (
                            <td className="py-2">
                                {ORDER_STATUS[item.status]}
                            </td>
                        )
                    },
                    actions: (item: any) => {
                        return (
                            <td className="py-2">
                                {
                                    !item.pallet_products ? (
                                        <CDropdown style={{ marginBottom: 10 }}>
                                            <CDropdownToggle className="elv-btn" href="#" style={{ width: 100 }}>{t('adminDashboard.CommonWords.action')}</CDropdownToggle>
                                            <CDropdownMenu className='pointer' style={{ position: 'absolute' }}>
                                                <CDropdownItem onClick={() => downloadZipImages([item.id])}>{t('adminDashboard.OrdersWords.download_images')}</CDropdownItem>
                                                <CDropdownItem onClick={() => { checkPDFStatus(item.id, 'productPDF') }}>{t('adminDashboard.OrdersWords.download_pdf')}</CDropdownItem>
                                            </CDropdownMenu>
                                        </CDropdown>
                                    ) : 'No Action'
                                }
                            </td>
                        )
                    }
                }}
            />
        </div>
    );
};
export default CustomerOrderDetails;