import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CCol, CCard, CCardBody, CCardTitle, CCardText, CRow, CTooltip, CButton, CToaster, CNavItem } from '@coreui/react';
import { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'simplebar/dist/simplebar.min.css';
import { CUSTOMER_GROUP_ID, formattedPrice, SUPPLIER_GROUP_ID } from '../../constants';
import { Utils } from '../../admin/components/utils';
import { LoggedinUser, Package, Pallet, ProductItem } from '../../types';
import { RootState } from '../../redux/store';
import { addToCart, removeFromCart } from '../../redux/slices/CustomerCartSlice';
import EmptyImage from '../../assets/images/empty-image.jpg';
import '../CustomerStyles.css';
import 'simplebar/dist/simplebar.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from 'react-i18next';
import { GenericInfoToast } from '../../admin/components/create-package-components/ToastsComponent';
import { isMobile, isTablet } from 'react-device-detect';

interface PackageDetailProductsProps {
    products: any;
    minPrice: string;
    maxPrice: string;
    minScale: string;
    maxScale: string;
    searchedInput: string;
    currentSubGroupList: number[];
    productCart: any;
    packageItem: Package | undefined;
    combinedProductIds: number[];
    setShowRefreshButton: (value: boolean) => void;
    setDisableConfirmButton: (value: boolean) => void;
    isDiscounted: boolean;
    discountPercentage: string;
    discountPoint: number;
    calculateDiscountedPriceOfProduct: (totalPriceOfPackage: number, discountRate: number, originalProductPrice: number) => number
    packagePrice: number;
    discountAmount: number;
}
const PackageDetailProductsComponent = (props: PackageDetailProductsProps) => {
    const state = useLocation();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const [viewedItems, setViewedItems] = useState<number[]>([]);
    const {
        products, minScale, maxScale, minPrice, maxPrice, searchedInput, currentSubGroupList, isDiscounted, discountPercentage, packagePrice, discountAmount,
        discountPoint, productCart, combinedProductIds, packageItem, setShowRefreshButton, setDisableConfirmButton, calculateDiscountedPriceOfProduct
    } = props;
    const [toast, addToast] = useState<any>(null);
    
    useEffect(() => {
        console.log(packagePrice);
        // Retrieve viewed items from local storage on component mount
        const viewedItemsKey = `${state?.state?.packageItem?.id}`;
        const storedViewedItems = JSON.parse(localStorage.getItem(viewedItemsKey) || '[]');
        setViewedItems(storedViewedItems);
    }, []); // Empty dependency array to run the effect only on mount

    const calculateSellingPrice = (productPrice: number) => {
        if(discountAmount < 0 && productPrice != 0){
            const surcharge = (productPrice / packagePrice) * Math.abs(discountAmount);
            const newPrice = parseFloat((productPrice + surcharge).toFixed(2));
            return formattedPrice(newPrice);
        } else {
            return formattedPrice(productPrice);
        }
    };
    
    const { filterByPriceCriteria, filterByScaleCriteria, filterBySearchedCriteria, filterBySubCategory, getLink } = Utils();
    
    const handleAddToCart = (packageId: number, id: number, selling_price: number, combined_products?: number[]) => {
        const isInCart = productCart.some((item: number) => item === id);
        if (isInCart) {
            dispatch(removeFromCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        } else {
            dispatch(addToCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        }
        setShowRefreshButton(true);
        setDisableConfirmButton(true);
    };

    
    const images = (product: ProductItem) => {
        return product.image_list?.map((item: any) => {
            return `https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${product.id}&filename=${item}_s.jpg`
        });
    };
    
    const handleOpenNewTab = (product: ProductItem | Pallet, url: string) => {

        // Open the link in a new tab using window.open
        if('pallet_products' in product) {
            const newTab: any = window.open(url, '_blank');
            const serializedProduct = JSON.stringify(product);
            const serializedPackageItem = JSON.stringify(packageItem);
            newTab.localStorage.setItem('pallet', serializedProduct);
            newTab.localStorage.setItem('packageItem', serializedPackageItem);
        }
        else {
            const newTab: any = window.open(url, '_blank');
            const serializedProduct = JSON.stringify(product);
            const serializedPackageItem = JSON.stringify(packageItem);
            newTab.localStorage.setItem('product', serializedProduct);
            newTab.localStorage.setItem('packageItem', serializedPackageItem);
        }        

        // Store the viewed item with a dynamic name based on package ID
        const viewedItemsKey = `${state?.state?.packageItem?.id}`;
        const storedViewedItems = JSON.parse(localStorage.getItem(viewedItemsKey) || '[]');
        if (!storedViewedItems.includes(product.id)) {
            const updatedViewedItems = [...storedViewedItems, product.id]; // Add the new product ID
            localStorage.setItem(viewedItemsKey, JSON.stringify(updatedViewedItems));
            setViewedItems(updatedViewedItems); // Update the state with the new array
        } else {
            setViewedItems(storedViewedItems);
        }

    };

    // Set chracter limit based on GroupID
    const isStaffUser = !authUser.groups?.includes(CUSTOMER_GROUP_ID) && !authUser.groups?.includes(SUPPLIER_GROUP_ID);
    const characterLimit = isStaffUser ? 27 : 32;

    return (
        <CRow>
        <CToaster push={toast} placement="top-end" />
        {
            products?.filter((item: any) => {
                return (!combinedProductIds.includes(item.id)
                    && filterByPriceCriteria(item, minScale, maxScale)
                    && filterByScaleCriteria(item, minPrice, maxPrice)
                    && filterBySearchedCriteria(item, searchedInput))
                    && filterBySubCategory(item, currentSubGroupList)
                  
            }).map((productItem: any) => {
                // this filteration means the set articles which are paired only one of them would be rendered because it is a whole product for the customer and 
                // if the customer opens a detailed view he can see details of both of the set articles separatly. but on main screen it would be shown as one single
                // product so that is why I have added this filteration.
                return (
                    <CCol xs={12} md={6} lg={4} xl={3} xxl={2} style={{ marginTop: 24, marginBottom: 24, ...(isStaffUser ? { flex: '0 0 calc(100% / 5)', maxWidth: 'calc(100% / 5)' } : '') }}>

                        <CCard // Parent card component.
                            className={`pointer ${productCart.includes(productItem.id) ? 'selected-card' : 'unselected-card'}`}
                            onClick={() => {
                                if (authUser.groups?.includes(CUSTOMER_GROUP_ID) && packageItem && packageItem.status === 'A' && !packageItem.buy_full_package) {
                                    handleAddToCart(packageItem?.id, productItem.id, productItem.selling_price, productItem.combined_products);
                                } else if (authUser.groups?.includes(CUSTOMER_GROUP_ID) && packageItem && packageItem.status === 'A' && packageItem.buy_full_package) {
                                    addToast(GenericInfoToast(() => {addToast(null)}, `${t('adminDashboard.OrdersWords.buy_full_package_toast_message')}`));
                                }
                            }}
                        >
                            {authUser?.groups && authUser?.groups?.includes(CUSTOMER_GROUP_ID) && viewedItems.includes((productItem.id)) && <div className="ribbon ribbon-top-right"><span>{t('adminDashboard.Products.seen')}</span></div>}
                            {authUser?.groups && authUser?.groups.includes(CUSTOMER_GROUP_ID) && productItem.combined_products && productItem.combined_products.length > 0 && <div className="ribbon ribbon-top-left"><span>{t('adminDashboard.Products.paired')}</span></div>}                            
                            <CCardBody className="customer-product-card-body">
                                <div onClick={(e) => { e.stopPropagation() }}>
                                    {
                                    'pallet_products' in productItem ?
                                        <div>
                                            <img src={`https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=340439039052693323&filename=0.jpg`} className={"aspect-ratio-style"} alt="logo" loading='lazy'/>
                                        </div>
                                    :
                                    images(productItem)?.length === 0 ?
                                        <div style={{cursor: 'pointer'}}>
                                            <img src={EmptyImage} className={"aspect-ratio-style"} alt="logo" loading='lazy'/>
                                        </div>
                                        :
                                        <Carousel showThumbs={false} showStatus={false}>
                                            {
                                                images(productItem)?.map((imageItem: string) => {
                                                    return (
                                                        <div
                                                            style={{cursor: 'pointer'}}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (authUser?.groups && authUser.groups.includes(CUSTOMER_GROUP_ID)) {
                                                                    productItem?.pallet_products ?
                                                                        handleOpenNewTab(productItem, '/customer/pallet/details')
                                                                    :
                                                                        handleOpenNewTab(productItem, '/customer/product/details');
                                                                }
                                                                else {
                                                                    productItem?.pallet_products ?
                                                                        handleOpenNewTab(productItem, '/admin/dashboard/pallet/details')
                                                                    :
                                                                        handleOpenNewTab(productItem, '/admin/dashboard/product/details');
                                                                }
                                                            }}>
                                                            <img src={imageItem} className={"aspect-ratio-style"} loading='lazy' />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    }
                                </div>
                                <div className='customer-product-card-body-text'>
                                    <h6>
                                        {
                                            'pallet_products' in productItem ?
                                            <>
                                                <CTooltip content={productItem?.description}>
                                                    <CCardTitle> {productItem?.description}</CCardTitle>
                                                </CTooltip>
                                                <CCardText>
                                                    <div className='margin-top-12'><b> ID: {productItem?.id}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.usage')}: {productItem?.usage}</b></div>
                                                    {/* <div className='margin-top-12'><b> {t('adminDashboard.Pallets.products_inside')}: {productItem?.pallet_products?.length}</b></div> */}
                                                    {!authUser.groups?.includes(CUSTOMER_GROUP_ID) && <div className='margin-top-12'><h6> {t('adminDashboard.Products.buying_price')}: {productItem?.buying_price}</h6></div>}
                                                    <div className='margin-top-12'><h6> {t('adminDashboard.Products.online_price')}: {formattedPrice((Number(productItem?.current_online_price)))}</h6></div>
                                                    {!isDiscounted && (
                                                        <div className='margin-top-12'>
                                                            <div className='margin-top-12'><h5> {t('adminDashboard.Products.selling_price')}: {calculateSellingPrice((Number(productItem?.selling_price)))}</h5></div>
                                                        </div>
                                                    )}
                                                </CCardText>
                                            </>
                                            :
                                            <>
                                                <CTooltip content={`${productItem?.brand ? productItem?.brand : 'No Brand'}` + ' ' + productItem?.model}>
                                                    <CCardTitle> {productItem?.brand ? productItem?.brand : 'No Brand'} {productItem?.model} </CCardTitle>
                                                </CTooltip>
                                                <CCardText>
                                                    <div className='margin-top-12'><b> ID: {productItem?.id}</b></div>
                                                    {/* <div className='margin-top-12'><b> {t('adminDashboard.Products.brand')}: {productItem?.brand}</b></div> */}
                                                    {/* <div className='margin-top-12'><b> {t('adminDashboard.CommonWords.model')}: {productItem?.model}</b></div> */}
                                                    {
                                                        productItem?.sub_category?.length > characterLimit && !isTablet && !isMobile ?
                                                            <CTooltip content={productItem?.sub_category}>
                                                                <div className='margin-top-12'>
                                                                    <b>
                                                                        {/* {t('adminDashboard.Products.category')}: {' '} */}
                                                                        {
                                                                            productItem?.sub_category.substring(0, characterLimit) + "..."
                                                                        }
                                                                    </b>
                                                                </div>
                                                            </CTooltip>
                                                        :
                                                        <div className='margin-top-12'> <b> {productItem?.sub_category}</b></div>
                                                    }

                                                    {
                                                        authUser.groups?.includes(CUSTOMER_GROUP_ID) || authUser.groups?.includes(SUPPLIER_GROUP_ID)  ?
                                                            ''
                                                            // <div className='margin-top-12'><b> {t('adminDashboard.Packages.article')} #: {productItem?.article}</b></div>
                                                        :
                                                            <div className='margin-top-12 url-decoration'><b 
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    const link = productItem.link ? productItem.link : getLink(productItem.model || '');
                                                                    if (link?.length > 0) {
                                                                        window.open(link.startsWith('http') ? link : `https://${link}`, '_blank', 'noopener,noreferrer');
                                                                    }
                                                                }}
                                                            > {t('adminDashboard.Packages.article')} #: {productItem?.article}</b></div>
                                                    }
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.usage')}: {productItem?.usage?.name}</b></div>
                                                    <div className='margin-top-12'><h6> {t('adminDashboard.Products.online_price')}: {formattedPrice((Number(productItem?.current_online_price)))}</h6></div>
                                                    {discountPoint==0 &&(
                                                        <div className='margin-top-12'>
                                                            <h5>{t('adminDashboard.Products.selling_price')}: {calculateSellingPrice(Number(productItem?.selling_price))}</h5>
                                                        </div>
                                                    )}
                                                </CCardText>
                                            </>
                                        }
                                        <div className="d-flex align-items-center justify-content-between w-100 margin-top-12">
                                            {/* Button on the Right */}
                                            <CButton
                                                className={productCart.includes(productItem.id) ? 'elv-btn-outline package-detail-button' : 'elv-btn package-detail-button'}
                                                variant='outline'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (authUser?.groups?.includes(CUSTOMER_GROUP_ID)) {
                                                        productItem?.pallet_products ?
                                                            handleOpenNewTab(productItem, '/customer/pallet/details')
                                                        :
                                                            handleOpenNewTab(productItem, '/customer/product/details');
                                                    } else {
                                                        productItem?.pallet_products ?
                                                            handleOpenNewTab(productItem, '/admin/dashboard/pallet/details')
                                                        :
                                                            handleOpenNewTab(productItem, '/admin/dashboard/product/details');
                                                    }
                                                }}
                                            >
                                                {t('adminDashboard.CommonWords.more_details')}
                                            </CButton>
                                            {/* Show Selling Price for Non-Customers */}
                                            {isDiscounted && discountPoint!=0 && (
                                                <CCol className="text-end">
                                                        {/* Row for Discount Badge & Original Price */}
                                                        <div className="d-flex align-items-center justify-content-end gap-2">
                                                            {/* Discount Badge */}
                                                            <div className="discount-badge">
                                                                {`-${discountPercentage}`}
                                                            </div>
                                                            {/* Original Price (Strikethrough) */}
                                                            <p className="text-muted text-decoration-line-through m-0">
                                                                {formattedPrice(productItem?.selling_price)}
                                                            </p>
                                                        </div>
                                                        {/* Final Price */}
                                                        <h5 style={{ marginBottom: '8px', fontSize: '24px' }}>
                                                            <b>{formattedPrice(calculateDiscountedPriceOfProduct(packagePrice, discountPoint, productItem.selling_price))}</b>
                                                        </h5>    
                                                </CCol>
                                            )}
                                        </div>
                                    </h6>
                                </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                )
            })
        }
        </CRow>
    )
};
export default PackageDetailProductsComponent;