import { CModal, CModalHeader, CModalBody, CModalFooter, CButton, CInputGroupText } from "@coreui/react"
import "../AdminComponentStyles.css";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { t } from "i18next";

interface ModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    selectedFile: File | null;
    setSelectedFile: (value: File | null) => void;
    manualPackage: boolean;
    setManualPackage: (value: boolean) => void;
    handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
    handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRemoveFile: () => void;
    handleDownload: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    uploadFileAndCheck: () => void;
}
const ManualPackageCreationUploadFile = (props: ModalProps) => {
    const {
        isModalOpen,
        setIsModalOpen,
        selectedFile,
        setSelectedFile,
        manualPackage,
        setManualPackage,
        handleDragOver,
        handleDrop,
        handleFileUpload,
        handleRemoveFile,
        handleDownload,
        uploadFileAndCheck
    } = props;
    
    return (
        <CModal className="zero-margin" visible={isModalOpen} alignment="center" onClose={() => {
            setIsModalOpen(false);
            if(!selectedFile){
                setManualPackage(false);
            }
            }}>
                        <CModalHeader>
                            <h5>{t('adminDashboard.Packages.import_products_via_csv')}</h5>
                        </CModalHeader>
                        <CModalBody>
                        {/* Drag & Drop File Upload */}
                        <div 
                            className="file-drop-area"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onClick={() => document.getElementById('fileInput')?.click()}
                        >
                            {selectedFile ? (
                                <div className="d-flex align-items-center gap-2 p-2">
                                    <h6 className="m-0">{selectedFile.name}</h6>
                                    <CInputGroupText 
                                        onClick={handleRemoveFile} 
                                        style={{
                                            backgroundColor: "white",  
                                            cursor: "pointer",
                                            border: "none",
                                            paddingLeft: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <span>&#10005;</span>  {/* Cross (X) button */}
                                    </CInputGroupText>
                                </div>
                            ) : (
                                <>
                                    <h6 className="mb-2">{t('adminDashboard.Packages.drag_and_drop')}</h6>
                                    <p className="text-muted mt-2">{t('adminDashboard.Packages.click_to_browse')}</p>
                                </>
                            )}
                            <input 
                                type="file" 
                                id="fileInput" 
                                className="d-none"
                                accept=".csv, .xlsx, .xls"  // Restrict file selection
                                onChange={handleFileUpload}
                            />
                        </div>
                    </CModalBody>
                    <CModalFooter className="d-flex justify-content-between border-none" >
                        {/* Left Side: Download Button */}
                        <a href="#" onClick={handleDownload} style={{ textDecoration: 'underline' }}>
                            <CIcon icon={cilCloudDownload} size="sm" /> {t('adminDashboard.CommonWords.download_template')}
                        </a>

                        {/* Right Side: Close & Upload Buttons */}
                        <div>
                            <CButton color={"light"} onClick={() => {
                                setIsModalOpen(false);
                                setManualPackage(false);
                                }}>{t('adminDashboard.CommonWords.close')}
                            </CButton>
                            <CButton color="primary" className="ms-2"
                                disabled={!selectedFile}
                                onClick={() => {
                                    uploadFileAndCheck();
                                    setIsModalOpen(false);
                                }}>
                                {t('adminDashboard.WebsiteSettings.upload')}
                            </CButton>
                        </div>
                    </CModalFooter>
                    </CModal>
    )
}
export default ManualPackageCreationUploadFile;