import { CFormInput, CSpinner } from "@coreui/react"
import { Table, Column, Plugins, Cell, } from 'fixed-data-table-2';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import KPIComponent from "./AllSoldKPIComponent";
import { debounce } from "lodash";

interface AllSoldProductsProps {
  // calculateKPIs: (products: any) => void;
  productOrderedData: any;
  setProductOrderedData: (value: any) => void;
  windowWidth: number;
  windowHeight: number;
  productKpiData: any;
  kpiLoader: boolean;
};

const AllSoldProducts = (props: AllSoldProductsProps) => {
    const productsColumnTitles: any = useMemo(() => ({
      // your column titles here
      lager_number: "Lager Nr",
      sold_by: 'Sold by',
      brand_name: 'Brand',
      article_number: 'Article',
      supply_id: 'Supply',
      supply_type: "Supply Type",       
      product_group: "Group",
      final_grade: "Grade",
      company: 'Company',
      order_number: "Order",
      created_at: 'Order Date',
      discount_created_at: "Discount date",
      discount_invoice: "Discount invoice",
      invoice_created_at: "Invoice Date",
      invoice_number: "Invoice",
      invoice_paid: "Paid",
      jtl_buying_price: "JTL Buying €",
      portal_buying_price: "Portal Buying €",

      jtl_online_price: "JTL Online €",
      portal_online_price: "Portal Online €",

      jtl_selling_price: "JTL Selling €",
      portal_selling_price: "Portal Selling €",

      price_percent: "Price Percent %",
      profit: "Profit €",
      // turnover: 'Turnover €',
      // turnover_ratio: 'Turnover Ratio',
    }), []);
    const {productOrderedData, windowWidth, windowHeight, productKpiData, kpiLoader} = props;
    const [searchTexts, setSearchTexts] = useState<any>({});
    const [columnWidths, setColumnWidth] = useState<any> ({
      article_number: 220,
      brand_name: 100,
      company: 140,
      created_at: 140,
    })

    useEffect(() => {
      let defaultSearchValues: any = {};
      Object.keys(productsColumnTitles).map((columnKey: any) => {
        defaultSearchValues[columnKey] = '';
      })
      setSearchTexts(defaultSearchValues);
    },[])
    
    const onColumnResizeEndCallback = useCallback((newColumnWidth: number, columnKey: string) => {
      setColumnWidth((prevWidths: any) => ({
        ...prevWidths,
        [columnKey]: newColumnWidth
      }));
    }, []);
    
      // Function to filter data based on search texts
    const getFilteredData = () => {
      return productOrderedData.filter((row: any) => {
        return Object.keys(productsColumnTitles).every(columnKey => {
          // If no search text is entered for this column, include all data
          if (!searchTexts[columnKey]) return true;
          // If data exists and includes the search text, include it in the filtered results
          return row[columnKey]?.toString().toLowerCase().includes(searchTexts[columnKey].toLowerCase());
        });
      });
    };

    const handleSearchChange = debounce((columnKey, value) => {
      setSearchTexts((prevTexts: any) => ({
          ...prevTexts,
          [columnKey]: value
      }));
    }, 150); // Adjust debounce time as needed
  
    // Use this filtered data for rendering in the table
    const filteredData = getFilteredData();
    const showSign = (columnKey: string) => {
      if (columnKey == "jtl_buying_price" || columnKey == "portal_buying_price" || columnKey == "jtl_online_price"
        || columnKey == "portal_online_price" || columnKey == "jtl_selling_price" || columnKey == "portal_selling_price"
        || columnKey == "profit"
      ) 
      {
        return  "€"
      }
      else if(columnKey == "price_percent" ) {
        return  "%"
      }
      else {
        return ""
      }
    }
   
    return (
      <div>
         <KPIComponent products={filteredData} />
        <Table
          rowHeight={45}
          headerHeight={90}
          rowsCount={filteredData?.length}
          className='all-sold-table margin-top-24'
          width={(windowWidth * 0.96) + 13}
          maxHeight={windowHeight * 0.8}
          touchScrollEnabled={true}
        >
          {
            Object.keys(productsColumnTitles).map((columnKey: any) => {
                return (
                <Column
                    columnKey={columnKey}
                    header={
                        <Plugins.ResizeCell
                        className='elv-btn text-center'
                        onColumnResizeEnd={onColumnResizeEndCallback}
                        >
                        {productsColumnTitles[columnKey]}
                        <CFormInput
                            value={searchTexts[columnKey]}
                            size='sm'
                            placeholder={`Search ${productsColumnTitles[columnKey]}`}
                           
                            onChange={(e) => handleSearchChange(columnKey, e.target.value)}
                        />

                        
                        </Plugins.ResizeCell>
                    }
                    cell={({ rowIndex }) => <Cell>
                      {
                        filteredData[rowIndex][columnKey] && columnKey == 'price_percent' ?
                         `${filteredData[rowIndex][columnKey] * 100} ${showSign(columnKey)}`
                        :
                      filteredData[rowIndex][columnKey] ?
                        `${filteredData[rowIndex][columnKey]} ${showSign(columnKey)}` 
                      :
                       ""}</Cell>}
                    width={columnWidths[columnKey] || 150}
                />
                )
            })
          }
        </Table>
      </div>
    )
};

export default AllSoldProducts;
