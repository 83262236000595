import { CForm, CRow, CCol, CFormLabel, CFormInput, CButton, CTooltip, CWidgetStatsF, CToaster} from "@coreui/react";
import { Cart,  ProductItem, ProductSubCategory, Supply, SupplyType, filterRequestObject, DropDownItem, Pallet } from "../../../types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {setPackageProducts, setSetArticles, setSelectedProducts, setSelectedSetArticles, setPallets, setSelectedPallets, setCart} from '../../../redux/slices/PackageSlice';
import { ELV_BLUE, FONT_15 } from "../../../constants";
import { getFilteredSingleProducts, getProductsByUploadingFile, getTypeProductSupplies } from "../../../services/ProductService";
import { t } from "i18next";
import Select, {components} from 'react-select';
const { Group } = components;
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { filterPallets } from "../../../services/PalletService";
import { modifyPackage } from "../../../services/PackageService";
import { Utils } from "../utils";
import { cilClock } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { GenericInfoToast } from "./ToastsComponent";
import ManualPackageCreationErrorModal from "../modals/ManualPackageCreationErrorModal";
import ManualPackageCreationUploadFile from "../modals/ManualPackageCreationUploadFile";
import WarningConfirmationModal from '../../components/modals/DeleteItemModal';
import { setSupplyTypes } from "../../../redux/slices/ProductSlice";


interface PackageSearchProps {    
    selectedCustomer: any;
    setSelectedCustomer: (value: any) => void;
    editPackage?: boolean;
    selectedTab: 0 | 1 | 2 | 3;
    setLoading?: (value: boolean) => void;   
    setSelectAll?: (value: boolean) => void;
    setSmartPairLoading: (value: boolean) =>void;
    packageStatus: string;
    current_package_id?: number;
    showCustomerToast?: () => void;
    setShowRefreshButton?: (value: boolean) => void;
   
}
const PackageSearchComponent = (props: PackageSearchProps) => {
    const {selectedTab, selectedCustomer, setSelectedCustomer, editPackage, showCustomerToast,
        setLoading, setSelectAll, setSmartPairLoading, packageStatus, current_package_id, setShowRefreshButton
    } = props;
    const dispatch = useDispatch();
    const {getPendingPackagesData, getAllProductSupplies, getPackagesData} = Utils();
    
    const supplyTypes = useSelector((state: RootState) => state.productSlice.supplyTypes);
    const customers = useSelector((state: RootState) => state.usersSlice.customers);
    const productSubCategories = useSelector((state: RootState) => state.productSlice.productSubCategories);

    const customerOptions: DropDownItem[] = customers.filter((customerItem) => customerItem.is_active ).map((customer) => ({ value: customer.id, label: customer.company }));   
    const [subCategoryOptions, setSubCategoriesOptions] = useState<DropDownItem[]>(productSubCategories.map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name })));
  
    const supplyTypeOptions: DropDownItem[]= (supplyTypes.map((type: SupplyType) => ({ value: type.name, label: type.name })));
    const [supplyOptions, setSupplyOptions] = useState<any>([]); // value represents supply type and label is suply id.

    // purpose of providing supply type in the value field above, is that we have to render supplies according to the type selected.
    const [currentSupplyValues, setCurrentSupplyValues] = useState<DropDownItem[]>([]);
    const [currentSupplyTypeValues, setCurrentSupplyTypeValues] = useState<DropDownItem[]>([]);
    const cart: Cart = useSelector((state: RootState) => state.packageSlice.cart);
    const products: ProductItem[] = useSelector((state: RootState) => state.packageSlice.products);
    const pallets: Pallet[] = useSelector((state: RootState) => state.packageSlice.pallets);
    const setArticles: ProductItem[] = useSelector((state: RootState) => state.packageSlice.setArticles);
    const selectedSetArticles: number[] = useSelector((state: RootState) => state.packageSlice.selectedSetArticles);
    const selectedProducts: number[] = useSelector((state: RootState) => state.packageSlice.selectedProducts);
    const selectedPallets: any = useSelector((state: RootState) => state.packageSlice.selectedPallets);

    // Selected variables array below.
    const [currentBrandList, setCurrentBrandList] = useState<number[]>([]); 
    const [currentGroupList, setCurrentGroupList] = useState<number[]>([]);
    const [currentSubGroupList, setCurrentSubGroupList] = useState<number[]>([]);
    const [currentSupplyList, setCurrentSupplyList] = useState<string[]>([]);
    const [currentSupplyTypeList, setCurrentSupplyTypeList] = useState<string[]>([]);
    const [supplyLoading, setSupplyLoading] = useState<boolean>(false);
    const [isRestSupply, setIsRestSupply] = useState<boolean>(false);

    const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date("2021-05-01"));
    const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date());
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(packageStatus === 'F');
    const [manualPackage, setManualPackage] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMissing, setIsMissing] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState<boolean>(false);
    const [isMissingModalOpen, setIsMissingModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isMissingLager, setIsMissingLager] = useState<string[]>([]);
    const [isSoldProduct, setIsSoldProduct] = useState<string[]>([]);
    const [isSoldPallet, setIsSoldPallet] = useState<string[]>([]);
    const [isDuplicateIds, setIsDuplicateIds] = useState<string[]>([]);
    const [isBlockedIds, setIsBlockedIds] = useState<string[]>([]);
    const [toast, addToast] = useState<any>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setSelectedFile(event.dataTransfer.files[0]);
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        // product template here.
        link.href = require('../../../assets/templates/lager_number_upload_template.xlsx');
        link.setAttribute('download', 'upload-lager-template.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Handle file removal
    const handleRemoveFile = () => {
        setSelectedFile(null);
        (document.getElementById("fileInput") as HTMLInputElement).value = ""; // Reset file input
    };

    useEffect(() => {
        setIsButtonDisabled(packageStatus === 'F')
    }, [packageStatus]);

    const fetchProductsButton = <CButton
        className='elv-btn'
        variant="outline"
        shape="square"
        style={{marginTop: 24}}
        disabled={(isButtonDisabled) || (currentSupplyList?.length === 0 && currentSubGroupList?.length === 0 && currentBrandList?.length === 0 && selectedTab !== 2 && isRestSupply === false)}
        onClick={()=>{
            getProducts();
            setSmartPairLoading(false);
        }}
    >{t('adminDashboard.Packages.fetch_products_button_text')}
    </CButton>
    // Search Text variables below.
    // Following hook keeps track, whenever supply type changes it changes the supply IDs accordingly.
    useEffect(() =>{
        if(currentSupplyTypeList.length > 0) {
            if(!supplyOptions.some((option: any) => {return option.label ===currentSupplyTypeList[currentSupplyTypeList.length - 1]})) {
                // If last added items exists in supplyOptions it means user remove a type from filters.

                setSupplyLoading(true);
                getTypeProductSupplies(currentSupplyTypeList[currentSupplyTypeList.length - 1]).then((response) => {
                    filterSupplies(currentSupplyTypeList[currentSupplyTypeList.length - 1], response.data);
                }).catch((e) => {
                    setSupplyLoading(false);
                });
            }
            else {
                // Filter supplyOptions here and remove the supply ids for whose type is being removed.
                let updatedSupplyOptions: any[] = []
                currentSupplyTypeList.map((type: string, index: number) => {
                   updatedSupplyOptions =[...updatedSupplyOptions, ...supplyOptions.filter((item: any) =>{return item.label === type})]
                })
                setSupplyOptions(updatedSupplyOptions);
            }
        }
        else {
            setSupplyOptions([]);
        }       
    },[currentSupplyTypeList]);

    // Following hook keeps track, whenever category changes it changes the sub categories accordingly.
    useEffect(() =>{
        if(currentGroupList.length > 0) {
            filterSubCategories();
        }
        else {
            setSubCategoriesOptions(productSubCategories.map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name })));
        }       
    },[currentGroupList]);

    useEffect(() => {
        setSubCategoriesOptions(
            productSubCategories.map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }))
        )
    },[productSubCategories]);

    useEffect(() =>{
        if(currentSupplyTypeValues.length > 0) {
            setIsMissing(false);
        }
    },[currentSupplyTypeValues]);

    // Filter supply IDs according to the type selected.
    const filterSupplies = (supplyType: string, filteredProductSupplies: Supply[]) => {
        // setSupplyOptions([...supplyOptions, {value: supplyType, label: `-- ${supplyType} Select_All (${filteredProductSupplies.length})`}, ...filteredProductSupplies.map((supply: Supply) => ({ value: supply.id, label: `${supply.id} (${supply.available_products_count})` }))]);
        setSupplyOptions([...supplyOptions, {label: supplyType, options: [...filteredProductSupplies.map((supply: Supply) => ({ value: supply.id, label: `${supply.id} (${supply.available_products_count})` }))]}]);
        setSupplyLoading(false);
    }

    // Filter Sub Categories according to the Category selected.
    const filterSubCategories = () => {
        let updatedProductSubCategories: ProductSubCategory[] = []
        updatedProductSubCategories = productSubCategories.filter((subCategory: ProductSubCategory) => {
          return currentGroupList.includes(subCategory.category)
        });

        setSubCategoriesOptions(updatedProductSubCategories.map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name })));
    }

    const getStandaloneProducts = (requestBody: filterRequestObject) => {
        getFilteredSingleProducts(requestBody)
        .then((response) => {
            if(editPackage) {
                dispatch(setSelectedProducts(selectedProducts));
                let alreadySelectedProducts = products.filter((item: ProductItem) => {
                    // First filter out the already selected products from the current list.
                    return (selectedProducts.includes(item.id));
                });
                let unselectedProducts = response.data.filter((item: ProductItem) => {
                    // Secondly filter out the remaining unselected products from the new data from the server.
                    return (!selectedProducts.includes(item.id));
                });
                setLoading && setLoading(false);
                // Now Merge these 2 arrays. 
                // This feature is for editing the package so that the selected choices are not lost for the salesmen.
                dispatch(setPackageProducts([...alreadySelectedProducts, ...unselectedProducts]));
            }
            else {
                let productIds: number[] = [];
                response.data.map((item: ProductItem) => {
                    productIds.push(item.id)
                });
                dispatch(setPackageProducts(response.data));
                dispatch(setSelectedProducts(productIds))
                dispatch(setCart({
                    ...cart,
                    standalone: productIds,
                }));
                setLoading && setLoading(false);
                setSelectAll && setSelectAll(true);
                // getSetArticles({...requestBody, is_setarticle: true});
            }
            setShowRefreshButton && setShowRefreshButton(true);
        })
        .catch((error) => {
            setLoading && setLoading(false);
        });
    }

    const getPallets = () => {
        filterPallets().then((response: {data: Pallet[]}) => {
            if(editPackage) {
                dispatch(setSelectedPallets(selectedPallets));
                let alreadySelectedPallets = pallets.filter((item: Pallet) => {
                    // First filter out the already selected pallets from the current list.
                    return (selectedPallets.includes(item.id));
                });
                let unselectedPallets = response.data.filter((item: Pallet) => {
                    // Secondly filter out the remaining unselected pallets from the new data from the server.
                    return (!selectedPallets.includes(item.id));
                });
                setLoading && setLoading(false);
                // Now Merge these 2 arrays. 
                // This feature is for editing the package so that the selected choices are not lost for the salesmen.
                dispatch(setPallets([...alreadySelectedPallets, ...unselectedPallets]));
            }
            else {
                setLoading && setLoading(false);
                dispatch(setPallets(response.data))
            }
            
        }).catch((e) => {
            setLoading && setLoading(false);
            dispatch(setPallets([]))
        });
    }

    const getSetArticles = (requestBody: filterRequestObject) => {
        setLoading && setLoading(true);
        getFilteredSingleProducts(requestBody)
        .then((response) => {
            if(editPackage) { // If the package is in edit state.
                dispatch(setSelectedSetArticles(selectedSetArticles));
                let alreadSelectedSetArticles = setArticles.filter((item: ProductItem) => {
                    // First filter out the already selected articles from the current list.
                    return (selectedSetArticles.includes(item.id));
                });
                let unselectedSetArticles = response.data.filter((item: ProductItem) => {
                    // Secondly filter out the remaining unselected articles from the new data from the server.
                    return (!selectedSetArticles.includes(item.id));
                });
                setLoading && setLoading(false);
                // Now Merge these 2 arrays. 
                // This feature is for editing the package so that the selected choices are not lost for the salesmen.
                dispatch(setSetArticles([...alreadSelectedSetArticles, ...unselectedSetArticles]));
            }
            else {
                // If the new package creation is in process.
                dispatch(setSetArticles(response.data));
                dispatch(setCart({
                    ...cart,
                    unpaired: [],
                    paired: [],
                }));
                dispatch(setSelectedSetArticles([]));
                setLoading && setLoading(false);
            }
        })
        .catch((error) => {
            setLoading && setLoading(false);
        });
    }
    const getProducts = () => {
        setLoading && setLoading(true);
        // Now this condition would always be true due to the min and max grade limits.
        const filterObject = {
            supply: isRestSupply ? undefined : currentSupplyList.length > 0 ? currentSupplyList : undefined,
            sub_category: currentSubGroupList.length > 0 ? currentSubGroupList : undefined,
            date_from: selectedDateFrom.toISOString().slice(0, 10),
            date_to: selectedDateTo.toISOString().slice(0, 10),
            package__isnull: true, //  This is to get only the available products.
            is_deleted: false,
            // is_setarticle: selectedTab === 1,
            package_snapshots__isnull: true,  //  This is to ignore the reserve products as well if there is any in any snapshot.
            supply__isnull: false,
            supply__is_allowed: true,
            is_rest_supply: isRestSupply ? isRestSupply : undefined
        };
        if(selectedTab === 0) {
            // fetch only products.
            getStandaloneProducts({...filterObject, is_setarticle: false});
        }
        else if(selectedTab === 1){// fetch only set articles.
            getSetArticles({...filterObject, is_setarticle: true});
        }
        else if (selectedTab === 2) {
            getPallets();
        }
    };

    const getProductsByFile = () => {
        setLoading && setLoading(true);
        let productIds: number[] = [];
        if (
            selectedFile && 
            (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
             selectedFile.type === 'application/vnd.ms-excel' || 
             selectedFile.type === 'text/csv')
          ) {
            const formData = new FormData();
            formData.append("file", selectedFile);
            getProductsByUploadingFile(formData)
                .then((response) => {
                    // Extract Data Safely
                    const product = (response.data as { products?: ProductItem[] })["products"] ?? [];
                    const set_articles = (response.data as { set_articles?: ProductItem[] })["set_articles"] ?? [];
                    const pallet = (response.data as { pallets?: Pallet[] })["pallets"] ?? [];
                    const sold_products = (response.data as { sold_products?: string[] })["sold_products"] ?? [];
                    const sold_pallets = (response.data as { sold_pallets?: string[] })["sold_pallets"] ?? [];
                    const invalid_ids = (response.data as { invalid_ids?: string[] })["invalid_ids"] ?? [];
                    const duplicates_id = (response.data as { duplicates?: string[] })["duplicates"] ?? [];
                    const blocked_id = (response.data as { blocked?: string[] })["blocked"] ?? [];
                    // Handle Normal Products (selectedTab === 0)
                    if(product.length <= 0){
                        setManualPackage(false);
                    }
                    if (product.length > 0) {
                        if (editPackage) {
                            dispatch(setSelectedProducts(selectedProducts));
                            let alreadySelectedProducts = products.filter((item: ProductItem) => {
                                // First filter out the already selected products from the current list.
                                return (selectedProducts.includes(item.id));
                            });
                            let unselectedProducts = product.filter((item: ProductItem) => {
                                // Secondly filter out the remaining unselected products from the new data from the server.
                                return (!selectedProducts.includes(item.id));
                            });
                            setLoading && setLoading(false);
                            // Now Merge these 2 arrays. 
                            // This feature is for editing the package so that the selected choices are not lost for the salesmen.
                            dispatch(setPackageProducts([...alreadySelectedProducts, ...unselectedProducts]));
                        } else {
                            product.map((item: ProductItem) => {
                                productIds.push(item.id)
                            });
                            dispatch(setPackageProducts(product));
                            dispatch(setSelectedProducts(productIds));
                            dispatch(setCart({
                                ...cart,
                                standalone: productIds,
                                unpaired: [],
                                paired: []
                            }));
                            console.log(productIds);
                        }
                    }
                    // Handle Set Articles (selectedTab === 2)
                    if (set_articles.length > 0) {
                        if (editPackage) {
                            dispatch(setSelectedSetArticles(selectedSetArticles));
    
                            let alreadySelectedSetArticles = setArticles.filter((item: ProductItem) => selectedSetArticles.includes(item.id));
                            let unselectedSetArticles = set_articles.filter((item: ProductItem) => !selectedSetArticles.includes(item.id));
    
                            if (unselectedSetArticles.length > 0) {
                                dispatch(setSetArticles([...alreadySelectedSetArticles, ...unselectedSetArticles]));
                            }
                        } else {
                            dispatch(setSetArticles(set_articles));
                            dispatch(setSelectedSetArticles([]));
                        }
                    }
                    // Handle Pallets
                    if (pallet && pallet.length > 0) {
                        if (editPackage) {
                            dispatch(setSelectedPallets(selectedPallets));
    
                            let alreadySelectedPallets = pallets.filter((item: Pallet) => selectedPallets.includes(item.id));
                            let unselectedPallets = pallet.filter((item: Pallet) => !selectedPallets.includes(item.id));
    
                            if (unselectedPallets.length > 0) {
                                dispatch(setPallets([...alreadySelectedPallets, ...unselectedPallets]));
                            }
                        } else {
                            dispatch(setPallets(pallet));
                        }
                    }
                    // Handle Missing Lager Numbers
                    if (sold_products && sold_products.length > 0) {
                        setIsSoldProduct(sold_products);
                        setIsMissing(true);
                    }
                    if (sold_pallets && sold_pallets.length > 0) {
                        setIsSoldPallet(sold_pallets);
                        setIsMissing(true);
                    }
                    if (invalid_ids && invalid_ids.length > 0) {
                        setIsMissingLager(invalid_ids);
                        setIsMissing(true);
                    }
                    if (duplicates_id && duplicates_id.length > 0) {
                        setIsDuplicateIds(duplicates_id); 
                        setIsMissing(true);  
                    }
                    if (blocked_id && blocked_id.length > 0) {
                        setIsBlockedIds(blocked_id); 
                        setIsMissing(true); 
                    }
                    setShowRefreshButton && setShowRefreshButton(true);
                    setLoading && setLoading(false);
                    setSelectedFile(null);
                })
                .catch((error) => {
                    setLoading && setLoading(false);
                    const errorMessage = error?.response?.data?.error || "An unexpected error occurred. Please try again.";
                    setSelectedFile(null);
                    // Display error in toast
                    addToast(GenericInfoToast(() => addToast(null), errorMessage));
                    setManualPackage(false);
                });
        } else {
            // Show error if no valid file is selected
            addToast(GenericInfoToast(() => addToast(null), `${t('adminDashboard.Packages.invalid_file_format')}`));
            setSelectedFile(null);
            setLoading && setLoading(false);
            setManualPackage(false);
        }
    };

    const uploadFileAndCheck = () => {
        if (selectedFile) {
            emptyWarningFields();
            setIsButtonDisabled(false);
            getProductsByFile();
        }
    }


      
    const warehouseOptions = [
        { value: 'AMM', label: 'AMM' }
    ];
    const handleGroupClick = (props: any) => {
        setCurrentSupplyList(
            [...currentSupplyList,
            ...props?.data?.options.map((option: any) => (option.value))]
        )
        setCurrentSupplyValues([ ...currentSupplyValues, ...props?.data?.options]);
    };
    const GroupOption = (props: any) => {
        return (
          <Group {...props}>
                <div className={"supply-select-all"} onClick={() => handleGroupClick(props)}>Select All ({props.options.length})</div>
                {props.children}
          </Group>
        );
      };
    const editPackageCustomer = (customer_id: number) => {
        if(current_package_id) {
            let productIds: any = [];
            productIds = [...cart.standalone, ...cart.unpaired, ...cart.pallets];
            cart?.paired.map((item)=>{
                productIds =  [...productIds, ...item.set_articles];
            });
            let formData = {
                customer: customer_id,
                products: productIds,
            }
            modifyPackage(current_package_id, formData).then((response) => {
                getPackagesData();
                getPendingPackagesData();
                getAllProductSupplies();
            }).catch((e) => {
                
            })
        }
    }

    const makeDropDownValuesEmpty = () => {
        setCurrentSupplyValues([]);
        setCurrentSupplyList([]);
        setCurrentSupplyTypeList([]);
        setCurrentSupplyTypeValues([]);
    }

    const emptyWarningFields = () => {
        setIsSoldProduct([]);
        setIsMissingLager([]);
        setIsSoldPallet([]);
        setIsDuplicateIds([]);
        setIsBlockedIds([]);
    }

    const customersDropDownList = <Select
        value={{value: selectedCustomer?.id, label: selectedCustomer?.company}}
        placeholder={`${t('adminDashboard.CommonWords.select')}`}
        options={customerOptions} 
        onChange={(option: any) => {
            editPackageCustomer(option?.value)
            setSelectedCustomer({id: option?.value, company: option?.label});
            showCustomerToast && showCustomerToast();
        }}
        isDisabled={packageStatus == "F"}
    />
    return (
        <CForm>
            {/* Modal Popup for Manual Check */}
            <ManualPackageCreationUploadFile
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                manualPackage={manualPackage}
                setManualPackage={setManualPackage}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                handleFileUpload={handleFileUpload}
                handleRemoveFile={handleRemoveFile}
                handleDownload={handleDownload}
                uploadFileAndCheck={uploadFileAndCheck}
            />

            {/* Modal Popup for Missing Products */}
            <ManualPackageCreationErrorModal
                isMissingModalOpen={isMissingModalOpen}
                setIsMissingModalOpen={setIsMissingModalOpen}
                isMissingLager={isMissingLager}
                isSoldProduct={isSoldProduct}
                isSoldPallet={isSoldPallet}
                isDuplicate={isDuplicateIds}
                isBlocked={isBlockedIds}
            />
            <WarningConfirmationModal
                loading={false}
                isModalVisible={warningModal}
                closeModal={()=> {
                    setWarningModal(false);
                }}
                headerText={`${t('adminDashboard.Packages.are_you_sure')}`}
                bodyText={`${t('adminDashboard.CommonWords.will_loose_item_in_cart')}`}
                confirmButtonText={`${t('adminDashboard.CommonWords.yes')}`}
                onClickConfirmation={() => {
                    setManualPackage(!manualPackage);
                    setWarningModal(false);
                    // Remove items loaded from Manual Package creation thing
                    if(!editPackage){
                        dispatch(setPackageProducts([]));
                        dispatch(setPallets([]));
                        dispatch(setSetArticles([]));
                        dispatch(setCart({
                            ...cart,
                            standalone: [],
                            unpaired: [],
                            paired: []
                        }));
                    }
                    setIsMissing(false);
                }}
            />
            <CToaster push={toast} placement="top-end" />
            {(isMissing) ? (
                <CRow>
                    <CCol>
                        <CWidgetStatsF
                            color="danger"
                            icon={<CIcon icon={cilClock} height={20} />}
                            title={t('adminDashboard.Packages.show_lager_number')}
                            value={t('adminDashboard.Packages.some_lager_number_missing')}
                            onClick={() => setIsMissingModalOpen(!isMissingModalOpen)}
                            className="pointer"
                        />
                    </CCol>
                </CRow>
            ) : null}
            <CRow>
                <CCol xs={12} className='padding-top-16'>
                    <CFormLabel><b>{t('adminDashboard.Packages.warehouse_text')}*</b></CFormLabel>
                    <Select
                        value={warehouseOptions[0]}
                        options={warehouseOptions}
                        isDisabled={true}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={12} className='padding-top-16'>
                    <CFormLabel><b>{t('adminDashboard.Packages.customer_text')}*</b></CFormLabel>
                    {
                        editPackage && packageStatus == "F" ?
                            <CFormInput
                                type={"text"}
                                placeholder='Customer details'
                                value={selectedCustomer?.company}
                                style={{marginBottom: 16, marginTop: 24}}
                            />
                        : 
                            editPackage && packageStatus != "F" ?  
                            customersDropDownList
                        :
                            <Select
                                placeholder={t('adminDashboard.CommonWords.select')}
                                options={customerOptions}
                                onChange={(option) => setSelectedCustomer({id: option?.value, company: option?.label})}
                            />
                     }
                </CCol>
            </CRow>
            {
                selectedTab !== 2 &&
                <>
                    {/* Manual Checkbox */}
                    <CRow>
                        <CCol xs={12} className="padding-top-16">
                            <CFormLabel className="pointer">
                                <input 
                                    className="form-check-input pointer" 
                                    type="checkbox" 
                                    checked={manualPackage} 
                                    onChange={() => {
                                        if (manualPackage && cart.standalone.length > 0) {
                                            setWarningModal(true);
                                            setIsModalOpen(!manualPackage);
                                            makeDropDownValuesEmpty();
                                        }
                                        else {
                                            setManualPackage(!manualPackage);
                                            setIsModalOpen(!manualPackage);
                                            setIsMissing(false);
                                            makeDropDownValuesEmpty();
                                        }
                                    }} 
                                /> 
                                <b> {t('adminDashboard.Packages.manual_package_creation')}</b>
                            </CFormLabel>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol xs={12} className='padding-top-16'>
                            <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_type')}*</b></CFormLabel>
                            <Select
                                value={currentSupplyTypeValues}
                                closeMenuOnSelect={false}
                                isMulti
                                placeholder={t('adminDashboard.CommonWords.select')}
                                noOptionsMessage={({ inputValue: string }) => 'Supply-type not found.' }
                                options={supplyTypeOptions}
                                onChange={(options: any) => {
                                    setCurrentSupplyTypeList(
                                        options.map((option: any) => (option.value))
                                    )
                                    setCurrentSupplyTypeValues(options)
                                }}
                                isDisabled={isRestSupply || manualPackage}
                                styles={{
                                    multiValueLabel: (base) => ({
                                      ...base,
                                      backgroundColor: ELV_BLUE,
                                      color: 'white',
                                      fontSize: FONT_15,
                                    }),
                                }}
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs={12} className='padding-top-16'>
                            <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_id')}*</b></CFormLabel>
                            <Select
                                placeholder={t('adminDashboard.CommonWords.select')}
                                closeMenuOnSelect={false}
                                value={currentSupplyValues}
                                isMulti
                                noOptionsMessage={({ inputValue: string }) => 'Supply not found.' }
                                options={supplyOptions}
                                onChange={(options: any) => {
                                    setCurrentSupplyList(
                                        options.map((option: any) => (option.value))
                                    )
                                    setCurrentSupplyValues(options);
                                }}
                                isLoading={supplyLoading}
                                isDisabled={isRestSupply || manualPackage}
                                components={{ Group: GroupOption }}
                                styles={{
                                    multiValueLabel: (base) => ({
                                      ...base,
                                      backgroundColor: ELV_BLUE,
                                      color: 'white',
                                      fontSize: FONT_15,
                                    }),
                                }}
                            />
                        </CCol>
                        <CCol xs={12} className='padding-top-8'>
                            <CFormLabel><input className="form-check-input pointer" type="checkbox" checked={isRestSupply} disabled={manualPackage} onClick={()=>setIsRestSupply(!isRestSupply)}/> <b>{t('adminDashboard.Packages.fetch_from_rest')}</b></CFormLabel>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol xs={12} className='padding-top-16'>
                            <CFormLabel><b>{t('adminDashboard.Products.category')}</b></CFormLabel>
                            {/* This used to be a sub cat, but it should be renamed according to the current portal, as asked by Boris.*/}
                            <Select
                                placeholder={t('adminDashboard.CommonWords.select')}
                                closeMenuOnSelect={false}
                                isMulti
                                options={subCategoryOptions}
                                isDisabled={manualPackage}
                                onChange={(options: any) => {
                                    setCurrentSubGroupList(
                                        options.map((option: any) => (option.value))
                                    )
                                }}
                                styles={{
                                    multiValueLabel: (base) => ({
                                      ...base,
                                      backgroundColor: ELV_BLUE,
                                      color: 'white',
                                      fontSize: FONT_15,
                                    }),
                                }}
                            />
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol xs={12} md={10} className='padding-top-16'>
                            <CFormLabel><b>{t('adminDashboard.Packages.date_from')}</b></CFormLabel>
                            <div>
                                <DatePicker
                                    selected={selectedDateFrom}
                                    onChange={(date: Date) => setSelectedDateFrom(date)}                        
                                    dateFormat="yyyy-MM-dd"
                                    className="datepicker pointer"
                                    disabled={manualPackage}
                                />
                            </div>
                        </CCol>
                        <CCol xs={12} md={10} className='padding-top-16'>
                            <CFormLabel><b>{t('adminDashboard.Packages.date_to')}</b></CFormLabel>
                            <div>
                                <DatePicker
                                    minDate={selectedDateFrom}
                                    selected={selectedDateTo}
                                    onChange={(date: Date) => setSelectedDateTo(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="datepicker pointer"
                                    disabled={manualPackage}
                                />
                            </div>
                        </CCol>
                    </CRow>
                </>
            }

            {currentSupplyList.length === 0 && selectedTab !== 2 && <h6 className="margin-top-24">{t('adminDashboard.Packages.supply_id_please')}</h6>}
            {
                isButtonDisabled ? (
                <CTooltip content={t('adminDashboard.Packages.fetch_products_button_edit_package_placed_order_message')} placement='top'>
                    <div style={{ display: 'inline-block' }}>
                        {fetchProductsButton}
                    </div>
                </CTooltip>
                ) : (
                    fetchProductsButton
                )
            }
            
        </CForm>
    )
};

export default PackageSearchComponent;