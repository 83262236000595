import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import { CContainer, CHeader, CNav, CNavItem, CNavLink, CSpinner } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../src/scss/style.scss';
import '../CustomerStyles.css';
import { getRefreshToken, getAuthUser } from "../../services/AuthService";
import { setLoggedinUser } from '../../redux/slices/AuthSlice';
import {setSelectedTab} from '../../redux/slices/CustomerTotalOrdersSlice' ;
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Utils } from '../../admin/components/utils';

function CustomerHomeScreen() {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {logout} = Utils();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const selectedTab = useSelector((state: RootState) => state.CustomerTotalOrdersSlice.selectedTab);

    useEffect(() => {
        setInterval(() => {
            getRefreshToken().then((response) => {
            }).catch((e) => {
                logout();
            });
        }, 100000);
        getAuthUser().then((response) => {
            dispatch(setLoggedinUser(response.data));
            i18n.changeLanguage(response?.data?.preferred_language);
        }).catch((e) => {
            logout();
            // console.log('érror: ', e);
        });
    }, []);

    const dashboardClickHandler = () => {
        dispatch(setSelectedTab(0));
        navigation('/customer/dashboard/summary')
    }

    const packageClickHandler = () => {
        dispatch(setSelectedTab(1));
        navigation('/customer/packages/summary')
    }
    const orderClickHandler = () => {
        dispatch(setSelectedTab(2));
        navigation('/customer/orders/summary')
    }

    const settingsClickHandler = () => {
        dispatch(setSelectedTab(3));
        navigation('/customer/settings')
    }
    
    return (
        <div>
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                {
                    location.pathname !== '/customer/terms-conditions' ?
                        <MainHeader />
                    : null
                }
                
                <div className="body flex-grow-1 zero-margin" style={{ width: '100%' }}>
                    <CContainer className='max-width' lg style={{padding: 0}}>
                        <Suspense fallback={<CSpinner color="primary" />}>
                            <div className={isMobile ? 'website-settings-mobile-container' : 'website-settings-main-container'}>
                                {
                                    location.pathname !== '/customer/terms-conditions' ?
                                        <CHeader>
                                            <CNav variant="tabs">
                                                <CNavItem className='pointer'>
                                                    <CNavLink onClick={dashboardClickHandler} active={selectedTab === 0 ? true : false}>
                                                       {t('adminDashboard.Dashboard.sidebar_text')}
                                                    </CNavLink>
                                                </CNavItem>
                                                <CNavItem className='margin-left-12 pointer'>
                                                    <CNavLink onClick={packageClickHandler} active={selectedTab === 1 ? true : false}>
                                                        {t('adminDashboard.Packages.sidebar_text')}
                                                    </CNavLink>
                                                </CNavItem>
                                                <CNavItem className='margin-left-12 pointer'>
                                                    <CNavLink onClick={orderClickHandler} active={selectedTab === 2 ? true : false}>{t('adminDashboard.OrderRequests.sidebar_text')}</CNavLink>
                                                </CNavItem>
                                                <CNavItem className='margin-left-12 pointer'>
                                                    <CNavLink onClick={settingsClickHandler} active={selectedTab === 3 ? true : false}>{t('adminDashboard.WebsiteSettings.settings')}</CNavLink>
                                                </CNavItem>
                                            </CNav>
                                        </CHeader>
                                    : null 
                                }

                                <Outlet />
                            </div>
                        </Suspense>
                    </CContainer>
                </div>
            </div>
        </div>
    );
}

export default CustomerHomeScreen;
