import axios from "axios";
import { ANALYTICS_URL } from "../constants";

export const getAnalyticsData = async(form_data: any) => {
    return await axios.post(
        ANALYTICS_URL + 'fetch_ordered_data',
        form_data
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getProductsKPIData = async(form_data: any) => {
    return await axios.post(
        ANALYTICS_URL + 'full_ordered_products_metrics',
        form_data
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getPalletsKPIData = async(form_data: any) => {
    return await axios.post(
        ANALYTICS_URL + 'full_ordered_pallets_metrics',
        form_data
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getProductQualityAnalysis = async(form_data: any) => {
    return await axios.post(
        ANALYTICS_URL + 'product_quality_analysis',
        form_data
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getSupplyPricingAnalysis = async(form_data: any) => {
    return await axios.post(
        ANALYTICS_URL + 'supply_type_analysis',
        form_data
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getStockAnalysis = async () => {
    return await axios.get(
        ANALYTICS_URL + 'Stock_Analysis',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};

export const getUserScanningTime = async() => {
    return await axios.get(
        ANALYTICS_URL + 'user_scanning_time',
    )
    .then((response) => {
        return response;
    })
    .catch(function (error) {
        console.log('failure: ', error);
        throw error;
    });
};