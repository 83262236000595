import { cilCheck, cilPen } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCard, CCardBody, CCardTitle, CCardText, CButton, CBadge, CRow, CCol } from "@coreui/react";
import { Cart, ProductItem } from "../../../types";
import Divider from "../divider";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Utils } from "../utils";
import { FONT_15 } from "../../../constants";

interface ProductCardComponentProps {
    edit?: boolean;
    cardOnClick: () => void;
    selectedProducts: number[];
    item: ProductItem;
    changeProductPrice: (e: any, id: number, type: string) => void;
    priceInputId: string;
    setSellingPriceState: (e: any, productId: number, selling_price: number) => void;
    priceInputValue: number;
    setPriceInputValue: (value: number) => void;
    setPriceInputId: (value: string) => void;
    automaticPairing?: boolean;
    getPairedSetArticlesOptions?: (e: any, item?: ProductItem, callBackFunction?: () => void) =>void;
    isWarehouseModule?: boolean;
    autoPairIndividually?: (e: any, itemId: number) => void;
    selectedTab?: number;
    discountInPercentage?: number;
}
const handleOpenNewTab = (product: ProductItem, url: string) => {
    // Open the link in a new tab using window.open
    const newTab: any = window.open(url, '_blank');
    const serializedProduct = JSON.stringify(product);
    newTab.localStorage.setItem('product', serializedProduct);
};
const ProductCardComponent = (props: ProductCardComponentProps) => {
    const { edit, cardOnClick, selectedProducts, item, priceInputId, setSellingPriceState,
        changeProductPrice, priceInputValue, setPriceInputValue, automaticPairing, setPriceInputId,
        getPairedSetArticlesOptions, isWarehouseModule, autoPairIndividually, selectedTab, discountInPercentage } = props;
        const {getLink} = Utils();
    
    const cart: Cart = isWarehouseModule ? useSelector((state: RootState) => state.warehousePackageSlice.warehouseCart) : useSelector((state: RootState) => state.packageSlice.cart);
    const pairedItem = isWarehouseModule ? cart.paired.filter((paired_item) =>{return paired_item.set_articles.includes(item.id)}) : cart.paired.filter((paired_item) =>{return paired_item.set_articles.includes(item.id)})
    
   
    return (
        <CCard // Parent card component.
            className = {`pointer ${selectedProducts.includes(item.id) ? "selected-card" : 'unselected-card'}`}
            onClick={() => (!item.is_sold || edit == true) && cardOnClick()}
        >
            <CCardBody>
                <CRow>
                    <CCol xs={12} md={8}>
                        <CCardTitle>{item.description}</CCardTitle>
                        <h6>
                            <CCardText className='product-card-info'>
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        display: 'flex',
                                        justifyContent: 'space-between'                           
                                    }}
                                    onClick={(e)=>{
                                        e.stopPropagation();
                                        handleOpenNewTab(item, '/admin/dashboard/product/details')
                                    }}
                                ><b>{t('adminDashboard.Products.product_id')}: {item.id}</b></div>
                                <div><b>{t('adminDashboard.Products.brand')}: {item.brand}</b></div>
                                <div><b>{t('adminDashboard.Products.category')}: {item.sub_category}</b></div>
                                <div><b>{t('adminDashboard.CommonWords.model')}: {item.model}</b></div>
                                <div><b>{t('adminDashboard.SupplyManagement.supply_id')}: {item.supply}</b></div>
                                <div><b>{t('adminDashboard.Products.grade_point')}: {Math.round(Number(item.final_points))}</b></div>
                                <div className="url-decoration">
                                    <b onClick={(e) => {
                                        e.stopPropagation();
                                        const link = item.link ? item.link : getLink(item.model || '');
                                        if (link?.length > 0) {
                                            window.open(link.startsWith('http') ? link : `https://${link}`, '_blank', 'noopener,noreferrer');
                                        }
                                        }}
                                    >
                                        {t('adminDashboard.Products.article')}: {item?.article}
                                    </b></div>
                                <div><b>{t('adminDashboard.Products.usage')}: {item?.usage?.name}</b></div>
                                {item?.metadata?.cooktop_id && <div><b>Kochfelder id: {item?.metadata?.cooktop_id}</b></div>}
                                <div><b>{t('adminDashboard.Products.online_price')}: {item?.is_sold ? item.selling_online_price : item.current_online_price} €</b></div>
                                <Divider color={ selectedProducts.includes(item.id) ? '#ffffff' : "#23B2D9"} thickness={1} margin={12} />
                                {/* <h5>
                                    {
                                        priceInputId.includes(String(item.id)) ?
                                            <div className="input-wrapper margin-top-12">
                                                <div className="input-container">
                                                    <input type={"number"} onClick={(e)=>{e.stopPropagation()}} id={`selling-price-input-${item.id}`} defaultValue={item.selling_price} value={priceInputValue} onChange={(e) => setPriceInputValue(Number(e.target.value))} />
                                                    <CIcon size='xl' icon={cilCheck} onClick={(e) => {priceInputValue == 0 ? alert(" Selling price cannot be 0.") : changeProductPrice(e, item.id, selectedTab === 1 ? 'set-articles' : 'products')}}/>
                                                </div>
                                            
                                            </div>
                                        :
                                            <div className="margin-top-12"><b>{t('adminDashboard.Products.selling_price')}: {  Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.selling_price) } € </b> {(!item.is_sold) ? <CIcon icon={cilPen} size='lg' onClick={(e) => setSellingPriceState(e, item.id, item.selling_price)} /> : null }</div>
                                    }
                                </h5> */}
                                <h5>
                                {
                                    (!discountInPercentage || discountInPercentage === 0) ? (
                                    priceInputId.includes(String(item.id)) ?
                                        <div className="input-wrapper margin-top-12">
                                        <div className="input-container">
                                            <input
                                            type="number"
                                            onClick={(e) => { e.stopPropagation() }}
                                            id={`selling-price-input-${item.id}`}
                                            defaultValue={item.selling_price}
                                            value={priceInputValue}
                                            onChange={(e) => setPriceInputValue(Number(e.target.value))}
                                            />
                                            <CIcon
                                            size='xl'
                                            icon={cilCheck}
                                            onClick={(e) => {
                                                priceInputValue == 0
                                                ? alert("Selling price cannot be 0.")
                                                : changeProductPrice(e, item.id, selectedTab === 1 ? 'set-articles' : 'products')
                                            }}
                                            />
                                        </div>
                                        </div>
                                        :
                                        <div className="margin-top-12">
                                        <b>{t('adminDashboard.Products.selling_price')}: {
                                            Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.selling_price)
                                        } € </b>
                                        {(!item.is_sold) ? <CIcon icon={cilPen} size='lg' onClick={(e) => setSellingPriceState(e, item.id, item.selling_price)} /> : null}
                                        </div>
                                    ) : (
                                    <div className="margin-top-12">
                                        <b>
                                        {/* <span>{t('adminDashboard.Products.selling_price')}:</span>&nbsp; */}
                                        <span style={{ textDecoration: 'line-through', opacity: '0.8' }}>
                                            {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.selling_price)} €
                                        </span>
                                        &nbsp;&nbsp;
                                        <span>
                                            {Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(
                                            item.selling_price * (1 - discountInPercentage / 100)
                                            )} €
                                        </span>
                                        </b>
                                    </div>
                                    )
                                }
                                </h5>
                                <div>
                                    {item?.is_sold && !edit && (
                                        <>
                                            <div style={{color: 'red'}}>{item?.order_id ? <b>SOLD</b> : <b>OFFERED</b> }</div>
                                            <div style={{ marginTop: '5px' }}>
                                                <CBadge color="dark">
                                                <b>{item?.customer_name}</b>
                                                </CBadge>
                                            </div>
                                            <div style={{ marginTop: '5px' }}>
                                                <CBadge color="info">
                                                <b>PackageID: {item?.package_id}</b>
                                                </CBadge>
                                            </div>
                                            {item?.order_id && (
                                                <div style={{ marginTop: '5px' }}>
                                                <CBadge color="warning">
                                                    <b>OrderID: {item?.order_id}</b>
                                                </CBadge>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </CCardText>
                        </h6>
                        {
                            item.is_setarticle && (!item.is_sold || edit == true) ?
                            <>
                                {
                                pairedItem[0]?.set_articles?.length > 0 ? 
                                        <CBadge className="margin-right-8" color="dark" style={{fontSize: 16}}>{`Paired (${pairedItem[0].set_articles.filter((id) => id !== item.id)})`}</CBadge>
                                    :
                                    
                                    (isWarehouseModule && selectedTab == 0 && !automaticPairing) || (selectedTab == 1 && !automaticPairing) ?
                                        <CButton
                                            color='link'
                                            variant="outline"
                                            shape="square"
                                            style={{color: selectedProducts.includes(item.id) ? 'white': '#23B2D9'}}
                                            onClick={(e)=>{
                                                getPairedSetArticlesOptions && getPairedSetArticlesOptions(e, item);
                                            }}
                                        >
                                        {t('adminDashboard.Packages.pair_button')}
                                        </CButton>
                                    : 
                                    null
                                    
                                }
                                {isWarehouseModule &&  selectedTab == 0 ?
                                    <CButton
                                        color='link'
                                        variant="outline"
                                        shape="square"
                                        style={{color: selectedProducts.includes(item.id) ? 'white': '#23B2D9'}}
                                        onClick={(e)=>{
                                            autoPairIndividually && autoPairIndividually(e, item.id);
                                        }}
                                    >
                                        Auto pair
                                    </CButton> : null
                                }
                            </>
                            : null
                        }
                    </CCol>
                    <CCol xs={12} md={4} style={{textAlign: 'center'}}>
                        <CBadge className="margin-top-12" color="dark" style={{fontSize: FONT_15}}>{item?.metadata?.broken_glass ? "Glass Broke" : null}</CBadge>
                        <CBadge className="margin-top-12" color="dark" style={{fontSize: FONT_15}}>{item?.metadata?.broken_display ? "Display Broke" : null}</CBadge>
                        <CBadge className="margin-top-12" color="dark" style={{fontSize: FONT_15}}>{item?.metadata?.broken_door ? "Door Broke" : null}</CBadge>
                    </CCol>
                </CRow>
                
                
            </CCardBody>
        </CCard>
    )
};
export default ProductCardComponent;